import {
  PhoneArrowUpRightIcon,
  PhoneArrowDownLeftIcon,
  PhoneXMarkIcon,
} from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import ChatCallEventContentInfo from "./ChatCallEventContentInfo";
moment.locale("pt");

export default function ChatCallEventContent({ _item, showContactInfo }) {
  return (
    <>
      <div
        className={
          `flex leading-1.5 rounded-md h-10 relative group ` +
          `${_item.outgoing_direction ? "flex-row-reverse" : ""}`
        }
      >
        <div
          className={`w-64 bg-gray-100/50 flex ${
            _item.outgoing_direction ? "flex-row-reverse" : ""
          }`}
        >
          <div className="w-10 bg-gray-100/50 p-2 rounded-md">
            {_item.phone_contact_status === "inbound:connected" && (
              <PhoneArrowDownLeftIcon className="w-6 h-6 text-lime-800/75" />
            )}
            {_item.phone_contact_status === "outbound:connected" && (
              <PhoneArrowUpRightIcon className="w-6 h-6 text-lime-800/75" />
            )}
            {_item.phone_contact_status === "inbound:not-connected" && (
              <PhoneXMarkIcon className="w-6 h-6 text-red-800/75" />
            )}
            {_item.phone_contact_status === "outbound:not-connected" && (
              <PhoneArrowUpRightIcon className="w-6 h-6 text-red-800/75" />
            )}
          </div>
          <div
            className={
              `w-44 flex font-semibold text-xs ` +
              `${
                _item.phone_contact_status.indexOf("not-connected") > -1
                  ? "py-1"
                  : "py-3"
              }`
            }
          >
            <div className="pl-2">
              {_item.phone_contact_status === "inbound:connected" && (
                <span>Recebida</span>
              )}
              {_item.phone_contact_status === "outbound:connected" && (
                <span>Efetuada</span>
              )}
              {_item.phone_contact_status === "inbound:not-connected" && (
                <span>
                  <p>Recebida</p>
                  <p className="font-normal">Não atendida</p>
                </span>
              )}
              {_item.phone_contact_status === "outbound:not-connected" && (
                <span>
                  <p>Efetuada</p>
                  <p className="font-normal">Não atendida</p>
                </span>
              )}
            </div>
          </div>
          {!showContactInfo && (
            <div className="p-1 hidden xl:flex font-normal text-xs w-20">
              <time
                dateTime={moment(_item.created_at).format()}
                className="text-[10px] font-normal text-gray-500"
              >
                {moment(_item.created_at).isSame(moment(), "day") ? (
                  <span className="text-right">
                    {moment(_item.created_at).format("HH:mm")}
                  </span>
                ) : (
                  <span className="text-right">
                    <p className="text-xs font-normal">
                      {moment(_item.created_at).format("HH:mm")}
                    </p>
                    <p className=" text-gray-400">
                      {moment(_item.created_at).format("D/MMM/YY")}
                    </p>
                  </span>
                )}
              </time>
            </div>
          )}
        </div>
        <div className="p-3 m-auto opacity-0 group-hover:opacity-100 transition-opacity">
          <ChatCallEventContentInfo
            data={_item}
            buttonContent={<InformationCircleIcon className="w-4 h-4" />}
          />
        </div>
      </div>
    </>
  );
}
