import { apiUrl, postRequests as pt } from "../../common/apiEndpoints";
import { postJson, putJson } from "../../common/fetchdata";
import { msgStatus } from "../data/messagingStatus";

export const doStatusChange = (
  globalcontext,
  action,
  selectedTopic,
  pres,
  setPres
) => {
  // type, title, message, okTrigg
  let selTopic = "";
  if (Array.isArray(selectedTopic))
    selTopic = selectedTopic.map((obj) => obj.id).toString();
  else selTopic = selectedTopic.id;

  globalcontext.contextFcn.panel.modal.open(
    "info",
    "Confirma a alteração do estado?",
    () =>
      putJson(
        pt.messagingthread,
        {
          ...pres,
          payload: {
            threadId: selTopic,
            status: action,
            mode: "statuschange",
          },
        },
        setPres
      )
  );
};

export const handleSetUnread = (selectedTopic, doPutMsgEdit) => {
  // type, title, message, okTrigg
  let selTopic = "";
  if (Array.isArray(selectedTopic))
    selTopic = selectedTopic.map((obj) => obj.id).toString();
  else selTopic = selectedTopic.id;

  doPutMsgEdit({
    mode: "setUnread",
    threadId: selTopic,
  });
};

export const handleStatusChange = (
  globalcontext,
  action,
  selectedTopic,
  doPutMsgEdit
) => {
  // type, title, message, okTrigg
  let selTopic = "";
  if (Array.isArray(selectedTopic))
    selTopic = selectedTopic.map((obj) => obj.id).toString();
  else selTopic = selectedTopic.id;

  globalcontext.contextFcn.panel.modal.open(
    "info",
    "Confirma a alteração do estado?",
    () =>
      doPutMsgEdit({
        mode: "statuschange",
        threadId: selTopic,
        status: action,
      })
  );
};

export const handleAssignmentChange = (
  globalcontext,
  userId,
  userName,
  selectedTopic,
  doPutMsgEdit
) => {
  // type, title, message, okTrigg
  let selTopic = "";
  if (Array.isArray(selectedTopic))
    selTopic = selectedTopic.map((obj) => obj.id).toString();
  else selTopic = selectedTopic.id;

  globalcontext.contextFcn.panel.modal.open(
    "info",
    `Confirma a assignação a ${userName}?`,
    () =>
      doPutMsgEdit({
        mode: "userassignment",
        threadId: selTopic,
        assigneeId: userId,
      })
  );
};

export const handleGroupAssignmentChange = (
  globalcontext,
  groupId,
  groupName,
  selectedTopic,
  doPutMsgEdit
) => {
  // type, title, message, okTrigg
  let selTopic = "";
  if (Array.isArray(selectedTopic))
    selTopic = selectedTopic.map((obj) => obj.id).toString();
  else selTopic = selectedTopic.id;

  globalcontext.contextFcn.panel.modal.open(
    "info",
    `Confirma a assignação ao grupo ${groupName}?`,
    () =>
      doPutMsgEdit({
        mode: "groupassignment",
        threadId: selTopic,
        groupId: groupId,
      })
  );
};

export function messagingMainAction(selectedTopic) {
  if (typeof selectedTopic != "undefined") {
    const _status = selectedTopic.current_status;
    if (_status == "CLOSED") return "OPEN";
    else return "CLOSED";
  }
}

export function messagingMainActionObj(selectedTopic) {
  if (typeof selectedTopic != "undefined") {
    return msgStatus.filter(
      (s) => s.value == messagingMainAction(selectedTopic)
    )[0];
  }
}

export function messagingSecondaryActions(selectedTopic) {
  if (typeof selectedTopic != "undefined") {
    return msgStatus
      .filter((s) => s.value != messagingMainAction(selectedTopic))
      .filter((s) => s.value != selectedTopic.current_status);
  }
}
