import React, { useEffect } from 'react';
import { useAuth } from 'react-oidc-context';

const AuthSilentRenew = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.signinSilentCallback().catch((error) => {
      console.error('Error during silent renew callback:', error);
    });
  }, [auth]);

  return <div>Renewing token...</div>;
};

export default AuthSilentRenew;