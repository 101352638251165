import { useContext, useEffect } from "react";
import usePostRequest from "../../hooks/usePostRequest";
import useGetRequest from "../../hooks/useGetRequest";
import { GlobalStateContext } from "../../state/globalState";
import moment from 'moment-timezone'
import 'moment/dist/locale/pt';
moment.locale('pt');

export default function ConfigIntegration() {
  const globalcontext = useContext(GlobalStateContext);
  const { doPost: doPostUpdate, postR: postUpdateRes } =
    usePostRequest("integrationImport");

  const {
    doGet: doGetUpdate,
    items: getUpdatItems,
    isComplete: isCompleteUpdateItems,
  } = useGetRequest("integrationImport");

  useEffect(() => {
    doGetUpdate();
  }, []);

  function handleUpdateData() {
    globalcontext.contextFcn.panel.modal.open(
      "danger",
      `Confirma a atualização do modelo de dados?`,
      () => doPostUpdate({ model: "provider" })
    );
  }

  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">
          Integração com outros sistemas
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          A atualização dos modelos irá substituir as configurações atuais.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">
              Médicos
            </dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900 text-xs">
                <span className="pr-2 font-medium">Última atualização</span>
                {`${isCompleteUpdateItems ? moment(getUpdatItems.provider).fromNow() : ""}`}
              </div>
              <button
                type="button"
                onClick={handleUpdateData}
                className="font-semibold text-indigo-600 hover:text-indigo-500"
              >
                Atualizar agora
              </button>
            </dd>
          </div>
        </dl>
      </div>
    </>
  );
}
