import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { msgStatus } from "../../data/messagingStatus";
moment.locale("pt");

export default function ChatChangeContent({ _item }) {
  return (
    <div className="bg-gray-100 rounded-lg p-3 text-sm border border-gray-200">
      {_item.event_key == "asigneechange:" ? (
        <div>
          <span className="text-gray-400/50">
            Conversação atribuída a
            <span className="text-gray-400 px-2">
              {_item.assigned_user_fullname}
            </span>
            {!_item.system_event ? (
              <>
                por
                <span className="text-gray-400 px-2">
                  {_item.user_fullname}
                </span>
              </>
            ) : (
              <></>
            )}
          </span>
        </div>
      ) : _item.event_key.indexOf("statuschange") > -1 ? (
        <div>
          <span className="text-gray-400/50">
            Estado alterado para
            <span className="text-gray-400 px-2">
              {_item.status == null
                ? "Sem estado"
                : msgStatus.filter((s) => s.value == _item.status)[0].name}
            </span>
            {!_item.system_event ? (
              <>
                por
                <span className="text-gray-400 px-2">
                  {_item.user_fullname}
                </span>
              </>
            ) : (
              <></>
            )}
          </span>
        </div>
      ) : (
        _item.event_key
      )}
    </div>
  );
}
