import { useState, useContext, useEffect } from 'react';
import { putJson, blankResult, reqOk, loadingScreen } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';
import { useAuth } from "./useAuth";


function usePutRequest( action ) {


  let auth = useAuth();
  const [putR, setPutR] = useState(blankResult);
  const _gc = useContext(GlobalStateContext);

   useEffect(() => {
      loadingScreen(putR, _gc)
  }, [putR]); 

  function handleRequest( payload ) {
    putJson(
      apiUrl[action],
      { ...putR,  payload: payload, token: auth.token },
      setPutR
    );
  };



  return {
    doPut: handleRequest,
    putR,
    setPutR,
    isComplete: reqOk(putR),
    items: reqOk(putR) ? putR.items : null
  }

}

export default usePutRequest;