import React, { useState, useContext, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import parsePhoneNumber from "libphonenumber-js";
import { msgStatus } from "../../data/messagingStatus";
import useGetRequest from "../../../hooks/useGetRequest";
import usePostRequest from "../../../hooks/usePostRequest";
import usePutRequest from "../../../hooks/usePutRequest";

import {
  PencilIcon,
  StarIcon,
  XMarkIcon,
  EnvelopeIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { GlobalStateContext } from "../../../state/globalState";
import MessagingContentHeader from "../../MessagingContentHeader";
import MessagingViewersBubble from "../../viewers/MessagingViewersBubble";
import RelatedContactList from "../../relatedcontacts/RelatedContactList";
import TextInputModal from "../../../common/modal/TextInputModal";
import { refreshContext } from "../../data/messagingSelect";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InboxChatDetailsSidebar({
  selectedTopic,
  setShowContactInfo,
}) {
  const globalcontext = useContext(GlobalStateContext);
  const [inpModalShow_name, setinpModalShow_name] = useState(false);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const {
    doGet: doGetRelatedContacts,
    getR: getRRelatedContacts,
  } = useGetRequest("relatedcontact");

  const {
    doPost: doPostRelatedContacts,
    postR: postRRelatedContacts,
  } = usePostRequest("relatedcontact");

  const {
    doPut: doPutLeadsManage,
  } = usePutRequest("messagingthread");

  useEffect(() => {
    if (selectedTopic.id != null && selectedTopic.id > 0) {
      doGetRelatedContacts({
        custWebId: selectedTopic.customers_web.id,
      });
    }
  }, [selectedTopic]);

  useEffect(() => {
    if (postRRelatedContacts) {
      refreshContext(searchParams, navigate);
    }
  }, [postRRelatedContacts.state]);

  function handleAssignment(_type, id) {
    globalcontext.contextFcn.panel.modal.open(
      "info",
      "Confirma a ação de relacionar este contato?",
      () =>
        doPostRelatedContacts({
          type: _type,
          id: id,
          custWebId: selectedTopic.customers_web.id,
        })
    );
  }

  function saveChangesName(data) {
    doPutLeadsManage({
      mode: "name",
      threadId: selectedTopic.id,
      firstName: data.firstname,
      lastName: data.lastname,
    });
  }

  return (
    <>
      <TextInputModal
        title={"Nome completo"}
        open={inpModalShow_name}
        setOpen={setinpModalShow_name}
        initValue={[
          {
            label: "Primeiro nome",
            id: "firstname",
            value: selectedTopic.customers_web.name,
          },
          {
            label: "Último nome",
            id: "lastname",
            value: selectedTopic.customers_web.lastname,
          },
        ]}
        saveValue={(e) => saveChangesName(e)}
      />
      <aside className="relative w-full overflow-y-auto border-l border-gray-200 bg-white p-8 lg:block">
        <button
          type="button"
          onClick={() => setShowContactInfo(false)}
          className="absolute right-3 top-3 ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          <span className="absolute -inset-1.5" />
          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          <span className="sr-only">Favorite</span>
        </button>

        <div className="space-y-6 ">
          <div>
            <div className="mt-4 flex items-start justify-between">
              <div className="flex">
                <h2 className="text-lg font-medium text-gray-900">
                  <span className="sr-only">Details for </span>
                  <span
                    id="contact-heading"
                    className="text-lg font-medium text-gray-900"
                  >
                    {`${
                      selectedTopic.customers_web.name != null
                        ? selectedTopic.customers_web.name
                        : "Novo contato"
                    } ${
                      selectedTopic.customers_web.lastname != null
                        ? selectedTopic.customers_web.lastname
                        : ""
                    }`}
                  </span>
                </h2>
                <span className="ml-3 cursor-pointer rounded-full h-8 w-8 bg-white p-2 text-xs font-semibold text-gray-900 hover:bg-gray-100">
                  <a href="#" onClick={() => setinpModalShow_name(true)}>
                    <PencilIcon className="w-4 h-4" />
                  </a>
                </span>

                <p className="text-sm font-medium text-gray-500"></p>
              </div>
              <button
                type="button"
                className="relative ml-4 flex h-8 w-8 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
              >
                <span className="absolute -inset-1.5" />
                <StarIcon className="h-6 w-6" aria-hidden="true" />
                <span className="sr-only">Favorite</span>
              </button>
            </div>
          </div>
          <div>
            <h3 className="font-medium text-gray-900">Detalhes</h3>
            <dl className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200">
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Estado</dt>
                <dd className="whitespace-nowrap text-gray-900">
                  <span
                    className={`inline-flex items-center rounded-full px-3 py-0.5 text-sm font-medium ${
                      selectedTopic.current_status == null
                        ? "bg-cyan-100 text-gray-800"
                        : `${
                            msgStatus.filter(
                              (s) => s.value == selectedTopic.current_status
                            )[0].bg
                          } text-white`
                    } `}
                  >
                    {selectedTopic.current_status == null
                      ? "Sem estado"
                      : msgStatus.filter(
                          (s) => s.value == selectedTopic.current_status
                        )[0].name}
                  </span>
                </dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Grupo</dt>
                <dd className="whitespace-nowrap text-gray-900">Grupo</dd>
              </div>
              <div className="flex justify-between py-3 text-sm font-medium">
                <dt className="text-gray-500">Responsável</dt>
                <dd className="whitespace-nowrap text-gray-900">
                  {selectedTopic.assigned_user != null
                    ? selectedTopic.assigned_user.fullname
                    : "Não assignado"}
                </dd>
              </div>
              {selectedTopic.customers_web.phone_contact != null && (
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">
                    <PhoneIcon className="w-4 h-4" />
                  </dt>
                  <dd className="whitespace-nowrap text-gray-900">
                    <div className="">
                      <span className="text-xs">
                        +
                        {
                          parsePhoneNumber(
                            selectedTopic.customers_web.phone_contact
                              .phonenum_e164,
                            null
                          ).countryCallingCode
                        }
                      </span>
                      {` `}
                      {
                        parsePhoneNumber(
                          selectedTopic.customers_web.phone_contact
                            .phonenum_e164,
                          null
                        ).nationalNumber
                      }
                      <span className="ml-2">
                        {selectedTopic.customers_web.phone_contact != null
                          ? typeof parsePhoneNumber(
                              selectedTopic.customers_web.phone_contact
                                .phonenum_e164,
                              null
                            ) != "undefined" &&
                            parsePhoneNumber(
                              selectedTopic.customers_web.phone_contact
                                .phonenum_e164,
                              null
                            ).country
                          : "N/D"}
                      </span>
                    </div>
                  </dd>
                </div>
              )}

              {selectedTopic.customers_web.email_contact != null && (
                <div className="flex justify-between py-3 text-sm font-medium">
                  <dt className="text-gray-500">
                    <EnvelopeIcon className="w-4 h-4" />
                  </dt>
                  <dd className="whitespace-nowrap text-gray-900">
                    {selectedTopic.customers_web.email_contact != null
                      ? selectedTopic.customers_web.email_contact.email
                      : ""}
                  </dd>
                </div>
              )}
            </dl>
          </div>
          <div>
            <Disclosure>
              <DisclosureButton className="text-md font-semibold text-gray-400 group flex items-center gap-2 group gap-x-3 rounded-md w-fit">
                <h3 className="font-medium text-gray-900">Visto por</h3>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>
              <DisclosurePanel
                transition
                className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
              >
                <div className="mt-2 flex items-center justify-between w-full">
                  <MessagingViewersBubble
                    readReceipts={
                      selectedTopic.contact_message_thread_read_receipt
                    }
                  />
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>

          <div>
            <Disclosure>
              <DisclosureButton className="text-md font-semibold text-gray-400 group flex items-center gap-2 group gap-x-3 rounded-md w-fit">
                <h3 className="font-medium text-gray-900">
                  Relacionar contatos
                </h3>
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>
              <DisclosurePanel
                transition
                className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
              >
                <div className="items-center justify-between">
                  <RelatedContactList
                    data={getRRelatedContacts}
                    handleAssignment={handleAssignment}
                  />
                </div>
              </DisclosurePanel>
            </Disclosure>
          </div>

         {/*  <div className="flex gap-x-3">
            <button
              type="button"
              className="flex-1 rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Download
            </button>
            <button
              type="button"
              className="flex-1 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              Delete
            </button>
          </div> */}
        </div>
      </aside>
    </>
  );
}
