import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useGetRequest from "../../hooks/useGetRequest";
import { AutomationEventLogsTable } from "./AutomationEventLogsTable";

export function AutomationEventLogsHome() {
  const location = useLocation();

  // Extract `wfExecution` query parameter
  const queryParams = new URLSearchParams(location.search);
  const currentWfExecutionCode = queryParams.get("wfExecution");

  const {
    doGet: doGetEvents,
    items: events,
    isComplete: isCompleteEvents,
  } = useGetRequest("automationTriggerEventLogs");

  useEffect(() => {
    if (currentWfExecutionCode != null) {
      doGetEvents({
        wf_execution_id: currentWfExecutionCode,
      });
    }
  }, [currentWfExecutionCode]);

  return (
    <main className="flex flex-col">
     
      <div className="flex items-center justify-center">
        {currentWfExecutionCode == null ? (
          <>Erro</>
        ) : (
          <AutomationEventLogsTable
            isLoading={isCompleteEvents}
            events={!isCompleteEvents ? [] : events.data}
          />
        )}
      </div>
    </main>
  );
}
