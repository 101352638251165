import React, { useState, useRef, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Mic, Square, Trash2, Upload } from "lucide-react";
import toast from "react-hot-toast";
import IvrFileList from "./IvrFileList";
import usePostRequestWithFiles from "../../hooks/usePostRequestWithFiles";


/* interface AudioTrack {
  id: string
  name: string
  blob: Blob
} */

export default function IvrVoiceRecorder() {
  const [isRecording, setIsRecording] = useState(false);
  const [audioTracks, setAudioTracks] = useState([]);
  const [trackName, setTrackName] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);

  const { doPost, items, isComplete } = usePostRequestWithFiles("file");

  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && isRecording) {
        mediaRecorderRef.current.stop();
      }
    };
  }, [isRecording]);

  const startRecording = async () => {
    if (!trackName.trim()) {
      toast.error("Please enter a name for the track before recording.");
      return;
    }

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      mediaRecorderRef.current.addEventListener("stop", handleStop);
      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast.error(
        "Unable to access the microphone. Please check your permissions."
      );
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data);
    }
  };

  const handleStop = async () => {
    const audioBlob = new Blob(audioChunksRef.current, { type: "audio/webm" });
    const newTrack = {
      id: Date.now().toString(),
      name: trackName,
      blob: audioBlob,
    };
    setAudioTracks((prevTracks) => [...prevTracks, newTrack]);
    audioChunksRef.current = [];

    // Immediately post to backend
    await uploadTrack(newTrack);

    // Reset track name
    setTrackName("");
  };

  const removeTrack = (id) => {
    setAudioTracks((prevTracks) =>
      prevTracks.filter((track) => track.id !== id)
    );
  };

  const uploadTrack = async (track) => {
    // Simulating a POST request to a backend

    const payload = { type: "IVR_TRACK", filename_1: track.name, filename_1_desc: ''};
    const files = { file: track.blob };

    await doPost(payload, files);
  };

  return (
    <Card className="w-full max-w-4xl mx-auto">
      <CardHeader>
        <CardTitle>Voice Recorder</CardTitle>
      </CardHeader>
      <CardContent>
        <Tabs defaultValue="recorder" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="recorder">Recorder</TabsTrigger>
            <TabsTrigger value="files">Files</TabsTrigger>
          </TabsList>
          <TabsContent value="recorder">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="track-name">Track Name</Label>
                <Input
                  id="track-name"
                  value={trackName}
                  onChange={(e) => setTrackName(e.target.value)}
                  placeholder="Enter track name"
                  disabled={isRecording}
                />
              </div>
              <Button
                onClick={isRecording ? stopRecording : startRecording}
                className="w-full"
                disabled={isRecording && !trackName.trim()}
              >
                {isRecording ? (
                  <>
                    <Square className="w-4 h-4 mr-2" />
                    Stop Recording
                  </>
                ) : (
                  <>
                    <Mic className="w-4 h-4 mr-2" />
                    Start Recording
                  </>
                )}
              </Button>
              {audioTracks.map((track) => (
                <div
                  key={track.id}
                  className="flex items-center justify-between p-2 border rounded"
                >
                  <div className="flex flex-col">
                    <span className="font-medium">{track.name}</span>
                    <audio src={URL.createObjectURL(track.blob)} controls />
                  </div>
                  <div>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => uploadTrack(track)}
                      title="Upload"
                    >
                      <Upload className="w-4 h-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => removeTrack(track.id)}
                      title="Remove"
                    >
                      <Trash2 className="w-4 h-4" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </TabsContent>
          <TabsContent value="files">
            <IvrFileList />
          </TabsContent>
        </Tabs>
      </CardContent>
      <CardFooter>
        <p className="text-sm text-muted-foreground">
          {audioTracks.length} track{audioTracks.length !== 1 ? "s" : ""}{" "}
          recorded
        </p>
      </CardFooter>
    </Card>
  );
}
