
import { useState, useEffect } from 'react'
import { Button } from '@/components/ui/button'
import { PlusCircle } from 'lucide-react'
import TemplateList from './TemplateList'
import TemplateForm from './TemplateForm'
import {  getTemplates } from './templateManager'

export default function TemplateHome() {
  const [templates, setTemplates] = useState([])
  const [isFormOpen, setIsFormOpen] = useState(false)
  const [editingTemplate, setEditingTemplate] = useState(null)

  useEffect(() => {
    setTemplates(getTemplates())
  }, [])

  const handleAddTemplate = () => {
    setEditingTemplate(null)
    setIsFormOpen(true)
  }

  const handleEditTemplate = (template) => {
    setEditingTemplate(template)
    setIsFormOpen(true)
  }

  const handleFormClose = () => {
    setIsFormOpen(false)
    setEditingTemplate(null)
    setTemplates(getTemplates())
  }

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-3xl font-bold mb-6">Communication Templates</h1>
      <Button onClick={handleAddTemplate} className="mb-4">
        <PlusCircle className="mr-2 h-4 w-4" /> Add Template
      </Button>
      <TemplateList templates={templates} onEditTemplate={handleEditTemplate} />
      {isFormOpen && (
        <TemplateForm
          template={editingTemplate}
          onClose={handleFormClose}
        />
      )}
    </div>
  )
}

