export const threadFilters = [
  {
    name: "status",
    key: "Estado",
    filterDataType: "list",
    options: [
      { value: "Aberto", name: "open" },
      { value: "Fechado", name: "closed" },
      { value: "Pendente", name: "pending" }
    ],
  },
  {
    name: "phone",
    key: "Telefone",
    filterDataType: "string"
  },
  {
    name: "fullName",
    key: "Nome",
    filterDataType: "string",
  },
  {
    name: "taxId",
    key: "NIF",
    filterDataType: "string",
  },
  {
    name: "email",
    key: "Email",
    filterDataType: "string",
  },
];


export const filterDataTypes = [
    {
      type: "string",
      operators: ["equal", "notEqual", "isContained", "isNotContained"],
      input: "input",
    },
    {
      type: "number",
      operators: ["equal", "notEqual", "lessOrEqualTo", "greaterOrEqualTo"],
      input: "input",
    },
    {
      type: "list",
      operators: ["equal", "notEqual"],
      input: "select",
    },
    {
      type: "date",
      operators: ["lessOrEqualTo", "greaterOrEqualTo"],
      input: "dateinput",
    },
  ];
  

export const filterDataTypeOperatorLabel = [
    {
      key: "equal",
      label: "Igual",
    },
    {
      key: "notEqual",
      label: "Diferente",
    },
    {
      key: "isContained",
      label: "Contém",
    },
    {
      key: "isNotContained",
      label: "Não Contém",
    },
    {
      key: "lessOrEqualTo",
      label: "Menor ou Igual",
    },
    {
      key: "greaterOrEqualTo",
      label: "Maior ou Igual",
    },
  ];