import { useEffect } from "react";
import useGetRequest from "../../hooks/useGetRequest";
import ProviderAppointmentCustomConfig from "./ProviderAppointmentCustomConfig";

export default function ProviderAppointmentManagement() {
  const {
    doGet: doGetProviders,
    isComplete: isCompleteProviders,
    items: itemsProviders,
  } = useGetRequest("providers");

  const {
    doGet: doGetAppCustomConfig,
    isComplete: isCompleteAppCustomConfig,
    items: itemsAppCustomConfig,
  } = useGetRequest("provservcfg");

  useEffect(() => {
    doGetProviders({
      entries: 20,
    });
  }, []);


  useEffect(() => {
    if (isCompleteProviders)
      doGetAppCustomConfig({
        providerId: itemsProviders.data.map((i) => i.id).toString(),
      });
  }, [isCompleteProviders]);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-1 align-middle sm:px-6 lg:px-8  space-y-4 ">
            {isCompleteProviders &&
              isCompleteAppCustomConfig &&
              itemsProviders.data.map((person) => (
                <div
                  key={person.id}
                  className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
                >
                  <ProviderAppointmentCustomConfig
                    person={person}
                    isCompleteAppCustomConfig={isCompleteAppCustomConfig}
                    itemsAppCustomConfig={itemsAppCustomConfig}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
