import React, {
  useMemo,
  useCallback,
  useRef,
  useEffect,
  useState,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Editor, Transforms, Range, createEditor, Node } from "slate";
import { withHistory } from "slate-history";
import {
  Slate,
  Editable,
  ReactEditor,
  withReact,
  useSelected,
  useFocused,
} from "slate-react";
import { Portal } from "./components";
import { IS_MAC } from "./utils/environment";

const MessagingSenderTextBox = forwardRef((props, ref) => {
  const eref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");

  // Initialize the editor with plugins
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );

  // Initialize the editor's value
  const [value, setValue] = useState(initialValue);

  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const chars = props.itemsInboxCategories?.user_agents.map( a => a.fullname).filter((c) =>
    c.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10);

  const onKeyDown = useCallback(
    (event) => {
      if (target && chars.length > 0) {
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const nextIndex = index >= chars.length - 1 ? 0 : index + 1;
            setIndex(nextIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const prevIndex = index <= 0 ? chars.length - 1 : index - 1;
            setIndex(prevIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, chars[index]);
            setTarget(null);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(null);
            break;
          default:
            break;
        }
      }
    },
    [chars, editor, index, target]
  );

  useEffect(() => {
    if (target && chars.length > 0) {
      const el = eref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [chars?.length, editor, index, search, target]);

  function extractMentionsFromField(field) {
    const mentions = [];
  
    // Recursive function to traverse nodes and extract mentions
    function traverse(node) {
      if (Array.isArray(node)) {
        node.forEach(traverse);
      } else if (node.type === 'mention') {
        mentions.push(node.character);
      } else if (node.children) {
        node.children.forEach(traverse);
      }
    }
  
    // Start traversing the "value" field
    traverse(field);
  
    return mentions;
  }

  // Expose methods to the parent component
  useImperativeHandle(ref, () => ({
    getContent: () => {
      // Return the current value as JSON and plain text
      return {
        value: value,
        plainText: Node.string({ children: value }),
        mentions: extractMentionsFromField(value)
      };
    },
    clearContent: () => {
      // Define the initial empty value
      const emptyValue = [
        {
          type: "paragraph",
          children: [{ text: "" }],
        },
      ];
      
      // Reset the editor's value to the initial state
      setValue(emptyValue);
      
      // Optionally, you can also reset the editor's history
      // This is safer than directly manipulating `editor.history`
      editor.history.redos = [];
      editor.history.undos = [];
    },
  }));


  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      value={value}
      onChange={(newValue) => {
        setValue(newValue);
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: "word" });
          const before = wordBefore && Editor.before(editor, wordBefore);
          const beforeRange = before && Editor.range(editor, before, start);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);
          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }
        setTarget(null);
      }}
    >
      <Editable
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        placeholder="Enter some text..."
      />
      {target && chars.length > 0 && (
        <Portal>
          <div
            ref={eref}
            style={{
              top: "-9999px",
              left: "-9999px",
              position: "absolute",
              zIndex: 1,
              padding: "3px",
              background: "white",
              borderRadius: "4px",
              boxShadow: "0 1px 5px rgba(0,0,0,.2)",
            }}
            data-cy="mentions-portal"
          >
            {chars.map((char, i) => (
              <div
                key={char}
                onClick={() => {
                  Transforms.select(editor, target);
                  insertMention(editor, char);
                  setTarget(null);
                }}
                style={{
                  padding: "1px 3px",
                  borderRadius: "3px",
                  cursor: "pointer",
                  background: i === index ? "#B4D5FF" : "transparent",
                }}
              >
                {char}
              </div>
            ))}
          </div>
        </Portal>
      )}
    </Slate>
  );
});

const withMentions = (editor) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "mention" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "mention" ? true : isVoid(element);
  };

  editor.markableVoid = (element) => {
    return element.type === "mention" || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor, character) => {
  const mention = {
    type: "mention",
    character,
    children: [{ text: "" }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }
  if (leaf.code) {
    children = <code>{children}</code>;
  }
  if (leaf.italic) {
    children = <em>{children}</em>;
  }
  if (leaf.underline) {
    children = <u>{children}</u>;
  }
  return <span {...attributes}>{children}</span>;
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "mention":
      return <Mention {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Mention = ({ attributes, children, element }) => {
  const selected = useSelected();
  const focused = useFocused();
  const style = {
    padding: "3px 3px 2px",
    margin: "0 1px",
    verticalAlign: "baseline",
    display: "inline-block",
    borderRadius: "4px",
    backgroundColor: "#eee",
    fontSize: "0.9em",
    boxShadow: selected && focused ? "0 0 0 2px #B4D5FF" : "none",
  };
  if (element.children[0].bold) {
    style.fontWeight = "bold";
  }
  if (element.children[0].italic) {
    style.fontStyle = "italic";
  }
  return (
    <span
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(" ", "-")}`}
      style={style}
    >
      {IS_MAC ? (
        <Fragment>
          {children}@{element.character}
        </Fragment>
      ) : (
        <Fragment>
          @{element.character}
          {children}
        </Fragment>
      )}
    </span>
  );
};

const initialValue = [
  {
    type: "paragraph",
    children: [{ text: "" }],
  },
];


export default MessagingSenderTextBox;