"use client";

import { useState, useEffect } from "react";
import dayjs from "dayjs";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { Button } from "@/components/ui/button";

const AutomationEventsDateNav = ({ selectedDate, setSelectedDate }) => {
  const [dates, setDates] = useState([]);
  const [visibleDates, setVisibleDates] = useState(7);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 640) setVisibleDates(5);
      else if (width < 768) setVisibleDates(7);
      else if (width < 1024) setVisibleDates(9);
      else setVisibleDates(11);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setDates(generateDates(selectedDate, visibleDates));
  }, [selectedDate, visibleDates]);

  const handleDateClick = (date) => {
    setSelectedDate(date);
  };

  const handleNavigate = (direction) => {
    const newSelectedDate = selectedDate.add(
      direction === "next" ? 1 : -1,
      "day"
    );
    setSelectedDate(newSelectedDate);
  };

  return (
    <div className="w-full bg-background border-b pb-3">
      <div className="max-w-screen-xl mx-auto px-4 py-2 flex items-center justify-between">
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleNavigate("prev")}
          className="shrink-0"
        >
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <div className="flex space-x-2">
          {dates.map((date) => (
            <div key={date.valueOf()} className="">
              <Button
                variant={date.isSame(selectedDate, "day") ? "default" : "ghost"}
                onClick={() => handleDateClick(date)}
                className="w-16 sm:w-20"
              >
                <div className="flex flex-col items-center">
                  {date.isSame(selectedDate, "day") ? (
                    <>
                      <span className="text-xs"></span>
                      <span className="text-lg font-bold">
                        {date.format("D")}
                      </span>
                      <span className="text-xs"></span>
                    </>
                  ) : (
                    <>
                      <span className="text-xs">{date.format("ddd")}</span>
                      <span className="text-lg font-bold">
                        {date.format("D")}
                      </span>
                      <span className="text-xs">{date.format("MMM")}</span>
                    </>
                  )}
                </div>
              </Button>
            </div>
          ))}
        </div>
        <Button
          variant="ghost"
          size="icon"
          onClick={() => handleNavigate("next")}
          className="shrink-0"
        >
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
    </div>
  );
};

function generateDates(centerDate, count) {
  const dates = [];
  const halfCount = Math.floor(count / 2);

  for (let i = -halfCount; i <= halfCount; i++) {
    dates.push(centerDate.add(i, "day"));
  }

  return dates;
}

export default AutomationEventsDateNav;
