import {
  EnvelopeIcon,
  FunnelIcon,
  PlayIcon,
  UserCircleIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import { msgStatus } from "./messagingStatus";
import {
  handleAssignmentChange,
  handleStatusChange,
  handleGroupAssignmentChange,
  handleSetUnread
} from "../actions/leadTags";

function setFlagUnread(item, doPutThreadActions) {
  return {
    name: "Marcar como não lido",
    icon: EnvelopeIcon,
    onClickAction: () => handleSetUnread(item, doPutThreadActions),
    textColor: "text-gray-800",
    children: [],
  };
}

function setStatus(globalcontext, item, doPutThreadActions) {
  return {
    name: "Alterar estado",
    icon: PlayIcon,
    onClickAction: null,
    href: null,
    textColor: "text-gray-800",
    children: msgStatus
      .filter((s) => s.value != item.current_status)
      .map((i) => ({
        name: i.actionLabel,
        icon: null,
        onClickAction: () =>
          handleStatusChange(globalcontext, i.value, item, doPutThreadActions),
        textColor: "text-gray-800",
      })),
  };
}

function setAssignGroup(globalcontext, item, assign, doPutThreadActions) {
  return {
    name: "Atribuir Grupo",
    icon: UserGroupIcon,
    onClickAction: null,
    href: null,
    textColor: "text-gray-800",
    children:
      assign == null
        ? []
        : assign.group_agents.map((u) => ({
            name: u.name,
            icon: UserCircleIcon,
            onClickAction: () =>
              handleGroupAssignmentChange(
                globalcontext,
                u.id,
                u.name,
                item,
                doPutThreadActions
              ),
            href: "#",
            textColor: "text-gray-800",
          })),
  };
}

function setAssignOperator(globalcontext, item, assign, doPutThreadActions) {
  return {
    name: "Atribuir Operador",
    icon: UserIcon,
    onClickAction: null,
    href: null,
    textColor: "text-gray-800",
    children:
      assign == null
        ? []
        : assign.user_agents.map((u) => ({
            name: u.fullname,
            icon: UserCircleIcon,
            onClickAction: () =>
              handleAssignmentChange(
                globalcontext,
                u.id,
                u.fullname,
                item,
                doPutThreadActions
              ),
            href: "#",
            textColor: "text-gray-800",
          })),
  };
}

export function contextMenu(globalcontext, item, assign, doPutThreadActions) {
  if (Object.keys(item).length > 0) {
    return [
      { ...setFlagUnread(item, doPutThreadActions) },
      { ...setStatus(globalcontext, item, doPutThreadActions) },
      { ...setAssignGroup(globalcontext, item, assign, doPutThreadActions) },
      { ...setAssignOperator(globalcontext, item, assign, doPutThreadActions) },
    ];
  } else return [];
}

export function contextAssignGroup(
  globalcontext,
  item,
  assign,
  doPutThreadActions
) {
  if ((typeof selectedTopic != "undefined" && Object.keys(item).length > 0) && assign != null) {
    let contextMenuItems = [];

    if (assign.group_agents.length > 0) {
      contextMenuItems = assign.group_agents.map((u) => ({
        name: u.name,
        icon: UserCircleIcon,
        onClickAction: () =>
          handleGroupAssignmentChange(
            globalcontext,
            u.id,
            u.name,
            item,
            doPutThreadActions
          ),
        href: "#",
        textColor: "text-gray-800",
      }));
    }
    return contextMenuItems;
  }
}


export function contextAssignOperator(
  globalcontext,
  item,
  assign,
  doPutThreadActions
) {
  if ((typeof selectedTopic != "undefined" && Object.keys(item).length ) > 0 && assign != null) {
    let contextMenuItems = [];

    if (assign.user_agents.length > 0) {
      contextMenuItems = assign.user_agents.map((u) => ({
        name: u.fullname,
        icon: UserCircleIcon,
        onClickAction: () =>
          handleAssignmentChange(
            globalcontext,
            u.id,
            u.fullname,
            item,
            doPutThreadActions
          ),
        href: "#",
        textColor: "text-gray-800",
      }));
    }
    return contextMenuItems;
  }
}