"use client";

import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import toast from "react-hot-toast";

export function AutomationDataSourceFieldManager() {
  const [dataSources, setDataSources] = useState([
    { id: "1", name: "Users", type: "table", value: "users" },
    {
      id: "2",
      name: "Active Orders",
      type: "query",
      value: "SELECT * FROM orders WHERE status = 'active'",
    },
  ]);
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [dataSourceFields, setDataSourceFields] = useState({});
  const [isAddFieldDialogOpen, setIsAddFieldDialogOpen] = useState(false);
  const [newField, setNewField] = useState({
    name: "",
    type: "string",
    alias: "",
    isCustom: true,
    calculation: "",
  });

  useEffect(() => {
    // Mock fetching fields for each data source
    const mockFields = {
      1: [
        {
          id: "1",
          name: "id",
          type: "integer",
          alias: "User ID",
          isCustom: false,
        },
        {
          id: "2",
          name: "name",
          type: "string",
          alias: "Full Name",
          isCustom: false,
        },
        {
          id: "3",
          name: "email",
          type: "string",
          alias: "Email Address",
          isCustom: false,
        },
      ],
      2: [
        {
          id: "1",
          name: "order_id",
          type: "integer",
          alias: "Order ID",
          isCustom: false,
        },
        {
          id: "2",
          name: "customer_name",
          type: "string",
          alias: "Customer Name",
          isCustom: false,
        },
        {
          id: "3",
          name: "total_amount",
          type: "decimal",
          alias: "Total Amount",
          isCustom: false,
        },
      ],
    };
    setDataSourceFields(mockFields);
  }, []);

  const handleSelectDataSource = (value) => {
    setSelectedDataSource(value);
  };

  const handleUpdateAlias = (fieldId, newAlias) => {
    setDataSourceFields((prev) => ({
      ...prev,
      [selectedDataSource]: prev[selectedDataSource].map((field) =>
        field.id === fieldId ? { ...field, alias: newAlias } : field
      ),
    }));
    toast.success(`The alias has been updated successfully.`);
  };

  const handleAddCustomField = () => {
    const newId = Date.now().toString();
    setDataSourceFields((prev) => ({
      ...prev,
      [selectedDataSource]: [
        ...prev[selectedDataSource],
        { ...newField, id: newId },
      ],
    }));
    setNewField({
      name: "",
      type: "string",
      alias: "",
      isCustom: true,
      calculation: "",
    });
    setIsAddFieldDialogOpen(false);
    toast.success(`The custom field has been added successfully.`);
  };

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Data Source Field Manager</h2>
      <div className="flex items-center space-x-4">
        <Select
          value={selectedDataSource}
          onValueChange={handleSelectDataSource}
        >
          <SelectTrigger className="w-[200px]">
            <SelectValue placeholder="Select Data Source" />
          </SelectTrigger>
          <SelectContent>
            {dataSources.map((ds) => (
              <SelectItem key={ds.id} value={ds.id}>
                {ds.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Dialog
          open={isAddFieldDialogOpen}
          onOpenChange={setIsAddFieldDialogOpen}
        >
          <DialogTrigger asChild>
            <Button disabled={!selectedDataSource}>Add Custom Field</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add Custom Field</DialogTitle>
              <DialogDescription>
                Create a new custom field for the selected data source.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="customFieldName" className="text-right">
                  Name
                </Label>
                <Input
                  id="customFieldName"
                  value={newField.name}
                  onChange={(e) =>
                    setNewField({ ...newField, name: e.target.value })
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="customFieldType" className="text-right">
                  Type
                </Label>
                <Select
                  value={newField.type}
                  onValueChange={(value) =>
                    setNewField({ ...newField, type: value })
                  }
                >
                  <SelectTrigger className="col-span-3">
                    <SelectValue placeholder="Select field type" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="string">String</SelectItem>
                    <SelectItem value="integer">Integer</SelectItem>
                    <SelectItem value="decimal">Decimal</SelectItem>
                    <SelectItem value="boolean">Boolean</SelectItem>
                    <SelectItem value="date">Date</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="customFieldAlias" className="text-right">
                  Alias
                </Label>
                <Input
                  id="customFieldAlias"
                  value={newField.alias}
                  onChange={(e) =>
                    setNewField({ ...newField, alias: e.target.value })
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="customFieldCalculation" className="text-right">
                  Calculation
                </Label>
                <Input
                  id="customFieldCalculation"
                  value={newField.calculation}
                  onChange={(e) =>
                    setNewField({ ...newField, calculation: e.target.value })
                  }
                  className="col-span-3"
                  placeholder="e.g., field1 + field2"
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="submit" onClick={handleAddCustomField}>
                Add Field
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
      {selectedDataSource && dataSourceFields[selectedDataSource] && (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Name</TableHead>
              <TableHead>Type</TableHead>
              <TableHead>Alias</TableHead>
              <TableHead>Custom</TableHead>
              <TableHead>Calculation</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dataSourceFields[selectedDataSource].map((field) => (
              <TableRow key={field.id}>
                <TableCell>{field.name}</TableCell>
                <TableCell>{field.type}</TableCell>
                <TableCell>
                  <Input
                    value={field.alias}
                    onChange={(e) =>
                      handleUpdateAlias(field.id, e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>{field.isCustom ? "Yes" : "No"}</TableCell>
                <TableCell>{field.calculation || "-"}</TableCell>
                <TableCell>
                  {field.isCustom && (
                    <Button
                      variant="destructive"
                      size="sm"
                      onClick={() => {
                        setDataSourceFields((prev) => ({
                          ...prev,
                          [selectedDataSource]: prev[selectedDataSource].filter(
                            (f) => f.id !== field.id
                          ),
                        }));
                        toast.success(
                          `The custom field has been removed successfully.`
                        );
                      }}
                    >
                      Remove
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}
