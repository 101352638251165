import React, { useContext } from "react";
import { GlobalStateContext } from "../../../state/globalState";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
moment.locale("pt");
import ChatCallEventContent from "./ChatCallEventContent";
import ChatCustomerCommContent from "./ChatCustomerCommContent";
import ChatChangeContent from "./ChatChangeContent";
import ChatInternalCommentContent from "./ChatInternalCommentContent";
import ChatCustomFormContent from "./ChatCustomFormContent";

export default function ChatBubbleContent({ item, showContactInfo }) {
  const globalcontext = useContext(GlobalStateContext);
  let user = globalcontext.userData.user;
  user = typeof user != "undefined" ? user : {};

  return item.type == "contact_message_thread_change_event" ? (
    <div className="w-full sm:px-6 lg:px-8 flex items-center justify-center">
      <ChatChangeContent _item={item} />
    </div>
  ) : item.type == "contact_message_thread_comment" ? (
    <div className="w-full sm:px-6 lg:px-8 flex items-center justify-center">
      <ChatInternalCommentContent _item={item} showContactInfo={showContactInfo} />
    </div>
  ) : (
    <dl className="grid grid-cols-2 w-full sm:px-6 lg:px-8">
      {item.outgoing_direction && <div />}
      <div className="flex items-start gap-2.5">
        <div
          className={`flex flex-col gap-1 w-full  ${
            item.outgoing_direction ? "items-end" : "items-start"
          }`}
        >
          <div
            className={`flex ${
              item.outgoing_direction ? "" : "flex-row-reverse"
            }`}
          >
            {item.type == "tel_central_call_event" ? (
              <ChatCallEventContent
                _item={item}
                showContactInfo={showContactInfo}
              />
            ) : item.type == "customer_comm" ? (
              <ChatCustomerCommContent _item={item} />
            ) : item.type == "custom_form" ? (
              <ChatCustomFormContent _item={item} />
            ) : item.type == "contact_message" ? (
              "contact_message"
            ) : (
              "other"
            )}
          </div>
        </div>
      </div>
    </dl>
  );
}
