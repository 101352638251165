import React, { useState, useContext } from 'react';
import { getJson, blankResult, reqOk, postJson, putJson } from '../../../common/fetchdata';
import { GlobalStateContext } from '../../../state/globalState';
import { apiUrl } from '../../../common/apiEndpoints';




export default function LeadManagerOptions() {

  const globalcontext = useContext(GlobalStateContext);
  const [mangData, setmangData] = useState(blankResult);


  function handleRestartLeadCount() {
    globalcontext.contextFcn.panel.modal.open(
      'danger',
      `Deseja reiniciar a contagem das leads?`,
      () => putJson(apiUrl.messagingthread, {
        ...mangData, payload: {
          mode: 'restartLeadCount'
        }
      }, setmangData)
    );
  }


  return (
    <>
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900">Gestor de Leads</h2>
        <p className="mt-1 text-sm leading-6 text-gray-500">
          Nesta secção pode alterar as configurações do gestor de Leads.
        </p>

        <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
          <div className="pt-6 sm:flex">
            <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">Reiniciar leads pendentes</dt>
            <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
              <div className="text-gray-900">

                <fieldset>
                  <legend className="sr-only">Notifications</legend>
                  <div className="space-y-5">



                  </div>
                </fieldset>
              </div>
              <button
                type="submit"
                onClick={handleRestartLeadCount}
                className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
              >
                Reiniciar contagem
              </button>
            </dd>
          </div>


        </dl>
      </div>


    </>
  );
}
