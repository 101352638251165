import { useSearchParams, useNavigate } from "react-router-dom";
import { categorySelected } from "../dataFetch";
import { FunnelIcon, XMarkIcon } from "@heroicons/react/24/outline";
import ModalContainer from "../../../common/modal/ModalContainer";
import { useState, useRef } from "react";
import FilterComponent from "../../../common/filter/FilterComponent";
import {
  isCustomFilterApplied,
  resetFilters,
  setCustomFilter,
  setResetCustomFilters,
} from "../../data/messagingSelect";
import { threadFilters } from "../../../common/filter/filterconst";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}



export function InboxSidebarTopicListHeader({
  itemsInboxCategories,
  getRMessagingTopics,
}) {
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  let navigate = useNavigate();

  const filterRef = useRef();

  const handleSave = () => {
    if (filterRef.current) {
      filterRef.current.handleSubmit();
      const filterList = filterRef.current.getFilterList();
      setCustomFilter(searchParams, navigate, filterList);
      setOpenModal(false);
    }
  };

  const handleResetFilters = () => {
    setOpenModal(false);
    setResetCustomFilters(searchParams, navigate);
  };

  return (
    <>
      <ModalContainer
        open={openModal}
        handleSubmit={handleSave}
        handleSecondarySubmit={handleResetFilters}
        setOpen={setOpenModal}
        title={"Filtros"}
        contentText={"Escolha os filtros a aplicar"}
        content={<FilterComponent filters={threadFilters} ref={filterRef} />}
        primaryButtonText={"Filtrar"}
        secondaryButtonText={"Reiniciar"}
        primaryButtonColor={"indigo"}
      />

      <div className="font-bold text-sm text-gray-600 w-full p-3">
        {getRMessagingTopics.params.threadId != null ? (
          <span>
            Ver tópico ID:
            <span className="pl-2 text-gray-400 font-normal">
              {getRMessagingTopics.params.threadId}
            </span>
          </span>
        ) : categorySelected(searchParams) == "all" ? (
          "Todas as Conversações"
        ) : categorySelected(searchParams) == "unanswered" ? (
          "Conversações por responder"
        ) : categorySelected(searchParams) == "mentions" ? (
          "Conversações que fui mencionado"
        ) : categorySelected(searchParams) == "unassigned" ? (
          "Conversações não atríbuidas"
        ) : categorySelected(searchParams).indexOf("group") > -1 &&
          itemsInboxCategories != null &&
          itemsInboxCategories.other_groups.length > 0 ? (
          `Grupo: ${
            itemsInboxCategories.other_groups.filter(
              (g) =>
                g.id ==
                parseInt(categorySelected(searchParams).replace("group:", ""))
            )[0].name
          }`
        ) : (
          ""
        )}
      </div>
      <div className="p-2">
        {getRMessagingTopics.params.threadId == null ? (
          <button
            onClick={() => setOpenModal(true)}
            type="button"
            className={classNames(
              isCustomFilterApplied(searchParams)
                ? "text-sky-800 bg-gray-100 hover:bg-gray-100 hover:text-sky-500"
                : "text-gray-400 bg-white hover:bg-gray-100 hover:text-gray-500",
              "relative ml-4 flex h-8 w-8 items-center justify-center",
              "rounded-full",
              "focus:outline-none focus:ring-2 focus:ring-indigo-500"
            )}
          >
            <span className="absolute -inset-1.5" />
            <FunnelIcon className="h-5 w-5" aria-hidden="true" />

            <span className="sr-only">Filtrar</span>
          </button>
        ) : (
          <button
            type="button"
            onClick={() => resetFilters(navigate)}
            className="rounded-full flex bg-white mt-1 px-1 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            <XMarkIcon className="h-3 w-3"/> 
          </button>
        )}
      </div>
    </>
  );
}
