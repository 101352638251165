import { Fragment, useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import usePutRequest from "../../hooks/usePutRequest";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProviderAppointmentCustomConfig({
  person,
  isCompleteAppCustomConfig,
  itemsAppCustomConfig,
}) {
  const {
    doPut: doPutConfig,
    items: itemsConfig,
    isComplete: isCompleteConfig,
  } = usePutRequest("provservcfg");

  const [providerConf, setProviderConf] = useState({});

  useEffect(() => {
    if (isCompleteConfig) {
      setProviderConf(itemsConfig.data);
    }
  }, [isCompleteConfig]);

  useEffect(() => {
    if (isCompleteAppCustomConfig)
      setProviderConf(
        itemsAppCustomConfig.data.filter((i) => i.providerId == person.id)[0]
      );
  }, [isCompleteAppCustomConfig]);

  function handleChangeSetting(_config) {
    doPutConfig({ providerId: person.id, ..._config });
  }

  function hasCustomConfig(_servSubTypeId) {
    if (providerConf.custom.length == 0) return false;
    return providerConf.custom.filter(
      (f) => f.servsubtype_id == _servSubTypeId
    )[0].active;
  }

  function getCustomConfig(sourceObj, field) {
    if (hasCustomConfig(sourceObj.id)) {
      const customConf = providerConf.custom.filter(
        (f) => f.servsubtype_id == sourceObj.id
      )[0];
      return customConf[field];
    } else return sourceObj[field];
  }

  return (
    <>
      <table className="min-w-full divide-y divide-gray-300">
        <tbody className="divide-y divide-gray-200 bg-white">
          <tr>
            <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 w-full">
              {person.fullname}
            </td>
            <td className="whitespace-nowrap py-2 pl-4 pr-6 text-sm font-medium text-gray-900 sm:pl-6"></td>
          </tr>
          <tr className="bg-gray-50">
            <td className="px-6 py-1" colSpan="5">
              {person.servtypes_strlist == "" ? (
                <div className="w-full text-center text-gray-700 text-sm font-normal p-2">
                  O médico não tem especialidades atribuídas
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-100 text-xs">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="w-24 py-1 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-0"
                      ></th>
                      <th
                        scope="col"
                        className="w-24 py-1 pl-4 pr-3 text-left text-xs font-semibold text-gray-900 sm:pl-0"
                      ></th>
                      <th
                        scope="col"
                        className="w-24 px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                      ></th>
                      <th
                        scope="col"
                        className="w-32 px-3 py-1.5 text-left text-xs font-semibold text-gray-900"
                      >
                        Ativo
                      </th>
                      <th
                        scope="col"
                        className="w-32 px-3 py-1.5 text-[10px] font-semibold text-gray-900 text-center"
                      >
                        Marcação direta
                      </th>
                      <th
                        scope="col"
                        className="w-32 px-3 py-1.5 text-[10px] font-semibold text-gray-900 text-center"
                      >
                        Pré-Pagamento
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {providerConf?.default?.map((j, idx) => (
                      <tr key={idx}>
                        <td className="w-24 whitespace-nowrap px-3 py-4 text-xs text-gray-500 text-center">
                          <input
                            checked={hasCustomConfig(j.id)}
                            onChange={(e) =>
                              handleChangeSetting({
                                subserviceTypeId: j.id,
                                type: "mode",
                                val: e.target.checked,
                              })
                            }
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                        </td>
                        <td className="max-w-[250px] min-w-[250px] whitespace-nowrap py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-0 truncate">
                          {j.servtype.name}
                        </td>
                        <td className="w-full whitespace-nowrap px-3 py-4 text-xs text-gray-500 truncate">
                          {j.name}
                        </td>
                        <td className="w-24 whitespace-nowrap px-3 py-4 text-xs text-gray-500 truncate">
                          <Switch
                            checked={getCustomConfig(j, "is_enabled")}
                            onChange={(e) =>
                              handleChangeSetting({
                                subserviceTypeId: j.id,
                                type: "enable",
                                val: e,
                              })
                            }
                            className={classNames(
                              getCustomConfig(j, "is_enabled") ? "bg-indigo-600" : "bg-gray-200",
                              "flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                getCustomConfig(j, "is_enabled")
                                  ? "translate-x-3.5"
                                  : "translate-x-0",
                                "h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                        </td>
                        <td className="w-24 whitespace-nowrap px-3 py-4 text-xs text-gray-500 text-center">
                          <input
                            checked={getCustomConfig(j, "allow_client_booking")}
                            onChange={(e) =>
                              handleChangeSetting({
                                subserviceTypeId: j.id,
                                type: "allow_client_booking",
                                val: e.target.checked,
                              })
                            }
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                        </td>
                        <td className="w-24 whitespace-nowrap px-3 py-4 text-xs text-gray-500 text-center">
                          <input
                            checked={getCustomConfig(j, "require_prepayment")}
                            onChange={(e) =>
                              handleChangeSetting({
                                subserviceTypeId: j.id,
                                type: "require_prepayment",
                                val: e.target.checked,
                              })
                            }
                            type="checkbox"
                            id="vehicle1"
                            name="vehicle1"
                            value="Bike"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
