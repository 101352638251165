import React from "react";
import useGetRequest from "../../hooks/useGetRequest";
import { useEffect } from "react";
import TableEngineTw from "../../common/table/TableEngineTw";
import { tableconfig as tbcfg } from "../../common/configs/tableconfig_customerportal_users";

// https://github.com/sadmann7/shadcn-table

const UserTable = ({ itemsUsers }) => {
  return (
    <table border="1">
      <thead>
        <tr>
          <th>Email</th>
          <th>Email Confirmed</th>
          <th>ID</th>
          <th>Last Sign In</th>
          <th>First Name</th>
          <th>Last Name</th>
          <th>Date of Birth</th>
          <th>Gender</th>
          <th>Nationality</th>
          <th>Phone</th>
          <th>Phone Confirmed</th>
          <th>ID Doc Type</th>
          <th>ID Doc Number</th>
        </tr>
      </thead>
      <tbody>
        {itemsUsers.data.map((i) => (
          <tr key={i.id}>
            <td>{i.email}</td>
            <td>{i.email_confirmed_at ? "Yes" : "No"}</td>
            <td>{i.id}</td>
            <td>
              {i.last_sign_in_at
                ? new Date(i.last_sign_in_at).toLocaleString()
                : "Never"}
            </td>
            <td>{i.profiles.firstname || "N/A"}</td>
            <td>{i.profiles.lastname || "N/A"}</td>
            <td>{i.profiles.date_of_birth || "N/A"}</td>
            <td>{i.profiles.gender || "N/A"}</td>
            <td>{i.profiles.nationality || "N/A"}</td>
            <td>{i.profiles.phone || "N/A"}</td>
            <td>{i.profiles.phone_confirmed_at ? "Yes" : "No"}</td>
            <td>{i.profiles.id_doc_type || "N/A"}</td>
            <td>{i.profiles.id_doc_num || "N/A"}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const CustomerPortalUsers = () => {
  const {
    doGet: doGetUsers,
    getR: getRUsers,
    isComplete: isCompleteUsers,
    items: itemsUsers,
  } = useGetRequest("customerPortalUsers");

  useEffect(() => {
    doGetUsers();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Utilizadores
          </h1>
          <p className="mt-2 text-sm text-gray-700">Listagem de utilizadores</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <TableEngineTw
          cfg={tbcfg}
          items={isCompleteUsers && itemsUsers}
          fetchData={doGetUsers}
        />
      </div>
    </div>
  );
};

export default CustomerPortalUsers;
