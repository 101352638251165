import { useEffect } from "react";
import usePutRequest from "../../hooks/usePutRequest";

export default function NotificationList({ notifications, setNotifications }) {
  const {
    doPut: doPutNotf,
    items: itemsNotf,
    isComplete: isCompleteNotf,
  } = usePutRequest("notification");

  function handleClearNotification(notfId) {
    doPutNotf({
      action: "hideNotification",
      notificationId: notfId,
    });
  }

  useEffect(() => {
    if (isCompleteNotf) {
      setNotifications(itemsNotf.unread_notifications);
    }
  }, [isCompleteNotf]);

  return (
    <>
      <ul role="list" className="divide-y divide-gray-100">
        {notifications.map((item, k) => (
          <li key={k} className="py-4">
            <div className="flex items-center gap-x-3">
              <h3 className="flex-auto truncate text-sm font-semibold leading-6 text-gray-900">
                {item.label}
              </h3>
              <time
                dateTime={item.created_at}
                className="flex-none text-xs text-gray-500"
              >
                {item.created_at}
              </time>
            </div>
            <p className="mt-3 truncate text-sm text-gray-500">
              Pushed to <span className="text-gray-700">A</span> (
              <span className="font-mono text-gray-700">B</span> on{" "}
              <span className="text-gray-700">C</span>)
              <span className="pl-6">
                <button onClick={() => handleClearNotification(item.id)}>
                  Esconder
                </button>
              </span>
            </p>
          </li>
        ))}
      </ul>
      <button onClick={() => handleClearNotification('all')}>Escoder tudo</button>
    </>
  );
}
