import TemplateItem from './TemplateItem'


export default function TemplateList({ templates, onEditTemplate }) {
  return (
    <div className="space-y-4">
      {templates.map((template) => (
        <TemplateItem
          key={template.id}
          template={template}
          onEdit={() => onEditTemplate(template)}
        />
      ))}
    </div>
  )
}

