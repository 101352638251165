import React from "react";
import RoundAvatar from "../../common/card/RoundAvatar";
import NavUserActionMenu from "../NavUserActionMenu";
import { useSocket } from "../../socket/SocketProvider";

import {
  ChevronDownIcon,
} from "@heroicons/react/20/solid";

export default function NavUserMenu({ userData }) {
  const {  socketState } = useSocket();

  return (
    <>
      <NavUserActionMenu
        content={
          <div className="-m-1.5 flex items-center p-1.5">
            <span className="sr-only">Abrir menu utilizador</span>

            <RoundAvatar
              size={2}
              avatarUrl={userData.user?.avatar_url_sm}
              firstName={userData.user?.name}
              lastName={userData.user?.lastname}
              borderColor={
                socketState.connected 
                ? 'emerald-500'
                : 'red-500'
              }
            />
            <span className="hidden lg:flex lg:items-center">
              <span
                className="ml-4 text-sm font-semibold leading-6 text-gray-900 truncate"
                aria-hidden="true"
              >
                {`${userData.user?.name}`}
              </span>
              <ChevronDownIcon
                className="ml-2 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </div>
        }
      />
    </>
  );
}
