import { useState, useMemo, useCallback, useEffect } from 'react';

export function useTableLogic(data, config) {

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(config.pagination?.itemsPerPage || 10);
  const [sortConfig, setSortConfig] = useState(config.initialSort);
  const [filters, setFilters] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(
    new Set(config.columns.map((col) => col.key))
  );
  const [backendData, setBackendData] = useState(config.backend?.initialData || []);
  const [totalItems, setTotalItems] = useState(config.backend?.initialData?.length || data.length);

  const isBackend = !!config.backend;

  const toggleColumnVisibility = useCallback((key) => {
    setVisibleColumns((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(key)) {
        newSet.delete(key);
      } else {
        newSet.add(key);
      }
      return newSet;
    });
  }, []);

  const filteredData = useMemo(() => {
    if (isBackend) return backendData;
    return data.filter((item) =>
      Object.entries(filters).every(([key, value]) => {
        const itemValue = item[key];
        return typeof itemValue === 'string'
          ? itemValue.toLowerCase().includes(value.toString().toLowerCase())
          : itemValue === value;
      })
    );
  }, [data, filters, isBackend, backendData]);

  const sortedData = useMemo(() => {
    if (isBackend) return backendData;
    if (!sortConfig) return filteredData;
    return [...filteredData].sort((a, b) => {
      const aValue = a[sortConfig.key];
      const bValue = b[sortConfig.key];
      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1;
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1;
      return 0;
    });
  }, [filteredData, sortConfig, isBackend, backendData]);

  const paginatedData = useMemo(() => {
    if (isBackend) return backendData;
    const startIndex = (currentPage - 1) * itemsPerPage;
    return sortedData.slice(startIndex, startIndex + itemsPerPage);
  }, [sortedData, currentPage, itemsPerPage, isBackend, backendData]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleSort = useCallback((key) => {
    setSortConfig((prev) =>
      prev && prev.key === key
        ? { ...prev, direction: prev.direction === 'asc' ? 'desc' : 'asc' }
        : { key, direction: 'asc' }
    );
    if (isBackend) setCurrentPage(1);
  }, [isBackend]);

  const handleFilter = useCallback((key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
    setCurrentPage(1);
  }, []);

  const handleSelectItem = useCallback((item) => {
    setSelectedItems((prev) =>
      prev.includes(item) ? prev.filter((i) => i !== item) : [...prev, item]
    );
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedItems((prev) =>
      prev.length === paginatedData.length ? [] : [...paginatedData]
    );
  }, [paginatedData]);

  const fetchData = useCallback(async () => {
    if (isBackend && config.backend?.onFetch) {
      const params = {
        page: currentPage,
        itemsPerPage,
        sortConfig,
        filters,
      };
      const result = await config.backend.onFetch(params);
      setBackendData(result.data);
      setTotalItems(result.totalItems);
    }
  }, [isBackend, config.backend, currentPage, itemsPerPage, sortConfig, filters]);

  useEffect(() => {
    if (isBackend) {
      fetchData();
    }
  }, [isBackend, fetchData]);

  return {
    paginatedData,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    sortConfig,
    handleSort,
    filters,
    handleFilter,
    selectedItems,
    handleSelectItem,
    handleSelectAll,
    visibleColumns,
    toggleColumnVisibility,
    totalItems,
  };
}

