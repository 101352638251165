import { useEffect, useState } from "react";
import { FlexibleTable } from "../../../components/table/FlexibleTable";
import { ArrowRight } from "lucide-react";
import { Button } from "@/components/ui/button";
import dayjs from "dayjs";

import { useLocation, useNavigate } from "react-router-dom";
import AutomationEventsSourceData from "./AutomationEventsSourceData";

/* const backendTableConfig = {
  ...frontendTableConfig,
  backend: {
    onFetch: async (params) => {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 500));

      let filteredData = [...users];

      // Apply filters
      Object.entries(params.filters).forEach(([key, value]) => {
        filteredData = filteredData.filter((item) =>
          String(item[key]).toLowerCase().includes(String(value).toLowerCase())
        );
      });

      // Apply sorting
      if (params.sortConfig) {
        filteredData.sort((a, b) => {
          if (a[params.sortConfig.key] < b[params.sortConfig.key])
            return params.sortConfig.direction === "asc" ? -1 : 1;
          if (a[params.sortConfig.key] > b[params.sortConfig.key])
            return params.sortConfig.direction === "asc" ? 1 : -1;
          return 0;
        });
      }

      // Apply pagination
      const start = (params.page - 1) * params.itemsPerPage;
      const paginatedData = filteredData.slice(
        start,
        start + params.itemsPerPage
      );

      return {
        data: paginatedData,
        totalItems: filteredData.length,
      };
    },
  },
};
 */

export function AutomationEventsTable({ events, isLoading }) {
  const [useBackend, setUseBackend] = useState(false);
  const navigate = useNavigate();

  const handleNavClick = (wfId) => {
    navigate(`/automation?tab=event_log&wfExecution=${wfId}`);
  };



  const columns = [
    {
      key: "workflow",
      header: "workflow",
      render: (workflow) =>
        workflow ? (
          <span className={`text-[10px] font-semibold  text-green-800`}>
            {workflow.name}
          </span>
        ) : (
          ""
        ),
      sortable: false,
      filterable: false,
    },
    {
      key: "executed_at",
      header: "executed_at",
      sortable: true,
      render: (e) => (e != null && e != "" ? dayjs(e).format("H:mm:ss") : ""),
      filterable: false,
    },
    {
      key: "finished_at",
      header: "finished_at",
      sortable: true,
      render: (e) => (e != null && e != "" ? dayjs(e).format("H:mm:ss") : ""),
      filterable: false,
    },
    { key: "status", header: "status", sortable: true, filterable: false },
    {
      key: "_trigger_type",
      header: "_trigger_type",
      sortable: true,
      filterable: false,
    },
    {
      key: "fetched_data",
      header: "fetched_data",
      render: (fetched_data) =>
        fetched_data ? (
          <AutomationEventsSourceData
            srcData={fetched_data}
          />
        ) : (
          ""
        ),
      sortable: false,
      filterable: false,
    },
    {
      key: "execution_logs",
      header: "execution_logs",
      render: (execution_logs, allprops) =>
        execution_logs ? (
          <span className="">
            {execution_logs != null ? (
              <span className="flex">
                {execution_logs.length}{" "}
                {execution_logs.length > 0 && (
                  <span className="pl-2">
                    <Button
                      className="px-2 py-0"
                      variant="outline"
                      onClick={() => handleNavClick(allprops.id)}
                    >
                      <ArrowRight className="h-2 w-2" />
                    </Button>
                  </span>
                )}
              </span>
            ) : (
              0
            )}
          </span>
        ) : (
          ""
        ),
      sortable: false,
      filterable: false,
    },
  ];

  const frontendTableConfig = {
    columns,
    initialSort: { key: "id", direction: "asc" },
    selectable: true,
    pagination: {
      itemsPerPage: 10,
      itemsPerPageOptions: [5, 10, 20, 50],
    },
  };

  return (
    <main className="container mx-auto py-10">
      <FlexibleTable
        isLoading={isLoading}
        data={events}
        config={frontendTableConfig}
        //config={useBackend ? backendTableConfig : frontendTableConfig}
      />
    </main>
  );
}
