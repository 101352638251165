
import { useState, useEffect } from 'react'

export const mockWorkflows = [
  { id: '1', name: 'Onboarding Process' },
  { id: '2', name: 'Invoice Generation' },
  { id: '3', name: 'Customer Follow-up' },
  { id: '4', name: 'Data Backup' },
  { id: '5', name: 'Report Generation' },
];

export const mockTriggers = [
  {
    id: '1',
    name: 'Daily Onboarding Check',
    trigger_type: 'time_based',
    trigger_time: '09:00',
    workflow: '1',
    active: true,
    weekday: ['mon', 'tue', 'wed', 'thu', 'fri'],
  },
  {
    id: '2',
    name: 'Monthly Invoice',
    trigger_type: 'event_date_based',
    trigger_time: '08:00',
    relative_days: 1,
    workflow: '2',
    active: true,
    weekday: ['mon'],
  },
  {
    id: '3',
    name: 'Weekly Backup',
    trigger_type: 'time_based',
    trigger_time: '23:00',
    workflow: '4',
    active: true,
    weekday: ['sun'],
  },
];

export function useWorkflows() {
  const [workflows, setWorkflows] = useState(mockWorkflows)
  return { workflows }
}

