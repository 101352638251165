// src/oidcConfig.js
import { WebStorageStateStore } from 'oidc-client-ts';

const isProd = process.env.NODE_ENV === 'production';

const oidcConfig = {
    authority: 'https://auth.medzi.io/realms/medzi',
    client_id: 'medzi-console',
    redirect_uri: isProd
    ? 'https://admin.medzi.io/callback'
    : 'https://localhost:5173/callback',
    silent_redirect_uri: isProd
    ? 'https://admin.medzi.io/silent-renew'
    : 'https://localhost:5173/silent-renew',
    post_logout_redirect_uri: isProd
    ? 'https://admin.medzi.io/'
    : 'https://localhost:5173/',
    userStore: new WebStorageStateStore({ store: window.localStorage }), // Use localStorage
    response_type: 'code',
    scope: 'openid profile email',
    automaticSilentRenew: true,
    loadUserInfo: true,
  };
  
  export default oidcConfig;

