import { useState } from "react";
import { FlexibleTable } from "../../../components/table/FlexibleTable";
import { X, Check, Hourglass } from "lucide-react";
import dayjs from "dayjs";
import { AutomationEventLogsDetailTable } from "./AutomationEventLogsDetailTable";
import { AutomationEventLogsStepsTable } from "./AutomationEventLogsStepsTable";
import { AutomationEventPendingResponseTable } from "./AutomationEventPendingResponseTable";
import { AutomationEventLogsResult } from "./AutomationEventLogsResult";

/* 
const columns = [
  { key: 'name', label: 'Name', sortable: true, filterable: true, filterType: 'text' },
  { key: 'role', label: 'Role', sortable: true, filterable: true, filterType: 'unique', filterOptions: ['Developer', 'Designer', 'Manager'] },
  { key: 'status', label: 'Status', filterable: true, filterType: 'checkbox', filterOptions: ['Active', 'Inactive'] },
  { key: 'startDate', label: 'Start Date', sortable: true, filterable: true, filterType: 'date' },
  { key: 'action', label: 'Action' },
] */

const columns = [
  {
    key: "executed_at",
    header: "executed_at",
    render: (e) => (e != null && e != "" ? dayjs(e).format("H:mm:ss") : ""),
    sortable: true,
    filterable: false,
  },
  {
    key: "pivot_field",
    header: "pivot_field",
    sortable: true,
    filterable: true,
    filterType: 'text' 
  },
  {
    key: "result",
    header: "result",
    render: (e) =>
      e == "success" ? (
        <Check className="h-5 w-5 text-emerald-500" />
      ) : e == "error" ? (
        <X className="h-5 w-5 text-red-500" />
      ) : e == "pending" ? (
        <Hourglass className="h-5 w-5 text-yellow-500" />
      ) : (
        e
      ),
    sortable: false,
    filterable: false,
  },
  {
    key: "event",
    header: "event",
    render: (e) => <AutomationEventLogsDetailTable events={e.event_metadata} />,
    sortable: false,
    filterable: false,
  },
  {
    key: "event_ref",
    header: "event_ref",
    render: (e) => <AutomationEventLogsStepsTable events={e} />,
    sortable: false,
    filterable: false,
  },
  {
    key: "event_ref",
    header: "event_ref",
    render: (e) => <AutomationEventLogsResult events={e} />,
    sortable: false,
    filterable: false,
  }
];

// event_metadata

const frontendTableConfig = {
  columns,
  initialSort: { key: "executed_at", direction: "asc" },
  selectable: true,
  columnSelector: true,
  canDownload: true,
  pagination: {
    itemsPerPage: 10,
    itemsPerPageOptions: [5, 10, 20, 50],
  },
};

/* const backendTableConfig = {
  ...frontendTableConfig,
  backend: {
    onFetch: async (params) => {
      // Simulate API call
      await new Promise((resolve) => setTimeout(resolve, 500));

      let filteredData = [...users];

      // Apply filters
      Object.entries(params.filters).forEach(([key, value]) => {
        filteredData = filteredData.filter((item) =>
          String(item[key]).toLowerCase().includes(String(value).toLowerCase())
        );
      });

      // Apply sorting
      if (params.sortConfig) {
        filteredData.sort((a, b) => {
          if (a[params.sortConfig.key] < b[params.sortConfig.key])
            return params.sortConfig.direction === "asc" ? -1 : 1;
          if (a[params.sortConfig.key] > b[params.sortConfig.key])
            return params.sortConfig.direction === "asc" ? 1 : -1;
          return 0;
        });
      }

      // Apply pagination
      const start = (params.page - 1) * params.itemsPerPage;
      const paginatedData = filteredData.slice(
        start,
        start + params.itemsPerPage
      );

      return {
        data: paginatedData,
        totalItems: filteredData.length,
      };
    },
  },
};
 */

export function AutomationEventLogsTable({ events, isLoading }) {
  const [useBackend, setUseBackend] = useState(false);

  return (
    <main className="container mx-auto py-10">
      <FlexibleTable
        data={events}
        isLoading={isLoading}
        config={frontendTableConfig}
        //config={useBackend ? backendTableConfig : frontendTableConfig}
      />
    </main>
  );
}
