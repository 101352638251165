import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const AutomationNavigation = ({ tabs, sel, setSel, flatMenu }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const baseRoute = "automation";

  // Extract `tab` query parameter
  const queryParams = new URLSearchParams(location.search);
  const currentTabCode = queryParams.get("tab");

  // Update `sel` based on the `tab` query parameter
  useEffect(() => {
    if (currentTabCode && currentTabCode != "") {
      setSel(currentTabCode);
    } else {
      // Default to the first tab if no valid `tab` parameter is present
      setSel(flatMenu[0].tabCode);
      navigate(`/${baseRoute}?tab=${flatMenu[0].tabCode}`, { replace: true });
    }
  }, [currentTabCode, tabs, setSel, navigate]);

  const handleTabClick = (tabCode) => {
    setSel(tabCode);
    navigate(`/${baseRoute}?tab=${tabCode}`);
  };

  return (
    <aside className={`border-r border-gray-100 bg-gray-50`}>
      <div className="h-full">
        {tabs.map((t, k) => (
          <div className="group/menu-item relative" key={k}>
            <div className="peer/menu-button flex w-full items-center gap-2 rounded-md p-2 text-left text-sm outline-none ring-sidebar-ring transition-[width,height,padding] hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 group-has-[[data-sidebar=menu-action]]/menu-item:pr-8 aria-disabled:pointer-events-none aria-disabled:opacity-50 data-[active=true]:bg-sidebar-accent data-[active=true]:font-medium data-[active=true]:text-sidebar-accent-foreground data-[state=open]:hover:bg-sidebar-accent data-[state=open]:hover:text-sidebar-accent-foreground group-data-[collapsible=icon]:!size-8 group-data-[collapsible=icon]:!p-2 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 h-8">
              {!t.disabled ? (
                <button onClick={() => handleTabClick(t.tabCode)} className="">
                  {t.title}
                </button>
              ) : (
                <span className="">{t.title}</span>
              )}
            </div>
            {t.items?.length ? (
              <div className="ml-3.5 flex min-w-0 translate-x-px flex-col gap-1 border-l border-sidebar-border py-0.5 group-data-[collapsible=icon]:hidden">
                {t.items.map((item) => {
                  if (!(item.hide != null && item.hide))
                    return (
                      <div
                        className="flex h-7 min-w-0 -translate-x-px items-center gap-2 rounded-md px-2 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground text-xs group-data-[collapsible=icon]:hidden"
                        key={item.title}
                      >
                        <div
                          className="flex h-7 min-w-0 -translate-x-px items-center gap-2 rounded-md px-2 text-sidebar-foreground outline-none ring-sidebar-ring hover:bg-sidebar-accent hover:text-sidebar-accent-foreground focus-visible:ring-2 active:bg-sidebar-accent active:text-sidebar-accent-foreground disabled:pointer-events-none disabled:opacity-50 aria-disabled:pointer-events-none aria-disabled:opacity-50 [&>span:last-child]:truncate [&>svg]:size-4 [&>svg]:shrink-0 [&>svg]:text-sidebar-accent-foreground data-[active=true]:bg-sidebar-accent data-[active=true]:text-sidebar-accent-foreground text-xs group-data-[collapsible=icon]:hidden"
                   
                        >
                          <button onClick={() => handleTabClick(item.tabCode)}>
                            {item.title}
                          </button>
                        </div>
                      </div>
                    );
                })}
              </div>
            ) : null}
          </div>
        ))}
      </div>
    </aside>
  );
};
