import React from "react";
import TabNavigationControl from "../nav/tabs/TabNavigationControl";
import CustomerPortalUsers from "./list/CustomerPortalUsers";
import CustomerPortalSync from "./sync/CustomerPortalSync";
import CustomerPortalBookings from "./bookings/CustomerPortalBookings";


const CustomerPortalHome = () => {
  const [sel, setSel] = React.useState(0);

  const tabs = [
    {
      title: "Marcações",
      idx: 0,
      content: <CustomerPortalBookings/>,
      disabled: false,
    },
    {
      title: "Registos",
      idx: 1,
      content: <CustomerPortalUsers/>,
      disabled: false,
    },
    {
      title: "Sincronizar",
      idx: 2,
      content: <CustomerPortalSync/>,
      disabled: false,
    },
    {
      title: "Notificações",
      idx: 3,
      content: <>3</>,
      disabled: false,
    }
  ];

  return (
    <>
      <TabNavigationControl sel={sel} setSel={setSel} tabs={tabs} />
    </>
  );
};

export default CustomerPortalHome;
