import { apiUrl } from "../common/apiEndpoints";

const ogrp_fields = [
  {
    selector: "input",
    required: true,
    class: "",
    type: "text",
    name: "name",
    title: "Nome",
    placeholder: "Medicina Dentária",
    id: "name",
    map: true,
    validation: (f) => f.length > 0,
    validationerr: "Nome Inválido.",
  },
  {
    selector: "input",
    required: false,
    class: "",
    type: "text",
    name: "description",
    title: "Descrição",
    placeholder: "",
    id: "description",
    map: true,
  },
];

function edit_resources(_field, _role, _title) {
  return [
    {
      selector: "stackedit",
      required: true,
      name: _field,
      title: _title,
      id: `operatorgroup_${_field}`,
      map: true,
      stack: {
        title: _field,
        subtitle: "name",
        listItemView: (e) => {
          return (
            <>
              <span className="truncate font-medium">
                {e.providers.fullname}
              </span>
              <span className="flex-shrink-0 text-gray-400"></span>
            </>
          );
        },
        fields: [
          {
            selector: "selectprovider",
            providerRole: _role, //["crm:selfassign"],
            required: true,
            name: _field,
            title: _title,
            placeholder: "Nome...",
            id: _field,
          },
        ],
      },
    },
  ];
}

function rows_edit(_field, _role, _title) {
  return [
    ...edit_resources(_field, _role, _title).filter((r) => r.name == "name"),
    {
      ...edit_resources(_field, _role, _title).filter((r) => r.name == _field)[0],
      stack: {
        title: "name",
        subtitle: "name",
        listItemView: (e) => {
          let item = e;
          // adge case for when we have a mix of new and old items
          if (_field in item) {
            item = { ...item[_field] };
          }
          return (
            <>
              <span className="truncate font-medium">{item.fullname}</span>
              <span className="flex-shrink-0 text-gray-400"></span>
            </>
          );
        },
        fields: edit_resources(_field, _role, _title).filter((r) => r.name == _field)[0].stack
          .fields,
      },
    },
  ];
}

export const formfield_editogrp = {
  title: "Editar Grupo",
  apiEndpoint: apiUrl.operatorgroup,
  successMessage: "Alterações guardadas",
  method: "PUT",
  preloadForm: true,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [...ogrp_fields],
};

export const formfield_newogrp = {
  title: "Novo Grupo",
  apiEndpoint: apiUrl.operatorgroup,
  successMessage: "Alterações guardadas",
  method: "POST",
  preloadForm: false,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [...ogrp_fields],
};

export const formfield_editogrp_operators = {
  title: "Editar Operadores do Grupo",
  apiEndpoint: apiUrl.operatorgroupMembers,
  successMessage: "Alterações guardadas",
  method: "PUT",
  preloadForm: true,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [...rows_edit("members", ["crm:selfassign"], "Operadores")],
};

export const formfield_editogrp_resources = {
  title: "Editar Recursos do Grupo",
  apiEndpoint: apiUrl.operatorgroupMembers,
  successMessage: "Alterações guardadas",
  method: "PUT",
  preloadForm: true,
  name: "",
  id: "",
  submitLabel: "Guardar",
  rows: [...rows_edit("providers", ["crm:selfassign"], "Recursos")],
};
