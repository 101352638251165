import PropTypes from 'prop-types';

export const columnConfigPropType = PropTypes.shape({
  key: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  render: PropTypes.func,
  sortable: PropTypes.bool,
  filterable: PropTypes.bool,
});

export const tableConfigPropType = PropTypes.shape({
  columns: PropTypes.arrayOf(columnConfigPropType).isRequired,
  initialSort: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  selectable: PropTypes.bool,
  pagination: PropTypes.shape({
    itemsPerPage: PropTypes.number.isRequired,
    itemsPerPageOptions: PropTypes.arrayOf(PropTypes.number),
  }),
  backend: PropTypes.shape({
    onFetch: PropTypes.func.isRequired,
    initialData: PropTypes.array,
  }),
});

export const filterConfigPropType = PropTypes.objectOf(
  PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
);

export const fetchParamsPropType = PropTypes.shape({
  page: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  sortConfig: PropTypes.shape({
    key: PropTypes.string.isRequired,
    direction: PropTypes.oneOf(['asc', 'desc']).isRequired,
  }),
  filters: filterConfigPropType.isRequired,
});

export const fetchResultPropType = PropTypes.shape({
  data: PropTypes.array.isRequired,
  totalItems: PropTypes.number.isRequired,
});

