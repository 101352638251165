import React, { useState } from 'react';
import FormSlideover from '../../common/slideover/FormSlideover';
import SpeedDialAdd from '../../nav/speeddial/SpeedDialAdd';
import { reqOk, blankResult, getJson } from '../../common/fetchdata';
import { apiUrl } from '../../common/apiEndpoints';
import { tableconfig as tbcfg } from '../../common/configs/tableconfig_operatorgroup';
import TableEngineTw from '../../common/table/TableEngineTw';
import { formfield_newogrp as f_new } from "../../forms/formfield_operatorgroup";

export default function OperatorGroupManagement({  }) {
  const [getRes, setGetRes] = React.useState(blankResult);

  // Form data - edit/add entry
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);

  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1)
    setOpenAdd(false)
    fetchData()
  };

  const edit_errHandler = () => {
    console.error('Error processing request')
    setFormKey((k) => k + 1)
    setOpenAdd(false)
  }
  // end form data

  const fetchData = (args = {}) => {
    getJson(
      apiUrl.operatorgroup,
      { ...getRes, params: args },
      setGetRes
    );
  };

  return (
    <>
      <FormSlideover
        fin={f_new}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
      <SpeedDialAdd onClickAction={handleAddNewItem} />

      <TableEngineTw
        cfg={tbcfg}
        items={reqOk(getRes) && getRes.items}
        fetchData={fetchData}
        refreshHandler={fetchData}
      />
    </>
  );
}
