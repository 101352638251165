import {
  AtSymbolIcon,
  ChatBubbleLeftIcon,
  ChevronDownIcon,
  FolderIcon,
  InboxArrowDownIcon,
  InboxIcon,
} from "@heroicons/react/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import useGetRequest from "../../../hooks/useGetRequest";
import { useRef, useEffect } from "react";
import { categorySelected, goToCategory } from "../dataFetch";
import { useSearchParams, useNavigate } from "react-router-dom";
import NewTagModal from "../../tags/NewTagModal";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function InboxSelectSidebar({
  isCompleteInboxCategories,
  itemsInboxCategories,
  doGetInboxCategories,
}) {
  let navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const newTagModalRef = useRef();

  const navigation = [
    {
      name: "Tudo",
      icon: FolderIcon,
      view: "all",
      unread: isCompleteInboxCategories ? itemsInboxCategories.count.all : null,
    },
    {
      name: "Por responder",
      icon: ChatBubbleLeftIcon,
      view: "unanswered",
      unread: isCompleteInboxCategories
        ? itemsInboxCategories.count.not_answered
        : null,
    },
    {
      name: "Menções",
      icon: AtSymbolIcon,
      view: "mentions",
      unread: isCompleteInboxCategories
        ? itemsInboxCategories.count.mentioned
        : null,
    },
    {
      name: "Atribuídos a mim",
      icon: InboxArrowDownIcon,
      view: "mine",
      unread: isCompleteInboxCategories
        ? itemsInboxCategories.count.mine
        : null,
    },
    {
      name: "Não atribuído",
      icon: InboxIcon,
      view: "unassigned",
      unread: isCompleteInboxCategories
        ? itemsInboxCategories.count.notassigned
        : null,
    },
  ];

  /*   
TODO
Fix this !!
useEffect(() => {
    if (newTagModalRef.current.isCompletePostTag()) {
      doGetInboxCategories();
    }
  }, [newTagModalRef.current?.isCompletePostTag]); */

  return (
    <>
      <NewTagModal ref={newTagModalRef} />
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-3">
          <div>
            <ul role="list" className="-mx-2 space-y-1">
              {navigation.map((item) => (
                <li key={item.name}>
                  <a
                    href="#"
                    onClick={() =>
                      goToCategory(searchParams, navigate, item.view)
                    }
                    className={classNames(
                      item.view == categorySelected(searchParams)
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                      "group flex gap-x-3 rounded-lg px-2 py-2 text-sm font-semibold leading-6 w-full"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.view == categorySelected(searchParams)
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-4 w-4 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                    {item.unread ? (
                      <span
                        aria-hidden="true"
                        className="ml-auto w-7 min-w-max whitespace-nowrap rounded-md bg-white px-0.5 py-0.5 text-center text-[8px] font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                      >
                        {item.unread}
                      </span>
                    ) : null}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <div className="text-xs font-semibold leading-6 text-gray-400">
              Meus Grupos
            </div>
            <ul role="list" className="-mx-2 mt-2 space-y-1">
              {isCompleteInboxCategories &&
                (itemsInboxCategories.my_groups.length > 0 ? (
                  itemsInboxCategories.my_groups.map((item) => (
                    <li key={item.name}>
                      <a
                        href="#"
                        onClick={() =>
                          goToCategory(
                            searchParams,
                            navigate,
                            `group:${item.id}`
                          )
                        }
                        className={classNames(
                          item.view == categorySelected(searchParams)
                            ? "bg-gray-50 text-indigo-600"
                            : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                          "group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 w-fit"
                        )}
                      >
                        <span
                          className={classNames(
                            item.view == categorySelected(searchParams)
                              ? "border-indigo-600 text-indigo-600"
                              : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                            "flex mt-0.5 h-4 w-4 shrink-0 items-center justify-center rounded-md border bg-white text-[0.6rem] font-medium"
                          )}
                        >
                          {item.name[0]}
                        </span>
                        <span className="truncate">{item.name}</span>
                      </a>
                    </li>
                  ))
                ) : (
                  <span className="text-xs pl-4 text-gray-300">
                    Não pertence a nenhum grupo
                  </span>
                ))}
              <li>
                <Disclosure>
                  <DisclosureButton className="text-xs font-semibold leading-6 text-gray-400 group flex items-center gap-2 group gap-x-3 rounded-md p-2 w-fit">
                    Outros Grupos
                    <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
                  </DisclosureButton>
                  <DisclosurePanel
                    transition
                    className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
                  >
                    {isCompleteInboxCategories &&
                      (itemsInboxCategories.other_groups.length > 0 ? (
                        itemsInboxCategories.other_groups.map((item) => (
                          <li key={item.name}>
                            <a
                              href="#"
                              onClick={() =>
                                goToCategory(
                                  searchParams,
                                  navigate,
                                  `group:${item.id}`
                                )
                              }
                              className={classNames(
                                item.view == categorySelected(searchParams)
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                                "group flex gap-x-2 rounded-md p-2 text-xs font-semibold leading-6 w-fit"
                              )}
                            >
                              <span
                                className={classNames(
                                  item.view == categorySelected(searchParams)
                                    ? "border-indigo-600 text-indigo-600"
                                    : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                                  "flex mt-0.5 h-4 w-4 shrink-0 items-center justify-center rounded-md border bg-white text-[0.6rem] font-medium"
                                )}
                              >
                                {item.name[0]}
                              </span>
                              <span className="truncate">{item.name}</span>
                            </a>
                          </li>
                        ))
                      ) : (
                        <span className="text-xs pl-4 text-gray-300">
                          Sem registos
                        </span>
                      ))}
                  </DisclosurePanel>
                </Disclosure>
              </li>
            </ul>
          </div>
          <li>
            <Disclosure>
              <DisclosureButton className="text-xs font-semibold leading-6 text-gray-400 group flex items-center group gap-x-3 rounded-md w-fit">
                Caixas de correio
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>
              <DisclosurePanel
                transition
                className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
              >
                {isCompleteInboxCategories &&
                  (itemsInboxCategories.inboxes.length > 0 ? (
                    itemsInboxCategories.inboxes.map((item) => (
                      <li key={item.name}>
                        <a
                          href="#"
                          onClick={() =>
                            goToCategory(
                              searchParams,
                              navigate,
                              `inbox:${item.id}`
                            )
                          }
                          className={classNames(
                            item.view == categorySelected(searchParams)
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                            "group flex gap-x-2 rounded-md p-1 text-xs font-semibold leading-6 w-fit"
                          )}
                        >
                          <span
                            className={classNames(
                              item.view == categorySelected(searchParams)
                                ? "border-indigo-600 text-indigo-600"
                                : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                              "flex mt-0.5 h-4 w-4 shrink-0 items-center justify-center rounded-md border bg-white text-[0.5rem] font-medium"
                            )}
                          >
                            {item.name[0]}
                          </span>
                          <span className="truncate">{item.name}</span>
                        </a>
                      </li>
                    ))
                  ) : (
                    <span className="text-xs pl-4 text-gray-300">
                      Sem registos
                    </span>
                  ))}
              </DisclosurePanel>
            </Disclosure>
          </li>

          <li>
            <Disclosure>
              <DisclosureButton className="text-xs font-semibold leading-6 text-gray-400 group flex items-center group gap-x-3 rounded-md w-fit">
                Etiquetas
                <ChevronDownIcon className="w-5 group-data-[open]:rotate-180" />
              </DisclosureButton>
              <DisclosurePanel
                transition
                className="origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
              >
                {isCompleteInboxCategories &&
                  (itemsInboxCategories.tags.length > 0 ? (
                    itemsInboxCategories.tags.map((item) => (
                      <li key={item.name}>
                        <a
                          href="#"
                          onClick={() =>
                            goToCategory(
                              searchParams,
                              navigate,
                              `tag:${item.id}`
                            )
                          }
                          className={classNames(
                            item.view == categorySelected(searchParams)
                              ? "bg-gray-50 text-indigo-600"
                              : "text-gray-700 hover:bg-gray-50 hover:text-indigo-600",
                            "group flex gap-x-2 rounded-md p-1 text-xs font-semibold leading-6 w-fit"
                          )}
                        >
                          <span
                            className={classNames(
                              item.view == categorySelected(searchParams)
                                ? "border-indigo-600 text-indigo-600"
                                : "border-gray-200 text-gray-400 group-hover:border-indigo-600 group-hover:text-indigo-600",
                              "flex mt-0.5 h-4 w-4 shrink-0 items-center justify-center rounded-md border bg-white text-[0.5rem] font-medium"
                            )}
                          >
                            {item.name[0]}
                          </span>
                          <span className="truncate">{item.name}</span>
                        </a>
                      </li>
                    ))
                  ) : (
                    <span className="text-xs pl-4 text-gray-300"></span>
                  ))}
              </DisclosurePanel>
            </Disclosure>
          </li>
        </ul>
        <span className="text-xs pt-2 text-gray-300">
          <a href="#" onClick={() => newTagModalRef.current.openModal()}>
            Nova Etiqueta
          </a>
        </span>
      </nav>
    </>
  );
}
