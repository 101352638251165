import React from "react";

const AnalyticsBoard = ({ analyticsOpts, isLoadingComplete }) => {
  return (
    <>
      {isLoadingComplete && (
        <div style={{ height: "100vh", overflow: "hidden" }}>
          <iframe
            src={`${analyticsOpts.analyticsDomain}/embed/dashboard/${analyticsOpts.analyticsToken}#bordered=false&titled=false`}
            allowTransparency
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
            allowFullScreen
          />
        </div>
      )}
    </>
  );
};

export default AnalyticsBoard;
