import { useEffect, useState } from "react";
import AutomationEventsDateNav from "./AutomationEventsDateNav";
import dayjs from "dayjs";
import useGetRequest from "../../hooks/useGetRequest";
import { AutomationEventsTable } from "./AutomationEventsTable";

export function AutomationEventsHome() {
  const [selectedDate, setSelectedDate] = useState(dayjs());

  const {
    doGet: doGetEvents,
    items: events,
    isComplete: isCompleteEvents,
  } = useGetRequest("automationTriggerEvents");

  useEffect(() => {
    doGetEvents({
      dateA: selectedDate.startOf("day").toISOString(),
      dateB: selectedDate.endOf("day").toISOString(),
    });
  }, [selectedDate]);

  return (
    <main className="flex flex-col">
      <AutomationEventsDateNav
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
      />
      <div className="flex items-center justify-center">

        <AutomationEventsTable events={!isCompleteEvents ? [] : events.data} isLoading={isCompleteEvents}/>
      </div>
    </main>
  );
}
