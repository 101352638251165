import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Eye } from "lucide-react";
import { Button } from "@/components/ui/button";

export const AutomationEventLogsDetailTable = ({ events }) => {
  if (!events || events.length === 0) {
    return <p>No events to display.</p>;
  }

  const columns = Object.keys(events[0]);

  return (
    <div className="flex flex-col">
      <Dialog>
        <DialogTrigger asChild>
          <Button variant="ghost" className="w-20">
            <Eye className="h-4 w-4" />
            <span>{`Ver ${events.length}`}</span>
          </Button>
        </DialogTrigger>
        <DialogContent className=" max-w-4xl">
          <DialogHeader>
            <DialogTitle>View Data</DialogTitle>
          </DialogHeader>

          <Table>
            <TableHeader className="text-[10px]">
              <TableRow>
                {columns.map((column) => (
                  <TableHead key={column}>{column}</TableHead>
                ))}
              </TableRow>
            </TableHeader>
            <TableBody className="text-[10px]">
              {events.map((event, idx) => (
                <TableRow key={idx}>
                  {columns.map((column) => (
                    <TableCell key={`${idx}-${column}`}>
                      {typeof event[column] === "object"
                        ? JSON.stringify(event[column])
                        : String(event[column])}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </div>
  );
};
