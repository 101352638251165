import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";

export default function ContextMenuClick({
  position,
  visible,
  onClose,
  menuData,
  openToDirection,
}) {
  const [childHoverIndex, setChildHoverIndex] = useState(null);
  const [adjustedPosition, setAdjustedPosition] = useState(position);
  const mainPopoverRef = useRef(null);
  const childPopoverRefs = useRef([]);
  const childHoverTimeoutRef = useRef(null);

  useEffect(() => {
    const handleWindowResize = () => {
      adjustMenuPosition();
    };

    window.addEventListener("resize", handleWindowResize);

    // Adjust the menu position initially
    adjustMenuPosition();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [position, visible]);

  const adjustMenuPosition = () => {
    if (mainPopoverRef.current) {
      const menuRect = mainPopoverRef.current.getBoundingClientRect();
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let newTop = position.top;
      let newLeft = position.left;


      // Adjust horizontal position if overflowing
      if (menuRect.right > windowWidth) {
        newLeft = Math.max(windowWidth - menuRect.width, 0);
      } else if (menuRect.left < 0) {
        newLeft = 0;
      }

      // Adjust vertical position if overflowing
      if (menuRect.bottom > windowHeight) {
        newTop = Math.max(windowHeight - menuRect.height, 0);
        // If there's not enough space at the bottom, place it above the trigger
        if (newTop + menuRect.height > windowHeight) {
          newTop = position.top - menuRect.height;
        }
      } else {
        if (menuRect.top < 0) {
          newTop = 0;
        } else {
          if (openToDirection == "up") {
            newTop = newTop - menuRect.height
          }
        }
      }

      setAdjustedPosition({ top: newTop, left: newLeft });
    }
  };

  const handleChildMouseEnter = (index) => {
    if (childHoverTimeoutRef.current)
      clearTimeout(childHoverTimeoutRef.current);
    setChildHoverIndex(index);
  };

  const handleChildMouseLeave = () => {
    childHoverTimeoutRef.current = setTimeout(() => {
      setChildHoverIndex(null);
    }, 200);
  };

  useEffect(() => {
    if (childHoverIndex !== null && childPopoverRefs.current[childHoverIndex]) {
      const childMenu = childPopoverRefs.current[childHoverIndex];
      const childMenuRect = childMenu.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;

      if (childMenuRect.right > windowWidth) {
        childMenu.style.left = `-${childMenuRect.width}px`;
      } else {
        childMenu.style.left = "100%";
      }

      if (childMenuRect.bottom > windowHeight) {
        childMenu.style.top = `${windowHeight - childMenuRect.bottom}px`;
      } else {
        childMenu.style.top = "0";
      }
    }
  }, [childHoverIndex]);

  if (!visible) {
    return null;
  }

  const menu = (
    <div
      ref={mainPopoverRef}
      style={{
        top: adjustedPosition.top,
        left: adjustedPosition.left,
        position: "absolute",
      }}
      className="w-44 divide-y divide-gray-200 rounded-md bg-white shadow-lg z-50"
      onMouseLeave={onClose}
    >
      <div className="p-1 divide-y divide-gray-100">
        {menuData.map((item, index) => (
          <div
            key={index}
            className={`text-xs/6 font-normal ${item.textColor} group relative`}
            onMouseEnter={() => handleChildMouseEnter(index)}
            onMouseLeave={handleChildMouseLeave}
          >
            <div className="flex items-center justify-between w-full text-left py-1 px-2 transition hover:bg-gray-100">
              {item.onClickAction ? (
                <button
                  className="w-full text-left flex"
                  onClick={item.onClickAction}
                >
                  {item.icon != null && (
                    <span>
                      <item.icon className="w-3 h-3 mt-1.5 mr-3" />
                    </span>
                  )}
                  {item.name}
                </button>
              ) : (
                <a href={item.href} className="w-full flex">
                  {item.icon != null && (
                    <span>
                      <item.icon className="w-3 h-3 mt-1.5 mr-3" />
                    </span>
                  )}
                  {item.name}
                </a>
              )}
              {item.children && item.children.length > 0 && (
                <span className="ml-2 text-gray-500">
                  <ChevronRightIcon className="w-4 h-4" />
                </span>
              )}
            </div>
            {item.children &&
              item.children.length > 0 &&
              childHoverIndex === index && (
                <div
                  ref={(el) => (childPopoverRefs.current[index] = el)}
                  className="absolute top-0 mt-2 w-40 divide-y divide-gray-200 rounded-md bg-white shadow-lg"
                  style={{ left: "100%" }}
                >
                  <div className="p-1 divide-y divide-gray-100">
                    {item.children.map((child, childIndex) => (
                      <div key={childIndex}>
                        {child.onClickAction ? (
                          <button
                            className="block w-full text-left rounded-lg py-1 px-2 transition hover:bg-gray-100"
                            onClick={child.onClickAction}
                          >
                            <span className="flex">
                              {child.icon != null && (
                                <span>
                                  <child.icon className="w-3 h-3 mt-1.5 mr-3" />
                                </span>
                              )}
                              <p className={`font-normal ${child.textColor}`}>
                                {child.name}
                              </p>
                            </span>
                          </button>
                        ) : (
                          <a
                            className="block rounded-lg py-1 px-2 transition hover:bg-gray-100"
                            href={child.href}
                          >
                            <span className="flex">
                              {child.icon != null && (
                                <span>
                                  <child.icon className="w-3 h-3 mt-1.5 mr-3" />
                                </span>
                              )}
                              <p className={`font-normal ${child.textColor}`}>
                                {child.name}
                              </p>
                            </span>
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>
    </div>
  );

  return ReactDOM.createPortal(menu, document.body);
}
