// src/App.jsx
import React from "react";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import LoadAuthContext from "./auth/LoadAuthContext";
import InboxHome from "./messaging/inbox/InboxHome";
import CustomerPortalHome from "./customerportal/CustomerPortalHome";
import AnalyticsHome from "./analytics/AnalyticsHome";
import SettingsMain from "./settings/SettingsMain";
import ProfileEdit from "./settings/ProfileEdit";
import AutomationHome from "./automation/AutomationHome";

function App() {
  return (
    <LoadAuthContext
      content={
        <>
          <Routes>
            <Route path="/" element={<>home</>} />
            <Route path="/inbox" element={<InboxHome />} />
            <Route path="/customerportal" element={<CustomerPortalHome/>} />
            <Route path="/analytics" element={<AnalyticsHome/>} />
            <Route path="/automation" element={<AutomationHome/>} />
            <Route path="/preferences" element={<SettingsMain/>} />
            <Route path="/profile" element={<ProfileEdit/>} />
          </Routes>
        </>
      }
    />
  );
}

export default App;
