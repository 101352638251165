import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { blankResult, postJson, reqOk } from "../../common/fetchdata";
import { useContext, useEffect, useState } from "react";
import AvatarXs from "../../common/card/AvatarXs";
import { DocumentIcon } from "@heroicons/react/24/outline";

export default function RelatedContactList({
  data,
  handleAssignment,
}) {
  const [contacts, setContacts] = useState([]);

  useEffect(() => {
    if (reqOk(data)) {
      const _contacts = data.items.phone_lookup.flatMap((entry) =>
        entry.items.map((item) => ({
          _type: entry.type,
          ...item,
        }))
      );
      setContacts(_contacts);
    }
  }, [data]);

  return (
    <>
      <div className="flex flex-col">
        <div className="">
        </div>
        <div className="pt-6">
          {reqOk(data) ? (
            <>
              {
                contacts.length == 0 ? (
                  <>
                    <div className="text-center">
                      <h3 className="text-sm font-normal text-gray-900">
                        Não existem contactos a ser relacionados
                      </h3>
                    </div>
                  </>
                ) : (
                  <ul
                    role="list"
                    className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200"
                  >
                    {contacts.map((person, idx) => (
                      <li
                        key={idx}
                        className="flex items-center justify-between py-3"
                      >
                        <div className="flex items-center">
                          <div className="w-12">
                            <AvatarXs person={person} />
                            <div className="text-[10px] pt-2 truncate">
                              Paciente
                            </div>
                          </div>
                          <span className="ml-4 text-sm font-medium text-gray-900">
                            <p className="text-xs font-semibold leading-6 text-gray-900">
                              <span className="absolute inset-x-0 -top-px bottom-0" />
                              {person.fullname}
                            </p>
                            <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                              <span className="pr-2 text-gray-700">NIF</span>
                              {person.tax_id_ref}
                            </p>
                            {person.dateofbirth != null && (
                              <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                                <span className="pr-2 text-gray-700">
                                  Data Nascimento
                                </span>
                                {person.dateofbirth}
                              </p>
                            )}
                          </span>
                        </div>
                        <button
                          onClick={() =>
                            handleAssignment(person._type, person.id)
                          }
                          type="button"
                          className="ml-6 rounded-md bg-white text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        >
                          Remove<span className="sr-only"> {person.name}</span>
                        </button>
                      </li>
                    ))}
                  </ul>
                )

                /* 
                                        <ul
                                            role="list"
                                            className="divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl"
                                        >
                                            {contacts.map((person, idx) => (
                                                <li key={idx}>
                                                    <a href="#" className="relative flex justify-between gap-x-6 px-4 py-3 hover:bg-gray-50" onClick={() => handleAssignment(person._type, person.id)}>
                                                        <div className="flex min-w-0 gap-x-4">
                                                            <div className="w-12">
                                                                <AvatarXs person={person} />
                                                                <div className="text-[10px] pt-2 truncate">
                                                                    Paciente
                                                                </div>
                                                            </div>
                                                            <div className="min-w-0 flex-auto">
                                                                <p className="text-xs font-semibold leading-6 text-gray-900">
                                                                    <span className="absolute inset-x-0 -top-px bottom-0" />
                                                                    {person.fullname}
                                                                </p>
                                                                <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                                                                    <span className="pr-2 text-gray-700">NIF</span>{person.tax_id_ref}
                                                                </p>
                                                                {
                                                                    person.dateofbirth != null && <p className="mt-1 flex text-xs text-gray-500 truncate hover:underline">
                                                                        <span className="pr-2 text-gray-700">Data Nascimento</span>{person.dateofbirth}
                                                                    </p>
                                                                }

                                                            </div>
                                                        </div>
                                                        <div className="flex shrink-0 items-center gap-x-4">

                                                            <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                                        </div>
                                                    </a>
                                                </li>
                                            ))}
                                        </ul>
 */
              }
            </>
          ) : (
            <>A carregar...</>
          )}
        </div>
      </div>
    </>
  );
}
