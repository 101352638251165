import { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import InboxSelectSidebar from "./sidebar/InboxSelectSidebar";
import InboxTopicsSidebar from "./sidebar/InboxTopicsSidebar";
import useGetRequest from "../../hooks/useGetRequest";
import {
  loadMoreTopics,
  parseMessagingUrl,
  loadTopic,
  handleSelectTopic,
} from "./dataFetch";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import InboxChatContentPanel from "./chatcontent/InboxChatContentPanel";
import InboxChatDetailsSidebar from "./sidebar/InboxChatDetailsSidebar";
import { InboxSidebarTopicListHeader } from "./sidebar/InboxSidebarTopicListHeader";
import { GlobalStateContext } from "../../state/globalState";
import { useSocket } from "../../socket/SocketProvider";

export default function InboxHome() {
  const { setSocketRefreshTopics } = useSocket();
  const [selectBatch, setSelectBatch] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicsRead, setTopicRead] = useState([]);
  const [topic, setTopic] = useState({});
  const [showContactInfo, setShowContactInfo] = useState(false);

  const _gc = useContext(GlobalStateContext);
  const user = _gc.contextFcn.userData.user;

  let navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { search } = location;

  const {
    doGet: doGetMessagingTopics,
    getR: getRMessagingTopics,
    isComplete: isCompleteMessagingTopics,
    items: itemsMessagingTopics,
  } = useGetRequest("messaging");

  const {
    doGet: doGetMessagingThread,
    getR: getRMessagingThread,
    isComplete: isCompleteMessagingThread,
    items: itemsMessagingThread,
  } = useGetRequest("messagingthread");

  const {
    doGet: doGetInboxCategories,
    isComplete: isCompleteInboxCategories,
    items: itemsInboxCategories,
  } = useGetRequest("inboxcategories");

  useEffect(() => {
    doGetInboxCategories();
  }, []);

  // On URL change
  useEffect(() => {
    // capture page load

    parseMessagingUrl(
      searchParams,
      navigate,
      doGetMessagingTopics,
      doGetMessagingThread,
      isCompleteMessagingTopics,
      isCompleteMessagingThread,
      getRMessagingTopics,
      getRMessagingThread
    );
  }, [search]);

  // Update a specific topic
  // eg when changing from the context menu and we want to refresh the topic list
  // if this is the currently selected thread update as well

  async function refreshTopics(results) {
    setTopic((t) => {
      const updatedIds = results.result_thread.map((r) => r.id);
      if (updatedIds.indexOf(t.id) > -1)
        return results.result_thread.filter((t) => t.id == t.id)[0];
      return t;
    });
    // result is {}

    setTopics((prevTopics) => {
      // Create a copy of the previous topics
      const updatedTopics = [...prevTopics];

      // Iterate over the changed topics
      results.result_topics.forEach((resObjTopic) => {
        // Find the index of the topic to update
        const index = updatedTopics.findIndex(
          (topic) => topic.id === resObjTopic.id
        );

        // If the topic is found, update it
        if (index !== -1) {
          updatedTopics[index] = resObjTopic;
        }
      });

      // Return the updated topics
      return updatedTopics;
    });
  }

  useEffect(() => {
    setSocketRefreshTopics(() => refreshTopics);
  }, []);

  // On URL change
  // Load Topics
  useEffect(() => {
    if (isCompleteMessagingTopics) {
      if (itemsMessagingTopics.page == 1) setTopics(itemsMessagingTopics.data);
      else setTopics((t) => [...t, ...itemsMessagingTopics.data]);
    }
  }, [itemsMessagingTopics]);

  // Load Thread
  useEffect(() => {
    if (isCompleteMessagingThread) {
      loadTopic(itemsMessagingThread, setTopic, topicsRead, setTopicRead);
    }
  }, [isCompleteMessagingThread]);

  // Load inital topics read
  useEffect(() => {
    if (user && isCompleteMessagingTopics) {
      const readTopics = topics
        .filter((t) => t.is_read_by.indexOf(user.id) != -1)
        .map((t) => t.id);
      setTopicRead(readTopics);
    }
  }, [topics, user]);

  function handleLoadMoreTopics() {
    loadMoreTopics(searchParams, itemsMessagingTopics, doGetMessagingTopics);
  }

  function hasMoreTopicsToLoad() {
    if (!isCompleteMessagingTopics) return false;
    else {
      if (itemsMessagingTopics.nextPage > itemsMessagingTopics.maxPage)
        return false;
      else return true;
    }
  }

  function handleSelectTopicThread(ev) {
    handleSelectTopic(searchParams, navigate, ev);
  }

  function pageRefresh() {
    console.log("pageRefresh");
  }

  return (
    <>
      <ResizablePanelGroup direction="horizontal" className="flex h-full">
        <ResizablePanel
          defaultSize={20}
          className="p-4 border-r h-full items-center justify-center bg-white hidden md:block "
        >
          <InboxSelectSidebar
            isCompleteInboxCategories={isCompleteInboxCategories}
            itemsInboxCategories={itemsInboxCategories}
            doGetInboxCategories={doGetInboxCategories}
          />
        </ResizablePanel>
        <ResizableHandle />

        <ResizablePanel
          defaultSize={30}
          className="border-r h-full items-center justify-center bg-white hidden md:block "
        >
          <div className="flex min-h-[40px] relative border-b">
            <InboxSidebarTopicListHeader
              getRMessagingTopics={getRMessagingTopics}
              itemsInboxCategories={itemsInboxCategories}
            />
          </div>
          <div className="flex-1 overflow-auto">
            <InboxTopicsSidebar
              isCompleteInboxCategories={isCompleteInboxCategories}
              itemsInboxCategories={itemsInboxCategories}
              selectBatch={selectBatch}
              setSelectBatch={setSelectBatch}
              topicsRead={topicsRead}
              topics={topics}
              selectedTopic={topic}
              handleLoadMoreTopics={handleLoadMoreTopics}
              hasMoreTopicsToLoad={hasMoreTopicsToLoad}
              handleSelectTopic={handleSelectTopicThread}
              isColdLoading={
                getRMessagingTopics.state == 1 &&
                getRMessagingTopics.params.page == 1
              }
            />
          </div>
        </ResizablePanel>
        <ResizableHandle />

        <ResizablePanel
          defaultSize={40}
          className="border-r h-full items-center justify-center bg-white hidden md:block "
        >
          <InboxChatContentPanel
            selectedTopic={topic}
            selectBatch={selectBatch}
            topicLoadingState={getRMessagingThread.state}
            setSelectBatch={setSelectBatch}
            showContactInfo={showContactInfo}
            setShowContactInfo={setShowContactInfo}
            itemsInboxCategories={itemsInboxCategories}
          />
        </ResizablePanel>

        <ResizableHandle />
        <ResizablePanel defaultSize={10}>
          {typeof topic != "undefined" && Object.keys(topic).length > 0 && (
            <InboxChatDetailsSidebar
              selectedTopic={topic}
              setShowContactInfo={setShowContactInfo}
            />
          )}
        </ResizablePanel>
      </ResizablePanelGroup>

      {/* <ResizablePanelGroup direction="horizontal" className="flex h-full">
        <div className="lg:w-1/6 min-w-[80px] bg-white hidden md:block p-4 border-r h-full overflow-auto">
          <InboxSelectSidebar
            isCompleteInboxCategories={isCompleteInboxCategories}
            itemsInboxCategories={itemsInboxCategories}
          />
        </div>
        <div className="lg:w-3/12 min-w-[100px] bg-white hidden sm:block h-full border-r">
          <div className="flex min-h-[40px] relative border-b">
            <InboxSidebarTopicListHeader
              getRMessagingTopics={getRMessagingTopics}
              itemsInboxCategories={itemsInboxCategories}
            />
          </div>
          <div className="flex-1 overflow-auto">
            <InboxTopicsSidebar
              isCompleteInboxCategories={isCompleteInboxCategories}
              itemsInboxCategories={itemsInboxCategories}
              selectBatch={selectBatch}
              setSelectBatch={setSelectBatch}
              topicsRead={topicsRead}
              topics={topics}
              selectedTopic={topic}
              handleLoadMoreTopics={handleLoadMoreTopics}
              hasMoreTopicsToLoad={hasMoreTopicsToLoad}
              handleSelectTopic={handleSelectTopicThread}
              isColdLoading={
                getRMessagingTopics.state == 1 &&
                getRMessagingTopics.params.page == 1
              }
            />
          </div>
        </div>
        <div
          className={
            `bg-white h-full overflow-auto ` +
            (showContactInfo
              ? "lg:w-4/12 min-w-[300px]"
              : "lg:w-7/12 min-w-[500px]")
          }
        >
          <InboxChatContentPanel
            selectedTopic={topic}
            selectBatch={selectBatch}
            topicLoadingState={getRMessagingThread.state}
            setSelectBatch={setSelectBatch}
            showContactInfo={showContactInfo}
            setShowContactInfo={setShowContactInfo}
            itemsInboxCategories={itemsInboxCategories}
          />
        </div>
        <div
          className={
            `bg-white h-full overflow-auto ` +
            (showContactInfo ? "lg:w-3/12 min-w-[150px]" : "hidden")
          }
        >
          {typeof topic != "undefined" && Object.keys(topic).length > 0 && (
            <InboxChatDetailsSidebar
              selectedTopic={topic}
              setShowContactInfo={setShowContactInfo}
            />
          )}
        </div>
      </ResizablePanelGroup> */}
    </>
  );
}
