import React, { useRef, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

export function OnboardNewUser({ userData }) {
  const openModalRef = useRef(null);

  useEffect(() => {
    if (openModalRef?.current && userData.is_new_user)
      openModalRef.current.click();
  }, []);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span ref={openModalRef} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Bem-vindo</DialogTitle>
          <DialogDescription>{JSON.stringify(userData)}</DialogDescription>
        </DialogHeader>

        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
