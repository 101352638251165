import React from "react";
import { Fragment, useState } from "react";
import { MedziLogo } from "../common/icon/MedziLogo";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { GlobalStateContext } from "../state/globalState";
import { reqOk } from "../common/fetchdata";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BriefcaseIcon,
  CalendarIcon,
  ChartPieIcon,
  Cog6ToothIcon,
  FolderIcon,
  HomeIcon,
  CommandLineIcon,
  UsersIcon,
  XMarkIcon,
  EnvelopeIcon,
} from "@heroicons/react/24/outline";

import NavHeader from "./header/NavHeader";

const navigation = [
  /*     { rac: true, value: 'home_schedule', name: 'Início', href: '/home', icon: HomeIcon },
    { rac: true, value: 'customer', name: 'Pacientes', href: '/customer', icon: UsersIcon },
    { rac: true, value: 'provider', name: 'Médicos', href: '/provider', icon: BriefcaseIcon },
    { rac: true, value: 'appointment', name: 'Consultas', href: '/call', icon: CalendarIcon },
    { rac: true, value: 'filemanager', name: 'Ficheiros', href: '/filemanager', icon: FolderIcon },, */
  {
    rac: true,
    value: "inbox",
    name: "Pedidos",
    href: "/inbox",
    icon: EnvelopeIcon,
  },
  {
    rac: true,
    value: "customerportal",
    name: "Clientes",
    href: "/customerportal",
    icon: UsersIcon,
  },
  {
    rac: true,
    value: "analytics",
    name: "Relatórios",
    href: "/analytics",
    icon: ChartPieIcon,
  },
  {
    rac: true,
    value: "automation",
    name: "Automação",
    href: "/automation",
    icon: CommandLineIcon,
  },
  {
    rac: true,
    value: "settings",
    name: "Opções",
    href: "/preferences",
    icon: Cog6ToothIcon,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function HomeLayout(props) {
  const { pathname } = useLocation();
  const globalcontext = React.useContext(GlobalStateContext);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userData, setUserData] = React.useState(false);

  React.useEffect(() => {
    if (globalcontext.isStateLoaded) setUserData(globalcontext.userData);
  }, [globalcontext.isStateLoaded]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-white">
        <body className="h-full">
        ```
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>

                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <MedziLogo height={38} />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="-mx-2 flex-1 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.href == pathname
                                  ? "bg-gray-800 text-white"
                                  : "text-gray-400 hover:text-white hover:bg-gray-800",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className="h-6 w-6 shrink-0"
                                aria-hidden="true"
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:left-0 lg:z-50 lg:block lg:w-20 lg:overflow-y-auto lg:bg-gray-900 lg:pb-4">
          <div className="flex h-16 shrink-0 items-center justify-center">
            <MedziLogo height={38} />
          </div>

          <nav className="mt-8">
            <ul role="list" className="flex flex-col items-center">
              {navigation.map((item) => (
                <Fragment key={item.name}>
                  {/*  {(!item.rac || globalcontext.contextFcn.userData.canReadResource(item.value)) &&  */}

                  <li className="w-full">
                    <Link
                      to={item.href}
                      className={classNames(
                        item.href == pathname
                          ? "bg-gray-800 text-white"
                          : "text-gray-400 hover:text-white hover:bg-gray-800",
                        "group flex gap-x-3 text-[10px] leading-6 font-semibold flex-col items-center w-full py-3"
                      )}
                    >
                      <item.icon
                        className="h-6 w-6 shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">{item.name}</span>
                      {item.name}
                      {/*  {JSON.stringify(globalcontext.contextFcn.userData.canReadResource(item.value))} */}
                    </Link>
                  </li>
                  {/* } */}
                </Fragment>
              ))}
            </ul>
          </nav>
        </div>

        <div className="lg:pl-20">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div
              className="h-6 w-px bg-gray-900/10 lg:hidden"
              aria-hidden="true"
            />

            <NavHeader userData={userData} />
          </div>

          {/*     <div className="h-full w-full flex  flex-col items-start justify-start overflow-y-auto">
                        {props.showbreadCrumbs && (
                            <div className="h-full w-full mx-auto">
                                <Breadcrumbs />
                            </div>
                        )}

 */}
          <div
            className={`bg-white h-[calc(100vh-4rem)] min-h-max w-full mx-auto`}
          >
            {globalcontext.isStateLoaded ? <>{props.content}</> : <>Loading content ...</>}
          </div>
        </div>
      </div>
    </>
  );
}
