import React, { useContext, useState, useRef, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { GlobalStateContext } from "../../../state/globalState";
import InfiniteScroll from "react-infinite-scroll-component";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { UserIcon, UserGroupIcon } from "@heroicons/react/24/outline";
import MessagingActionContextMenu from "../../actions/MessagingActionContextMenu";
moment.locale("pt");

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const style = {
  height: 50,
  border: "1px solid green",
  margin: 6,
  padding: 8,
};

function LoadingTopics() {
  return (
    <div className="p-2">
      <div
        role="status"
        className="w-full p-4 space-y-4 divide-y divide-gray-200 animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
}

/* 
      isCompleteInboxCategories={isCompleteInboxCategories}
            itemsInboxCategories={itemsInboxCategories}
             */

export default function InboxTopicsSidebar({
  topics,
  selectBatch,
  setSelectBatch,
  topicsRead,
  selectedTopic,
  handleLoadMoreTopics,
  hasMoreTopicsToLoad,
  handleSelectTopic,
  isColdLoading,
  isCompleteInboxCategories,
  itemsInboxCategories,
  /* selectedTopic,
  handleSelectTopic,
  applyPagination,
  handleRefresh,
  topicsRead, */
}) {
  const [height, setHeight] = useState(window.innerHeight);
  const [selectedItem, setSelectedItem] = useState({});
  const containerRef = useRef(null);

  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const handleContextMenu = (event, thread) => {
    event.preventDefault();
    setContextMenuPosition({ top: event.clientY, left: event.clientX });
    setContextMenuVisible(true);
    setSelectedItem(thread);
  };

  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
  };

  const updateHeight = () => {
    if (containerRef.current) {
      setHeight(window.innerHeight);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", updateHeight);
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleChange = (checked, id) => {
    if (checked) setSelectBatch([...selectBatch, id]);
    else setSelectBatch(selectBatch.filter((s) => s != id));
  };

  return (
    <>
      <MessagingActionContextMenu
        contextMenuPosition={contextMenuPosition}
        contextMenuVisible={contextMenuVisible}
        handleCloseContextMenu={handleCloseContextMenu}
        selectedItem={selectedItem}
        isCompleteInboxCategories={isCompleteInboxCategories}
        itemsInboxCategories={itemsInboxCategories}
      />
      {!isColdLoading ? (
        <nav aria-label="Message list" className="min-h-0 flex-1 ">
          {/* ref={containerRef} style={{ height: `${height}px`, transition: 'height 0.5s ease' }} */}
          <div
            ref={containerRef}
            id="scrollableDiv"
            style={{
              height: height - 120,
              overflow: "auto",
              transition: "height 0.5s ease",
            }}
          >
            <InfiniteScroll
              dataLength={topics.length}
              next={handleLoadMoreTopics}
              hasMore={hasMoreTopicsToLoad()}
              loader={<h4>Loading...</h4>}
              scrollableTarget="scrollableDiv"
            >
              {topics.map((thread, index) => (
                <a
                  href="#"
                  key={index}
                  onClick={() => handleSelectTopic(thread.id)}
                >
                  <div
                    onContextMenu={(ev) => handleContextMenu(ev, thread)}
                    className={
                      `flex w-full border-b border-gray-50 relative focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 ` +
                      `${
                        typeof selectedTopic != "undefined" &&
                        (selectedTopic.id == thread.id
                          ? "bg-indigo-100 hover:bg-gray-300"
                          : topicsRead.indexOf(thread.id) == -1
                          ? "bg-gray-100 hover:bg-gray-300"
                          : "bg-white hover:bg-gray-300")
                      }`
                    }
                  >
                    <div className="flex w-full focus:outline-none">
                      <div
                        className={`w-1  
                        ${
                          thread.current_status == "CLOSED"
                            ? "bg-emerald-500"
                            : thread.current_status == "PENDING"
                            ? "bg-yellow-500"
                            : thread.current_status == "OPEN"
                            ? "bg-red-500"
                            : ""
                        }`}
                      ></div>

                      <div className="flex w-80 pl-2 h-12">
                        <div className="w-8">
                          <div className="py-2 pl-2">
                            <input
                              checked={selectBatch.indexOf(thread.id) != -1}
                              onChange={(e) =>
                                handleChange(e.target.checked, thread.id)
                              }
                              id="comments"
                              aria-describedby="comments-description"
                              name="comments"
                              type="checkbox"
                              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                            />
                          </div>
                        </div>

                        <div className="w-40 px-2 py-2 flex truncate">
                          <div className=" inline-flex flex-col rounded-md px-1 py-0.5 text-sm font-light text-gray-600">
                            {thread.customer_fullname != null &&
                            thread.customer_fullname.trim() != ""
                              ? thread.customer_fullname
                              : "customers_web" in thread &&
                                thread.customers_web.phonenum_e164 != null
                              ? typeof parsePhoneNumber(
                                  thread.customers_web.phonenum_e164,
                                  null
                                ) != "undefined"
                                ? parsePhoneNumber(
                                    thread.customers_web.phonenum_e164,
                                    null
                                  ).nationalNumber
                                : thread.customers_web.phonenum_e164
                              : "N/D"}
                          </div>
                        </div>

                        <div className="w-20 px-2 py-1.5 flex flex-col text-[9px] space-y-2 truncate">
                          {thread.central_operator_groups != null && (
                            <span className="text-sky-600 font-medium">
                              {thread.central_operator_groups != null &&
                                thread.central_operator_groups.name}
                            </span>
                          )}

                          {thread.assigned_user != null && (
                            <span className="">
                              {thread.assigned_user != null &&
                                thread.assigned_user.fullname}
                            </span>
                          )}
                        </div>

                        <div className="w-12 px-2 py-1 flex text-xs">
                          <div className="w-full"></div>
                          <div className="m-auto flex flex-col text-[9px]">
                            <time dateTime={thread.last_update}>
                              {moment(thread.last_update).format("HH:mm")}
                            </time>
                            <time dateTime={thread.last_update}>
                              {moment(thread.last_update).isBefore(
                                moment().startOf("day")
                              ) && (
                                <span>
                                  {moment(thread.last_update).format("DD/MM")}
                                </span>
                              )}
                            </time>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </InfiniteScroll>
          </div>
        </nav>
      ) : (
        <LoadingTopics />
      )}
    </>
  );
}
