import React from "react";
import useGetRequest from "../../hooks/useGetRequest";
import usePostRequest from "../../hooks/usePostRequest";
import { GlobalStateContext } from "../../state/globalState";
import { useContext, useEffect } from "react";

export default function CustomerPortalSync() {
  const globalcontext = useContext(GlobalStateContext);
  const {
    doGet: doGetSync,
    getR: getRSync,
    isComplete: isCompleteSync,
    items: itemsSync,
  } = useGetRequest("customerPortalSync");

  const { doPost: doPostSync } = usePostRequest("customerPortalSync");

  useEffect(() => {
    doGetSync();
  }, []);

  function handleUpdateData(modelUpdate) {
    globalcontext.contextFcn.panel.modal.open(
      "info",
      `Confirma a atualização do modelo de dados?`,
      () => doPostSync({ model: modelUpdate })
    );
  }


  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Users
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the users in your account including their name,
            status, lastUpdate and doSync.
          </p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      status
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      lastUpdate
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Serviços
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {`${
                        isCompleteSync
                          ? itemsSync.data.servicesSync
                            ? "Sim"
                            : "Não"
                          : ""
                      }`}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {`${
                        isCompleteSync
                          ? itemsSync.data.servicesSyncDate == null
                            ? "Nunca"
                            : itemsSync.data.servicesSyncDate
                          : ""
                      }`}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a
                        href="#"
                        onClick={() => handleUpdateData('services')}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Sincronizar
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                      Médicos
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {`${
                        isCompleteSync
                          ? itemsSync.data.providerSync
                            ? "Sim"
                            : "Não"
                          : ""
                      }`}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                      {`${
                        isCompleteSync
                          ? itemsSync.data.providerSyncDate == null
                            ? "Nunca"
                            : itemsSync.data.providerSyncDate
                          : ""
                      }`}
                    </td>
                    <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                      <a
                        href="#"
                        onClick={() => handleUpdateData('providers')}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Sincronizar
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
