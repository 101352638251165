import React from 'react';

const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome da Especialidade...',
    id: 'servicetype__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  }, {
    selector: 'stackedit',
    required: true,
    name: 'servsubtype',
    title: 'Tipos de Consulta',
    id: 'servicetype__servsubtype',
    map: true,
    stack: {
      title: 'name',
      subtitle: 'name',
      listItemView: (e) => {
        return (
          <>
            <span className="truncate font-medium">{e.name}</span>
          </>
        );
      },
      fields: [
        {
          selector: 'input',
          required: true,
          class: '',
          type: 'text',
          name: 'name',
          title: 'Tipo de Consulta',
          placeholder: 'Nome...',
          id: 'servsubtype__name',
          map: true,
          validation: (f) => f.length > 0,
          validationerr: 'Nome Inválido.',
        }
      ],
    },
  },
  {
    selector: 'stackedit',
    required: true,
    name: 'providers',
    title: 'Médicos',
    id: 'servicetype__providers',
    map: true,
    stack: {
      title: 'provider',
      subtitle: 'name',
      listItemView: (e) => {
        return (
          <>
            <span className="truncate font-medium">{e.providers.name}</span>
            <span className="flex-shrink-0 text-gray-400"></span>
          </>
        );
      },
      fields: [
        {
          selector: 'selectprovider',
          required: true,
          name: 'providers',
          title: 'Médico',
          placeholder: 'Nome...',
          id: 'providers',
        },

      ],
    },
  },
];


const rows_edit = [
  ...rows.filter(r => r.name == 'name'),
  {
    ...rows.filter(r => r.name == 'providers')[0], stack: {
      title: 'name',
      subtitle: 'name',
      listItemView: (e) => {

        let item = e
        // adge case for when we have a mix of new and old items
        if('providers' in item) {
          item = {...item.providers}
        }
        return (
          <>
            <span className="truncate font-medium">{item.name}</span>
            <span className="flex-shrink-0 text-gray-400"></span>
          </>
        );
      },
      fields: rows.filter(r => r.name == 'providers')[0].stack.fields,
    }
  }


];


export const formfield_newservicetype = {
  title: 'Nova Especialidade',
  description: 'Adicione uma nova Especialidade',
  apiEndpoint: '/api/servicetype',
  method: 'POST',
  preloadForm: false,
  name: '',
  id: '',
  submitLabel: 'Adicionar',
  rows: rows,
};

export const formfield_editservicetype = {
  title: 'Editar Espaço',
  description: 'Edite uma especialidade existente',
  apiEndpoint: '/api/servicetype',
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows_edit,
};
