import React from "react";
import { Fragment } from "react";
import { Transition } from "@headlessui/react";
import { InboxIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/20/solid";

import toast from "react-hot-toast";
import "./toasts.css";

export function successToast(message, style = null) {
  toast.success(message, {
    className: "border-l-4 border-green-700",
  });
}
export function errorToast(message) {
  toast.error(message, {
    className: "",
  });
}
export function infoToast(message) {
  toast.success(message, {
    className: "",
  });
}

export function NewMessageToast(msg) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 pad-1">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt025">
              <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                alt=""
              />
            </div>
            <div className="ml-3 flex-1">
              <p className="nomargin text-sm font-medium text-gray-900">
                {msg.from}
              </p>
              <p className="nomargin mt-1 text-sm text-gray-500">
                {msg.content}
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <div
            onClick={() => toast.dismiss(t.id)}
            className="toastclosebtn w-full border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            Fechar
          </div>
        </div>
      </div>
    ),
    {
      duration: 5000,
      position: "bottom-right",
    }
  );
}

export function newMessageToastWithAction(msg, action, actionLabel) {
  toast.custom(
    (t) => (
      <div
        className={`${
          t.visible ? "animate-enter" : "animate-leave"
        } p-6 max-w-md w-full bg-white shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
      >
        <div className="flex-1 w-0 pad-1">
          <div className="flex items-start">
            <div className="flex-shrink-0 pt025">
             {/*  <img
                className="h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixqx=6GHAjsWpt9&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.2&w=160&h=160&q=80"
                alt=""
              /> */}
            </div>
            <div className="ml-3 flex-1">
              <p className="nomargin text-sm font-medium text-gray-900">
                {msg.from}
              </p>
              <p className="nomargin mt-1 text-sm text-gray-500">
                {msg.content}
              </p>
            </div>
          </div>
        </div>
        <div className="flex border-l border-gray-200">
          <div
            onClick={() => {
              action();
              toast.dismiss(t.id);
            }}
            className="toastclosebtn w-full border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
          >
            {actionLabel}
          </div>
        </div>
      </div>
    ),
    {
      duration: 2000,
      position: "top-right",
    }
  );
}
/* { photo ? (
    
    :
    
  )} */
