import React, { useEffect, useContext } from "react";
import ContextMenuClick from "../../common/menu/ContextMenuClick";
import useGetRequest from "../../hooks/useGetRequest";
import usePutRequest from "../../hooks/usePutRequest";
import { contextMenu } from "../data/messagingContextMenu";
import { GlobalStateContext } from "../../state/globalState";
import { refreshContext } from "../data/messagingSelect";
import { useNavigate, useSearchParams } from "react-router-dom";

/* 

        isCompleteInboxCategories={isCompleteInboxCategories}
        itemsInboxCategories={itemsInboxCategories}
*/

export default function MessagingActionContextMenu({
  contextMenuPosition,
  contextMenuVisible,
  handleCloseContextMenu,
  selectedItem,
  isCompleteInboxCategories,
  itemsInboxCategories
}) {
  const globalcontext = useContext(GlobalStateContext);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const { doGet: doGetAssignableAgents, items: itemsAssignableAgents } =
    useGetRequest("assignableagents");

  const {
    doPut: doPutThreadActions,
  } = usePutRequest("messagingthread");

  useEffect(() => {
    doGetAssignableAgents();
  }, []);


  return (
    <ContextMenuClick
      position={contextMenuPosition}
      visible={contextMenuVisible}
      onClose={handleCloseContextMenu}
      menuData={contextMenu(
        globalcontext,
        selectedItem,
        itemsAssignableAgents,
        doPutThreadActions
      )}
    />
  );
}
