export function AutomationEventLogsResult({ events }) {
  return (
    <div className="flow-root">
      {events
        .filter((e) => e.action_type == "sms_received")
        .map((ev, idx) => (
          <div key={idx}>
            {ev.result.api_response.requestType ==
              "APPOINTMENT_CHANGE_STATUS" && "Status changed"}
          </div>
        ))}
    </div>
  );
}
