import React from 'react';
import { useState } from 'react';
import {
  BuildingStorefrontIcon,
  BuildingOfficeIcon,
} from '@heroicons/react/24/outline';
import CentersPage from './CentersPage';
import SpacesPage from './SpacesPage';
import SpaceFeaturesPage from './SpaceFeaturesPage';
import OrgConfig from './OrgConfig';
import useToken from '../../hooks/useToken';


export default function ConfigSpacesHome() {
  const { token } = useToken();
  const [page, setPage] = useState('org');

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
  }

  const WindowIcon = ({ className }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M12 3c-3.866 0 -7 3.272 -7 7v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1 -1v-10c0 -3.728 -3.134 -7 -7 -7z"></path>
        <path d="M5 13l14 0"></path>
        <path d="M12 3l0 18"></path>
      </svg>
    );
  };

  const ChairIcon = ({ className }) => {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        viewBox="0 0 24 24"
        strokeWidth={2}
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <path d="M5 11a2 2 0 0 1 2 2v2h10v-2a2 2 0 1 1 4 0v4a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-4a2 2 0 0 1 2 -2z"></path>
        <path d="M5 11v-5a3 3 0 0 1 3 -3h8a3 3 0 0 1 3 3v5"></path>
        <path d="M6 19v2"></path>
        <path d="M18 19v2"></path>
      </svg>
    );
  };

  const secondaryNavigation = [

    {
      name: 'Organização',
      icon: BuildingOfficeIcon,
      current: false,
      key: 'org',
    },
    {
      name: 'Centros',
      icon: BuildingStorefrontIcon,
      current: false,
      key: 'center',
    },
    {
      name: 'Espaços',
      icon: WindowIcon,
      current: false,
      key: 'space',
    },
    {
      name: 'Equipamentos',
      icon: ChairIcon,
      current: false,
      key: 'spacefeatures',
    },
    
  ];

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-4 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-48 lg:flex-none lg:border-0 lg:py-4">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => setPage(item.key)}
                    className={classNames(
                      page == item.key
                        ? 'bg-gray-50 text-indigo-600'
                        : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-50',
                      'group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        page == item.key
                          ? 'text-indigo-600'
                          : 'text-gray-400 group-hover:text-indigo-600',
                        'h-6 w-6 shrink-0'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="lg:flex-auto ">
              {page == 'center' && <CentersPage />}
              {page == 'space' && <SpacesPage />}
              {page == 'spacefeatures' && <SpaceFeaturesPage />}
         {/*      {page == 'org' && <OrgConfig />} */}

              
        
        </main>
      </div>
    </>
  );
}
