import { useState, useContext, useEffect } from 'react';
import { getJson, blankResult, reqOk, loadingScreen } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';
import { useAuth } from "./useAuth";


function useGetRequest( action ) {

  let auth = useAuth();
  const [getR, setGetR] = useState(blankResult);
  const _gc = useContext(GlobalStateContext);

  /*   useEffect(() => {
      loadingScreen(getR, _gc)
  }, [getR]); */

  function handleRequest( params ) {
    getJson(
      apiUrl[action],
      { ...getR,  params: params, token: auth.token },
      setGetR
    );
  };



  return {
    doGet: handleRequest,
    getR,
    setGetR,
    isComplete: reqOk(getR),
    items: reqOk(getR) ? getR.items : null
  }

}

export default useGetRequest;