import React, { useContext, useEffect } from "react";
import {
  Cog6ToothIcon,
  InformationCircleIcon,
  UserIcon,
  UserGroupIcon,
} from "@heroicons/react/24/outline";
import MessagingActionsStatusBtn from "./actions/MessagingActionsStatusBtn";
import ContextMenuLeftClickButton from "../common/menu/ContextMenuLeftClickButton";
import { GlobalStateContext } from "../state/globalState";
import {
  contextAssignGroup,
  contextAssignOperator,
  contextMenu,
} from "./data/messagingContextMenu";
import { msgStatus } from "./data/messagingStatus";
import useGetRequest from "../hooks/useGetRequest";
import usePutRequest from "../hooks/usePutRequest";

export default function MessagingActions({
  selectedTopic,
  selectBatch,
  showContactInfo,
  setShowContactInfo,
  itemsInboxCategories
}) {
  const globalcontext = useContext(GlobalStateContext);

  function anyActiveTopic() {
    return !(
      typeof selectedTopic != "undefined" &&
      Object.keys(selectedTopic).length === 0
    );
  }

  const {
    doPut: doPutThreadActions,
    putR: putReqThreadActions,
    isComplete: isCompleteThreadActions,
  } = usePutRequest("messagingthread");

  return (
    <>
      {anyActiveTopic() && selectBatch.length == 0 && (
        <>
          {/* Top section */}
          <div className="flex-shrink-0 border-b border-gray-200 bg-white">
            {/* Toolbar*/}
            <div className="flex h-16 flex-col justify-center">
              <div className="px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between py-3">
                  {/* Left buttons */}
                  <div>
                    <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                      <span className="inline-flex sm:shadow-sm">
                        <button
                          type="button"
                          onClick={() => setShowContactInfo(!showContactInfo)}
                          className={
                            `rounded-l-md relative -ml-px hidden items-center gap-x-1.5 px-3 py-2 ` +
                            ` text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 ` +
                            `focus:z-10 sm:inline-flex hover:bg-gray-50 ` +
                            (showContactInfo ? "bg-gray-100" : "bg-white")
                          }
                        >
                          <InformationCircleIcon
                            className="-ml-0.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </button>

                        <ContextMenuLeftClickButton
                          contextMenuData={contextAssignGroup(
                            globalcontext,
                            selectedTopic,
                            itemsInboxCategories,
                            doPutThreadActions
                          )}
                          content={
                            <button
                              type="button"
                              className={
                                `relative -ml-px hidden items-center gap-x-1.5 px-3 py-2 ` +
                                ` text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 ` +
                                `focus:z-10 sm:inline-flex hover:bg-gray-50 ` +
                                (showContactInfo ? "bg-gray-100" : "bg-white")
                              }
                            >
                              <UserGroupIcon
                                className="-ml-0.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </button>
                          }
                        />

                        <ContextMenuLeftClickButton
                          contextMenuData={contextAssignOperator(
                            globalcontext,
                            selectedTopic,
                            itemsInboxCategories,
                            doPutThreadActions
                          )}
                          content={
                            <button
                              type="button"
                              className={
                                `rounded-r-md relative -ml-px hidden items-center gap-x-1.5 px-3 py-2 ` +
                                ` text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 ` +
                                `focus:z-10 sm:inline-flex hover:bg-gray-50 ` +
                                (showContactInfo ? "bg-gray-100" : "bg-white")
                              }
                            >
                              <UserIcon
                                className="-ml-0.5 h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </button>
                          }
                        />
                      </span>
                    </div>
                  </div>
                  {!showContactInfo && (
                    <div className="hidden md:flex">
                      <div className=" text-gray-900">
                        <span
                          className={`inline-flex items-center rounded-full px-3 py-0.5 text-xs font-medium ${
                            typeof selectedTopic != "undefined" &&
                            (selectedTopic.current_status == null
                              ? "bg-cyan-100 text-gray-800"
                              : `${
                                  msgStatus.filter(
                                    (s) =>
                                      s.value == selectedTopic.current_status
                                  )[0].bg
                                } text-white`)
                          } `}
                        >
                          {typeof selectedTopic != "undefined" &&
                            (selectedTopic.current_status == null
                              ? "Sem estado"
                              : msgStatus.filter(
                                  (s) => s.value == selectedTopic.current_status
                                )[0].name)}
                        </span>
                      </div>
                      {(typeof selectedTopic != "undefined" && selectedTopic.central_operator_groups != null) && (
                        <>
                          <span>・</span>
                          <span className="text-xs text-gray-700 mt-1 font-medium uppercase">
                            {selectedTopic.central_operator_groups != null &&
                              selectedTopic.central_operator_groups.name}
                          </span>
                        </>
                      )}
                    </div>
                  )}
                  <nav>
                    <MessagingActionsStatusBtn selectedTopic={selectedTopic} />
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
