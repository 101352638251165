import React, { useEffect, useRef } from "react";
import InboxNoTopicSelected from "./InboxNoTopicSelected";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import MessagingContextMenu from "../../context/MessagingContextMenu";
import { InboxLoadingTopics } from "./InboxLoadingTopics";
import ChatBubbleContent from "./ChatBubbleContent";
moment.locale("pt");

export default function InboxChatContent({
  selectedTopic,
  topicLoadingState,
  selectBatch,
  setSelectBatch,
  showContactInfo,
}) {
  const bottomRef = useRef(null);

  useEffect(() => {
    if (
      typeof selectedTopic != "undefined" &&
      Object.keys(selectedTopic).length > 0
    )
      bottomRef.current?.scrollIntoView({ behavior: "instant" });
  }, [selectedTopic]);



  return (
    <>
      {selectBatch.length > 0 ? (
        <>
          <MessagingContextMenu
            selectBatch={selectBatch}
            setSelectBatch={setSelectBatch}
          />
        </>
      ) : typeof selectedTopic != "undefined" &&
        Object.keys(selectedTopic).length === 0 &&
        topicLoadingState == 0 ? (
        <InboxNoTopicSelected />
      ) : topicLoadingState == 1 ? (
        <InboxLoadingTopics />
      ) : (
        typeof selectedTopic != "undefined" &&
        Object.keys(selectedTopic).length > 0 && (
          <>
            <div className="space-y-2 py-4 sm:space-y-4 ">
              {selectedTopic.all_events.map((item, key) => (
                <div key={key} className="flex flex-row">
                  <ChatBubbleContent
                    item={item}
                    showContactInfo={showContactInfo}
                  />
                </div>
              ))}
              <span ref={bottomRef} />
            </div>
          </>
        )
      )}
    </>
  );
}
