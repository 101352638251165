import React, { useState } from 'react'
import { Filter } from 'lucide-react'
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  DateRangeFilter,
  TextFilter,
  CheckboxFilter,
  UniqueOptionsFilter,
} from "./filter-components";

export function FlexibleTableFiltering({ column, filters, handleFilter }) {

  const [activeFilters, setActiveFilters] = useState({})

  const renderFilterComponent = (column) => {
    switch (column.filterType) {
      case "date":
        return (
          <DateRangeFilter
            onFilter={(start, end) => handleFilter(column.key, { start, end })}
            onClear={() => handleClearFilter(column.key)}
          />
        );
      case "text":
        return (
          <TextFilter
            onFilter={(e) => handleFilter(column.key, e.target.value)}
            onClear={() => handleClearFilter(column.key)}
          />
        );
      case "checkbox":
        return column.filterOptions ? (
          <CheckboxFilter
            options={column.filterOptions}
            onFilter={(selected) => handleFilter(column.key, selected)}
            onClear={() => handleClearFilter(column.key)}
          />
        ) : null;
      case "unique":
        return column.filterOptions ? (
          <UniqueOptionsFilter
            options={column.filterOptions}
            onFilter={(selected) => handleFilter(column.key, selected)}
            onClear={() => handleClearFilter(column.key)}
          />
        ) : null;
      default:
        return null;
    }
  };

  return (
    <>
      {/*  <Input
        placeholder={`Filter ${column.header}`}
        value={filters[column.key.toString()] || ""}
        onChange={(e) => handleFilter(column.key, e.target.value)}
        className="mt-2"
      /> */}

      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            size="sm"
            className={`ml-2 h-8 w-8 p-0 ${
              activeFilters[column.key] ? "bg-blue-100" : ""
            }`}
          >
            <Filter
              className={`h-4 w-4 ${
                activeFilters[column.key] ? "text-blue-500" : ""
              }`}
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-56">
          <div className="p-2">{renderFilterComponent(column)}</div>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
}
