import React, { useState } from 'react'
import { Calendar } from '@/components/ui/calendar'
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Checkbox } from "@/components/ui/checkbox"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"


export function DateRangeFilter({ onFilter, onClear }) {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const handleClear = () => {
    setStartDate(undefined)
    setEndDate(undefined)
    onClear()
  }

  return (
    <div className="flex flex-col space-y-2">
      <div className="grid gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">{startDate ? startDate.toDateString() : 'Pick start date'}</Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={startDate}
              onSelect={setStartDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="grid gap-2">
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline">{endDate ? endDate.toDateString() : 'Pick end date'}</Button>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={endDate}
              onSelect={setEndDate}
              initialFocus
            />
          </PopoverContent>
        </Popover>
      </div>
      <div className="flex justify-between">
        <Button onClick={() => onFilter(startDate, endDate)}>Apply Filter</Button>
        <Button variant="outline" onClick={handleClear}>Clear</Button>
      </div>
    </div>
  )
}


export function TextFilter({ onFilter, onClear }) {
  const [value, setValue] = useState('')

  const handleClear = () => {
    setValue('')
    onClear()
  }

  return (
    <div className="flex flex-col space-y-2">
      <Input
        type="text"
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder="Filter..."
      />
      <div className="flex justify-between">
        <Button onClick={() => onFilter(value)}>Apply Filter</Button>
        <Button variant="outline" onClick={handleClear}>Clear</Button>
      </div>
    </div>
  )
}


export function CheckboxFilter({ options, onFilter, onClear }) {
  const [selected, setSelected] = useState([])

  const handleChange = (option) => {
    setSelected(prev => 
      prev.includes(option) 
        ? prev.filter(item => item !== option)
        : [...prev, option]
    )
  }

  const handleClear = () => {
    setSelected([])
    onClear()
  }

  return (
    <div className="flex flex-col space-y-2">
      {options.map(option => (
        <div key={option} className="flex items-center space-x-2">
          <Checkbox
            id={option}
            checked={selected.includes(option)}
            onCheckedChange={() => handleChange(option)}
          />
          <Label htmlFor={option}>{option}</Label>
        </div>
      ))}
      <div className="flex justify-between">
        <Button onClick={() => onFilter(selected)}>Apply Filter</Button>
        <Button variant="outline" onClick={handleClear}>Clear</Button>
      </div>
    </div>
  )
}

export function UniqueOptionsFilter({ options, onFilter, onClear }) {
  return (
    <div className="flex flex-col space-y-2">
      {options.map(option => (
        <Button key={option} onClick={() => onFilter(option)} variant="outline">
          {option}
        </Button>
      ))}
      <Button variant="outline" onClick={onClear}>Clear</Button>
    </div>
  )
}

