

const rows = [
  {
    selector: 'input',
    required: true,
    class: '',
    type: 'text',
    name: 'name',
    title: 'Nome',
    placeholder: 'Nome do tipo de consulta...',
    id: 'servicesubtype__name',
    map: true,
    validation: (f) => f.length > 0,
    validationerr: 'Nome Inválido.',
  },
  {
    selector: 'toggle',
    required: true,
    name: 'is_enabled',
    title: 'Ativar',
    subtitle: '',
    id: 'is_enabled',
    map: true
  },
  {
    selector: 'toggle',
    required: true,
    name: 'is_remote',
    title: 'Video-consulta',
    subtitle: '',
    id: 'is_remote',
    map: true
  },
  {
    selector: 'toggle',
    required: true,
    name: 'allow_client_booking',
    title: 'Permitir marcação do cliente',
    subtitle: '',
    id: 'allow_client_booking',
    map: true
  }
];


export const formfield_editservicesubtype = {
  title: 'Editar Tipo de consulta',
  description: 'Edite o tipo de consulta',
  apiEndpoint: '/api/servicesubtype',
  method: 'PUT',
  preloadForm: true,
  name: '',
  id: '',
  submitLabel: 'Editar',
  deleteAction: {
    label: 'Eliminar',
  },
  rows: rows,
};
