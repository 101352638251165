import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Popover,
  Transition,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { useSocket } from "../../socket/SocketProvider";
import {
  BellIcon,
  BellSlashIcon
} from "@heroicons/react/24/outline";
import {
  supportNotification,
  notificationsEnabled,
  notificationState,
  enableNotifications,
} from "../../state/notifications/notificationLib";
import { GlobalStateContext } from "../../state/globalState";
import NotificationList from "./NotificationList";

export default function NavNotifications({ userData }) {
  const globalcontext = useContext(GlobalStateContext);
  const { setSocketRefreshNotifications } = useSocket();
  const [notifications, setNotifications] = useState([]);

  //const notifications = globalcontext.contextFcn.userData.data.items.notifications;
  useEffect(() => {
    setSocketRefreshNotifications(() => refreshNotifications);
  }, []);


  useEffect(() => {
    if (
      userData.unread_notifications &&
      userData.unread_notifications.length > 0
    )
      setNotifications(userData.unread_notifications);
  }, [userData]);

  function notificationEl() {
    return notificationState() == "default" ? (
      <button
        type="button"
        className=""
        onClick={() => enableNotifications(notificationsEnabled)}
      >
        Habilitar
      </button>
    ) : notificationState() == "denied" ? (
      <span className="text-red-600 font-medium">Negado</span>
    ) : notificationState() == "granted" ? (
      <span className="text-emerald-700 font-medium">Permitido</span>
    ) : (
      ""
    );
  }

  function refreshNotifications(data) {
    setNotifications(data);
  }

  return (
    <Popover className="relative">
      <PopoverButton>
        <>
          <div className="relative -m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
            {notifications.length > 0 && (
              <span className="absolute top-1 right-0 h-4 w-4 rounded-full bg-red-600 flex justify-center items-center items text-[8px] text-white">
                {notifications.length}
              </span>
            )}
            <span className="sr-only">View notifications</span>
            {notificationState() == "granted" ? (
              <BellIcon className="h-6 w-6" aria-hidden="true" />
            ) : (
              <BellSlashIcon className="h-6 w-6" aria-hidden="true" />
            )}
          </div>
        </>
      </PopoverButton>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <PopoverPanel className="absolute left-1/2 z-10 mt-5 flex h-[90vh] w-[32.5rem] -translate-x-2/3 tr px-4">
          <div className="w-screen max-w-sm flex-auto overflow-hidden rounded-3xl bg-white text-sm leading-6 shadow-lg ring-1 ring-gray-900/5">
            <div className="p-4 h-full overflow-auto">
              <NotificationList
                notifications={notifications}
                setNotifications={setNotifications}
              />
            </div>
          </div>
        </PopoverPanel>
      </Transition>
    </Popover>
  );
}
