import React, { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

export default function TinyMceEditor({ setContent, content, editorRef, height = 300, toolbarOptions= '' }) {
  const onChange = (txt) => {
    setContent(txt);
  };
  return (
    <>
      <Editor
        apiKey="5rsqbk5sts3a72pzrrlyy00bz17pxnjzo2yj2xyd8sxxrnhz"
        onInit={(evt, editor) => (editorRef.current = editor)}
        //onEditorChange={onChange}
        initialValue={content}
        init={{
          //selector: 'textarea#premiumskinsandicons-borderless',
          skin: 'borderless',
          height: height,
          menubar: false,
          plugins: ['code'],
          toolbar:
            'undo redo | formatselect | ' +
            'bold italic backcolor | alignleft aligncenter ' +
            'alignright alignjustify | bullist numlist outdent indent | ' +
            'removeformat ' + toolbarOptions,
          content_style:
            'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        }}
      />
    </>
  );
}
