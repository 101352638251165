import {
  PhoneXMarkIcon,
  CheckIcon,
  QuestionMarkCircleIcon,
  EnvelopeIcon,
  DevicePhoneMobileIcon,
} from "@heroicons/react/24/outline";

export const msgStatus = [
  {
    value: "CLOSED",
    name: "Fechado",
    actionLabel: "Resolver",
    href: "#",
    icon: CheckIcon,
    bg: "bg-emerald-200",
    color: "green",
    iconColor: "text-white",
  },
  {
    value: "PENDING",
    name: "Pendente",
    icon: QuestionMarkCircleIcon,
    actionLabel: "Marcar como pendente",
    href: "#",
    bg: "bg-yellow-200",
    color: "yellow",
    iconColor: "text-white",
  },
  {
    value: "OPEN",
    name: "Aberto",
    icon: PhoneXMarkIcon,
    actionLabel: "Marcar como aberto",
    href: "#",
    bg: "bg-red-200",
    color: "red",
    iconColor: "text-white",
  },
];

export const msgDeliveryState = [
  {
    key: "DELIVERED",
    color: "emerald",
    label: "Entregue",
  },
  {
    key: "PENDING",
    color: "orange",
    label: "Em entrega",
  },
  {
    key: "FAILED",
    color: "red",
    label: "Falhou",
  },
];

export const msgChannel = [
  {
    key: "EMAIL",
    icon: EnvelopeIcon,
    label: "Email",
    color: "red",
  },
  {
    key: "SMS",
    icon: DevicePhoneMobileIcon,
    label: "SMS",
    color: "red",
  },
  {
    key: "PHONECALL",
    icon: null,
    label: "Chamada",
    color: "red",
  },
];