import React from "react";
import PropTypes from "prop-types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Checkbox } from "@/components/ui/checkbox";
import { Button } from "@/components/ui/button";
import { useTableLogic } from "./useTableLogic";
import { ChevronUp, ChevronDown } from "lucide-react";
import { tableConfigPropType } from "./tablePropTypes";
import { Skeleton } from "@/components/ui/skeleton";
import { FlexibleTableMenubar } from "./FlexibleTableMenubar";
import { FlexibleTableFiltering } from "./FlexibleTableFiltering";

export function FlexibleTable({ data, config, isLoading }) {
  const {
    paginatedData,
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    sortConfig,
    handleSort,
    filters,
    handleFilter,
    selectedItems,
    handleSelectItem,
    handleSelectAll,
    visibleColumns,
    toggleColumnVisibility,
    totalItems,
  } = useTableLogic(data, config);

  return (
    <div className="space-y-4">
      {!isLoading ? (
        <div className="flex items-center space-x-4">
          <div className="space-y-2">
            <Skeleton className="h-4 w-[350px]" />
            <Skeleton className="h-4 w-[300px]" />
          </div>
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <FlexibleTableMenubar
              data={data}
              config={config}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              visibleColumns={visibleColumns}
              toggleColumnVisibility={toggleColumnVisibility}
            />
          </div>

          <Table>
            <TableHeader>
              <TableRow>
                {config.selectable && (
                  <TableHead className="w-[50px]">
                    <Checkbox
                      checked={selectedItems.length === paginatedData.length}
                      onCheckedChange={handleSelectAll}
                    />
                  </TableHead>
                )}
                {config.columns.map(
                  (column) =>
                    visibleColumns.has(column.key) && (
                      <TableHead key={column.key.toString()}>
                        <div className="flex items-center space-x-2">
                          <span
                            className={`text-wrap ${
                              column.headerTextSize
                                ? `text-[${column.headerTextSize}px]`
                                : ""
                            }`}
                          >
                            {column.header}
                          </span>
                          {column.sortable && (
                            <Button
                              variant="ghost"
                              size="sm"
                              className="h-8 w-8 p-0"
                              onClick={() => handleSort(column.key)}
                            >
                              {sortConfig?.key === column.key ? (
                                sortConfig.direction === "asc" ? (
                                  <ChevronUp className="h-4 w-4" />
                                ) : (
                                  <ChevronDown className="h-4 w-4" />
                                )
                              ) : (
                                <ChevronUp className="h-4 w-4 opacity-50" />
                              )}
                            </Button>
                          )}
                          {column.filterable && (
                            <FlexibleTableFiltering
                              filters={filters}
                              handleFilter={handleFilter}
                              column={column}
                            />
                          )}
                        </div>
                      </TableHead>
                    )
                )}
              </TableRow>
            </TableHeader>
            <TableBody>
              {paginatedData.map((item, index) => (
                <TableRow key={index}>
                  {config.selectable && (
                    <TableCell>
                      <Checkbox
                        checked={selectedItems.includes(item)}
                        onCheckedChange={() => handleSelectItem(item)}
                      />
                    </TableCell>
                  )}
                  {config.columns.map(
                    (column) =>
                      visibleColumns.has(column.key) && (
                        <TableCell key={column.key.toString()}>
                          {column.render
                            ? column.render(item[column.key], item)
                            : item[column.key]?.toString()}
                        </TableCell>
                      )
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>

          {config.pagination && (
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
            />
          )}
        </>
      )}
    </div>
  );
}

FlexibleTable.propTypes = {
  data: PropTypes.array.isRequired,
  config: tableConfigPropType.isRequired,
};

function Pagination({
  currentPage,
  totalPages,
  onPageChange,
  totalItems,
  itemsPerPage,
}) {
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <div className="flex items-center justify-between">
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      >
        Previous
      </Button>
      <span>
        Showing {startItem}-{endItem} of {totalItems} items
      </span>
      <Button
        variant="outline"
        size="sm"
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      >
        Next
      </Button>
    </div>
  );
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalItems: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
};
