import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import usePostRequest from "../../hooks/usePostRequest";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";

const NewTagModal = forwardRef(({ userData }, ref) => {
  const openModalRef = useRef(null);
  const [tagName, setTagname] = useState("");

  const { doPost: doPostTag, isComplete: isCompletePostTag } =
    usePostRequest("messagingtag");

  function openModal() {
    openModalRef.current.click();
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    doPostTag({
      name: tagName,
    });
  };

  useImperativeHandle(ref, () => ({
    //handleSubmit,
    openModal,
    isCompletePostTag: () => isCompletePostTag,
  }));

  

  useEffect(() => {
    //Handeclose modal
    if (isCompletePostTag) {
    }
  }, [isCompletePostTag]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <span ref={openModalRef} />
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Bem-vindo</DialogTitle>
          <DialogDescription>{JSON.stringify(userData)}</DialogDescription>
        </DialogHeader>

        <form className="w-full  space-y-4" onSubmit={handleSubmit}>
          <input
            value={tagName}
            onChange={(e) => setTagname(e.target.value)}
            type="text"
            name="newTagName"
            placeholder="Nome da etiqueta"
            id="newTagName"
            className={`block w-full rounded-md border-0 p-1.5 shadow-sm ring-1 ring-inset focus:ring-2 focus:ring-inset sm:text-sm sm:leading-6 bg-white`}
          />
          <button
            type="submit"
            className="rounded-md bg-white px-2.5 py-1.5 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
          >
            Enviar
          </button>
        </form>

        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
});

export default NewTagModal;
