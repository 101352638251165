import { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import TriggersForm from "./TriggersForm";
import usePostRequest from "../../hooks/usePostRequest";
import usePutRequest from "../../hooks/usePutRequest";

export default function TriggersModal({
  isOpen,
  onClose,
  trigger = null,
  onCompleteRequest,
}) {
  const { doPost: doPost, isComplete: isCompletePost } =
    usePostRequest("automationTriggers");
  const { doPut: doPut, isComplete: isCompletePut } =
    usePutRequest("automationTriggers");

  const handleSubmit = async (data) => {
    if (trigger) {
      doPut({ id: trigger.id, data: data });
    } else {
      doPost({ data: data });
    }
    onClose();
  };

  useEffect(() => {
    if (isCompletePost || isCompletePut) onCompleteRequest();
  }, [isCompletePost, isCompletePut]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {trigger ? "Edit Trigger" : "Create New Trigger"}
          </DialogTitle>
        </DialogHeader>
        <TriggersForm onSubmit={handleSubmit} initialData={trigger} />
      </DialogContent>
    </Dialog>
  );
}
