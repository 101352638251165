import React, { useState, useEffect } from "react";
import AnalyticsBoard from "./boards/AnalyticsBoard";
import useGetRequest from "../hooks/useGetRequest";

export default function AnalyticsHome() {
  const [selectedDashboard, setSelectedDashboard] = useState(3);

  useEffect(() => {
    doGetAnalyticsOptions({
      dashboardId: selectedDashboard,
    });
  }, [selectedDashboard]);


  const {
    doGet: doGetAnalyticsOptions,
    items: analiticsOptions,
    isComplete: isLoadingComplete,
  } = useGetRequest("analyticsOptions");

  const tabs = [
    {
      title: "Ocupação",
      idx: 3,
    },
    {
      title: "Dentária",
      idx: 2,
    },
    {
      title: "Faturação",
      idx: 5,
    },
  ];

  return (
    <div className="p-4 w-full">
      <div className="flex flex-col space-y-3 h-full">
        <div>
          <div className="text-sm font-medium text-center text-gray-500 ">
            <ul className="flex flex-wrap -mb-px">
              {tabs.map((t, k) => (
                <li key={k} className="mr-2">
                  {!t.disabled ? (
                    <a
                      href="#"
                      onClick={() => setSelectedDashboard(t.idx)}
                      className={
                        t.idx == selectedDashboard
                          ? "inline-block p-3 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active"
                          : "inline-block p-3 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 "
                      }
                    >
                      {t.title}
                    </a>
                  ) : (
                    <a className="inline-block p-3 text-gray-400 rounded-t-lg cursor-not-allowed ">
                      {t.title}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="p-2 h-full" id={selectedDashboard} role="tabpanel">
          <AnalyticsBoard
            isLoadingComplete={isLoadingComplete}
            analyticsOpts={analiticsOptions}
          />
        </div>
      </div>
    </div>
  );
}
