// src/hooks/useAuth.js

import { useAuth as useOidcAuth } from 'react-oidc-context';

export const useAuth = () => {
  const auth = useOidcAuth();

  // Extract the authentication state and user information
  const isAuthenticated = auth.isAuthenticated;
  const isLoading = auth.isLoading;
  const error = auth.error;

  // Access the user object, which includes tokens and profile information
  const user = auth.user;

  // Access tokens
  const accessToken = user?.access_token;
  const idToken = user?.id_token;
  const refreshToken = user?.refresh_token;
  const token = accessToken;

  // User profile information (claims)
  const profile = user?.profile;

  return {
    ...auth,
    isAuthenticated,
    isLoading,
    error,
    token,
    accessToken,
    idToken,
    refreshToken,
    profile,
  };
};