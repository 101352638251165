

import { useState, useEffect } from "react"
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination"

const ITEMS_PER_PAGE = 10

export function AutomationLogManager() {
  const [logs, setLogs] = useState([])
  const [filteredLogs, setFilteredLogs] = useState([])
  const [searchTerm, setSearchTerm] = useState("")
  const [resultFilter, setResultFilter] = useState("all")
  const [sortField, setSortField] = useState("executedAt")
  const [sortDirection, setSortDirection] = useState("desc")
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    // Fetch logs
    // This is a mock implementation. Replace with actual API call.
    const mockLogs = Array.from({ length: 50 }, (_, i) => ({
      id: `log-${i + 1}`,
      triggerId: `trigger-${Math.floor(i / 5) + 1}`,
      triggerName: `Trigger ${Math.floor(i / 5) + 1}`,
      eventId: `event-${i + 1}`,
      executedAt: new Date(Date.now() - i * 3600000).toISOString(),
      result: Math.random() > 0.2 ? "success" : "failed",
    }))
    setLogs(mockLogs)
  }, [])

  useEffect(() => {
    let result = [...logs]

    // Apply search filter
    if (searchTerm) {
      result = result.filter(
        (log) =>
          log.triggerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
          log.eventId.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    // Apply result filter
    if (resultFilter !== "all") {
      result = result.filter((log) => log.result === resultFilter)
    }

    // Apply sorting
    result.sort((a, b) => {
      if (a[sortField] < b[sortField]) return sortDirection === "asc" ? -1 : 1
      if (a[sortField] > b[sortField]) return sortDirection === "asc" ? 1 : -1
      return 0
    })

    setFilteredLogs(result)
    setCurrentPage(1)
  }, [logs, searchTerm, resultFilter, sortField, sortDirection])

  const pageCount = Math.ceil(filteredLogs.length / ITEMS_PER_PAGE)
  const paginatedLogs = filteredLogs.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  )

  const handleSort = (field) => {
    if (field === sortField) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc")
    } else {
      setSortField(field)
      setSortDirection("asc")
    }
  }

  return (
    <div className="space-y-4">
      <h2 className="text-2xl font-bold">Event Logs</h2>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
        <Input
          type="text"
          placeholder="Search by trigger name or event ID..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="max-w-sm"
        />
        <Select value={resultFilter} onValueChange={(value) => setResultFilter(value)}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Filter by result" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">All Results</SelectItem>
            <SelectItem value="success">Success</SelectItem>
            <SelectItem value="failed">Failed</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="cursor-pointer" onClick={() => handleSort("triggerName")}>
              Trigger Name {sortField === "triggerName" && (sortDirection === "asc" ? "▲" : "▼")}
            </TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort("eventId")}>
              Event ID {sortField === "eventId" && (sortDirection === "asc" ? "▲" : "▼")}
            </TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort("executedAt")}>
              Executed At {sortField === "executedAt" && (sortDirection === "asc" ? "▲" : "▼")}
            </TableHead>
            <TableHead className="cursor-pointer" onClick={() => handleSort("result")}>
              Result {sortField === "result" && (sortDirection === "asc" ? "▲" : "▼")}
            </TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {paginatedLogs.map((log) => (
            <TableRow key={log.id}>
              <TableCell>{log.triggerName}</TableCell>
              <TableCell>{log.eventId}</TableCell>
              <TableCell>{new Date(log.executedAt).toLocaleString()}</TableCell>
              <TableCell>
                <span
                  className={`px-2 py-1 rounded-full text-xs font-semibold ${
                    log.result === "success" ? "bg-green-100 text-green-800" : "bg-red-100 text-red-800"
                  }`}
                >
                  {log.result}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <PaginationPrevious
              href="#"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              className={currentPage === 1 ? "pointer-events-none opacity-50" : ""}
            />
          </PaginationItem>
          {Array.from({ length: pageCount }, (_, i) => i + 1).map((page) => (
            <PaginationItem key={page}>
              <PaginationLink
                href="#"
                onClick={() => setCurrentPage(page)}
                isActive={currentPage === page}
              >
                {page}
              </PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationNext
              href="#"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
              className={currentPage === pageCount ? "pointer-events-none opacity-50" : ""}
            />
          </PaginationItem>
        </PaginationContent>
      </Pagination>
    </div>
  )
}

