import React, { createContext, useState, useRef, useEffect } from "react";
/* import { useInterpret } from '@xstate/react';
import { endCallModalMachine } from './endcall.modal.machine'; */
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import useGetRequest from "../hooks/useGetRequest";

import NotificationPanel from "./NotificationPanel";
import AreYouSureDangerModal from "../common/modal/AreYouSureDangerModal";
import AreYouSureSuccessModal from "../common/modal/AreYouSureSuccessModal";
import AreYouSureInfoModal from "../common/modal/AreYouSureInfoModal";
import SearchPopup from "../nav/search/SearchPopup";
import NewCustomerSmartcardImport from "../notification/NewCustomerSmartcardImport";
import CustomerActions from "../client/actions/CustomerActions";
import { socketApiUrl } from "../common/apiEndpoints";
import {
  getJson,
  putJson,
  postJson,
  deleteJson,
  blankResult,
  reqOk,
} from "../common/fetchdata";
import {
  getRequests as gt,
  putRequests as pt,
  postRequests as po,
  deleteRequests as dl,
} from "../common/apiEndpoints";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
moment.locale("pt");

import { manageUserConfig } from "./userconfig/userConfigManager";
import {
  setConfigBookmark,
  getRecentBookmarks,
} from "./userconfig/userConfigManager";

import {
  globalUiResetState,
  handleGlobalUiUpdate,
  handleResetGlobalUiUpdate,
} from "./globalui/globalUiUpdate";
import { SocketProvider } from "../socket/SocketProvider";
import { OnboardNewUser } from "../onboarding/OnboardNewUser";

let socket_conn;

export const GlobalStateContext = createContext({});

export const GlobalStateProvider = (props) => {
  let auth = useAuth();
  let navigate = useNavigate();
  const token = auth.token;
  //const authService = useInterpret(endCallModalMachine);
  const [isLoading, setIsLoading] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [clientSocketId, setClientSocketId] = useState(null);
  const [modalMsg, setModalMsg] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalAction, setModalAction] = useState(null);
  const [viewChatbox, setViewChatbox] = useState(false);
  const [expandSidebar, setExpandSidebar] = useState(false);
  const [breadcrumbContext, setBreadcrumbContext] = useState("");

  // GET REQUESTS
  const [activeCall, setActiveCall] = useState(blankResult);
  const [_pSearch, set_pSearch] = useState(blankResult);

  const [modalSucOpen, setmodalSucOpen] = useState(false);
  const [modalInfoOpen, setmodalInfoOpen] = useState(false);
  const [modalDangerOpen, setmodalDangerOpen] = useState(false);

  // Search
  const [showSearch, setShowSearch] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [searchMode, setSearchMode] = useState({
    universe: "all",
    mode: "pick",
    selected: null,
  });

  // Global variable parking
  const [parkVar, setParkVar] = useState([]);

  // Notifications
  const [smartCardData, setSmartCardData] = useState(null);
  const [triggerNotification, setTriggerNotification] = useState({});
  const [globalUiUpdate, setGlobalUiUpdate] = useState(globalUiResetState);

  //Refs
  const customerActionsRef = useRef();

  // Is Loaded
  const [isStateLoaded, setIsStateLoaded] = useState(false);

  // Requests
  const {
    doGet: doGetUserData,
    items: userData,
    isComplete: isCompleteUserData,
  } = useGetRequest("userdata");

  useEffect(() => {
    if (auth.token) {
      initializeUserVars();
    }
  }, []);

  useEffect(() => {
    if (isCompleteUserData) {
      setIsStateLoaded(true);
    }
  }, [isCompleteUserData]);

  function initializeUserVars() {
    refreshUserData();
    manageUserConfig();
  }

  const refreshUserData = () => {
    doGetUserData();
  };

  function toggleDarkMode() {
    setDarkMode((prevDarkMode) => !prevDarkMode);
  }

  const handlePostSearch = (payload = {}) =>
    postJson(
      po.search,
      { ..._pSearch, token: token, payload: payload, showSucToast: false },
      set_pSearch
    );

  // Role management
  function canReadResource(_resource) {
    /*  if (!reqOk(userData)) return false;
    const _uroles = userData.items.userRoles.filter(
      (r) => r.role_resource.key == _resource
    );
    if (_uroles.length == 0) return false;

    return _uroles[0].read == 1 ? true : false; */
    return true;
  }
  function canWriteResource(_resource) {
    /*     if (!reqOk(userData)) return false;
    const _uroles = userData.items.userRoles.filter(
      (r) => r.role_resource.key == _resource
    );
    if (_uroles.length == 0) return false;

    return _uroles[0].write == 1 ? true : false; */
    return true;
  }

  //
  const handleDeleteCustomerManager = (id) => {
    setModalMsg("Deseja realmente remover?");
    setModalAction({ action: "del_customermanager", id: id });
    setmodalDangerOpen(true);
  };

  const handleCustomerWebInvite = (customerId, channel, contactField) => {
    if (channel == "sms")
      setModalMsg(
        `Enviará uma SMS de convite para criação de uma nova conta para +${contactField.main_mob_phone_cc} ${contactField.main_mob_phone_loc}. Confirma?`
      );

    if (channel == "email")
      setModalMsg(
        `Enviará um email de convite para criação de uma nova conta para ${contactField.email}. Confirma?`
      );

    setModalAction({
      action: "customerwebinvite",
      channel: channel,
      id: customerId,
    });

    setmodalInfoOpen(true);
  };

  const handleNewBooking = (args = {}) => {
    let u = new URLSearchParams(args).toString();
    let endpoint = "/call/new";
    if (u.length > 0) endpoint = `${endpoint}?${u}`;
    navigate(endpoint);
  };

  const handleModalClick = () => {
    if (modalAction.action == "custom_fcn") modalAction.customFcn();
  };

  function openSearch(mode) {
    if (mode) setSearchMode(mode);
    else setSearchMode({ universe: "all", mode: "list" });
    setShowSearch(true);
  }
  function hideSearch() {
    setShowSearch(false);
  }

  function setPark(ref, val) {
    const _new = { ref: ref, val: val };
    setParkVar([...parkVar.filter((p) => p.ref != ref), _new]);
    setShowSearch(false);
  }
  function getPark(ref) {
    const _new = parkVar.filter((p) => p.ref == ref);
    if (_new.length == 0) return false;
    else return _new[0];
  }
  function resetPark(ref) {
    setParkVar([...parkVar.filter((p) => p.ref != ref)]);
  }

  function popupModal(type, message, okTrigg, title) {
    switch (type) {
      case "success":
        title = typeof title !== "undefined" ? title : "Sucesso";
        setmodalSucOpen(true);
        setModalTitle(title);
        setModalMsg(message);
        setModalAction({
          action: "custom_fcn",
          customFcn: okTrigg,
        });
        break;
      case "info":
        title = typeof title !== "undefined" ? title : "Informação";
        setmodalInfoOpen(true);
        setModalTitle(title);
        setModalMsg(message);
        setModalAction({
          action: "custom_fcn",
          customFcn: okTrigg,
        });
        break;
      case "danger":
        title = typeof title !== "undefined" ? title : "Tem a certeza";
        setmodalDangerOpen(true);
        setModalTitle(title);
        setModalMsg(message);
        setModalAction({
          action: "custom_fcn",
          customFcn: okTrigg,
        });
        break;
      default:
        break;
    }
  }

  function handleSendClientEvent() {
    console.log("");
  }

  const handleViewChatbox = () => setViewChatbox(!viewChatbox);

  const contextFcn = {
    dummy: () => console.log("Global context function"),
    loading: {
      isLoading: isLoading,
      setLoaded: () => setIsLoading(false),
      setIsLoading: () => setIsLoading(true),
    },
    userData: {
      data: userData,
      fetch: refreshUserData,
      clientSocket: clientSocketId,
      canReadResource: (res) => canReadResource(res),
      canWriteResource: (res) => canWriteResource(res),
    },
    customer: {
      manager: {
        del: handleDeleteCustomerManager,
      },
      actions: {
        attendanceReport: (call) =>
          customerActionsRef.current.attendanceReport(call),
        callPayment: (call) =>
          customerActionsRef.current.callPaymentAction(call),
      },
    },
    customerWeb: {
      invite: handleCustomerWebInvite,
    },
    appointment: {
      new: handleNewBooking,
    },
    view: {
      dark: darkMode,
      toggle: toggleDarkMode,
    },
    request: {
      postsearch: {
        do: handlePostSearch,
        status: reqOk(_pSearch),
        res: reqOk(_pSearch) && _pSearch.items.data,
      },
    },
    breadcrumb: {
      context: breadcrumbContext,
      setContext: setBreadcrumbContext,
    },
    parkVar: {
      reset: resetPark,
      get: getPark,
      set: setPark,
    },
    panel: {
      modal: {
        open: popupModal,
      },
      search: {
        open: openSearch,
        close: hideSearch,
        str: searchStr,
        setStr: setSearchStr,
        mode: searchMode,
      },
      sidebar: {
        expand: {
          isExpanded: expandSidebar,
          setExpanded: setExpandSidebar,
        },
      },
      chatbox: {
        visible: {
          toggle: handleViewChatbox,
          view: viewChatbox,
        },
      },
    },
    userDataConfig: {
      setBookmark: setConfigBookmark,
      getRecent: getRecentBookmarks,
    },
    clientEvent: {
      send: handleSendClientEvent,
    },
    globalUiUpdate: globalUiUpdate,
    globalUiReset: (field) =>
      handleResetGlobalUiUpdate(field, globalUiUpdate, setGlobalUiUpdate),
    navigate: navigate,
  };

  return (
    <GlobalStateContext.Provider
      value={{
        isStateLoaded,
        userData,
        activeCall,
        contextFcn,
      }}
    >
      {isCompleteUserData && <OnboardNewUser userData={userData} />}

      <SocketProvider>
        <NotificationPanel
          triggerNotification={triggerNotification}
          setTriggerNotification={setTriggerNotification}
        />
        <SearchPopup
          setQuery={setSearchStr}
          query={searchStr}
          show={showSearch}
          setShow={setShowSearch}
          setLookupMode={setSearchMode}
          lookupMode={searchMode}
        />

        <AreYouSureSuccessModal
          open={modalSucOpen}
          setOpen={setmodalSucOpen}
          handleConfirm={handleModalClick}
          title={modalTitle}
          description={modalMsg}
        />

        <AreYouSureDangerModal
          open={modalDangerOpen}
          setOpen={setmodalDangerOpen}
          handleConfirm={handleModalClick}
          title={modalTitle}
          description={modalMsg}
        />

        <AreYouSureInfoModal
          open={modalInfoOpen}
          setOpen={setmodalInfoOpen}
          handleConfirm={handleModalClick}
          title={modalTitle}
          description={modalMsg}
        />

        <NewCustomerSmartcardImport
          data={smartCardData}
          setData={setSmartCardData}
        />

        <CustomerActions ref={customerActionsRef} />

        {props.children}
      </SocketProvider>
    </GlobalStateContext.Provider>
  );
};
