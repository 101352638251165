import React, { useEffect, useState } from "react";

export default function RoundAvatar({
  size,
  avatarUrl,
  firstName,
  lastName,
  borderColor,
}) {

  const [bColor, setBColor] = useState("blue-400");

  useEffect(() => {
    if (typeof borderColor != "undefined") setBColor(borderColor);
  }, [borderColor]);

  return (
    <>
      {firstName != null && (
        <>
          {avatarUrl != null && (
            <img
              style={{ width: `${size}rem`, height: `${size}rem` }}
              className={`rounded-full cursor-pointer bg-gray-200 border-2 border-${bColor}`}
              src={avatarUrl}
              alt=""
            />
          )}
          {avatarUrl == null && (
            <div
              style={{ width: `${size}rem`, height: `${size}rem` }}
              className={`inline-flex items-center justify-center overflow-hidden rounded-full cursor-pointer bg-gray-200 border-2 border-${bColor}`}
            >
              <span className={`font-medium text-${size / 10}xl text-gray-800`}>
                {`${firstName[0]}${lastName && lastName[0]}`}
              </span>
            </div>
          )}
        </>
      )}
    </>
  );
}
