import React from "react";
import { useState } from "react";
import { UserCircleIcon, LinkIcon } from "@heroicons/react/24/outline";
import ConfigIntegration from "./ConfigIntegration";

export default function ConfigDataHome() {
  const [page, setPage] = useState("integration");

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const secondaryNavigation = [
    {
      name: "Integrações",
      icon: LinkIcon,
      current: false,
      key: "integration",
    },
  ];

  return (
    <>
      <div className="mx-auto max-w-7xl lg:flex lg:gap-x-4 lg:px-8">
        <aside className="flex overflow-x-auto border-b border-gray-900/5 py-4 lg:block lg:w-48 lg:flex-none lg:border-0 lg:py-4">
          <nav className="flex-none px-4 sm:px-6 lg:px-0">
            <ul
              role="list"
              className="flex gap-x-3 gap-y-1 whitespace-nowrap lg:flex-col"
            >
              {secondaryNavigation.map((item) => (
                <li key={item.name}>
                  <button
                    onClick={() => setPage(item.key)}
                    className={classNames(
                      page == item.key
                        ? "bg-gray-50 text-indigo-600"
                        : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                      "group flex gap-x-3 rounded-md py-2 pl-2 pr-3 text-sm leading-6 font-semibold"
                    )}
                  >
                    <item.icon
                      className={classNames(
                        page == item.key
                          ? "text-indigo-600"
                          : "text-gray-400 group-hover:text-indigo-600",
                        "h-6 w-6 shrink-0"
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          </nav>
        </aside>

        <main className="px-4 py-16 sm:px-6 lg:flex-auto lg:px-0 lg:py-4">
          <div className="mx-auto max-w-2xl space-y-6 sm:space-y-10 lg:mx-0 lg:max-w-none">
            {page == "integration" && <ConfigIntegration />}
          </div>
        </main>
      </div>
    </>
  );
}
