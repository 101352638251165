import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { AuthProvider } from "react-oidc-context";
import oidcConfig from '../oidcConfig';
import * as Sentry from "@sentry/react";

if (import.meta.env.MODE === "production") {
  Sentry.init({
    dsn: "https://5cebad48e38c5829f1a94289cc470dc3@o4506676634320896.ingest.sentry.io/4506695378665472",
    integrations: [Sentry.replayIntegration()],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
} else {
  console.log("Running in development mode");
}

import "./style/main.scss";
import App from "./App";
import AuthSilentRenew from "./auth/AuthSilentRenew";


ReactDOM.createRoot(document.getElementById("root")).render(
  <AuthProvider {...oidcConfig}>
    <Toaster autoClose={5000} />
    <BrowserRouter>
      <Routes>
        <Route path="/silent-renew" element={<AuthSilentRenew />} />
        <Route path="/*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </AuthProvider>
);
