import React, { useState } from 'react';
import TablePagination from './TablePagination'
import TableActionMenu from './TableActionMenu';
import TableFilter from './TableFilter';
import HeaderSortFilter from './HeaderSortFilter';

import FormSlideover from '../slideover/FormSlideover';
import ViewSlideover from '../slideover/ViewSlideover';
import { removeUnusedCriteria } from './tabular_lib';
import NoRecordsFound from '../emptystate/NoRecordsFound';

const TableEngineTw = ({ cfg, items, fetchData, context }) => {
  const tdStyle = cfg.expandTab != null ? {} : {};
  const initFilter = 'filter' in cfg ? cfg.filter.initFilter : null;
  const [filterCfg, setFilterCfg] = React.useState(initFilter);
  const groupContext = context != null ? context.group : null;

  // Edit data
  const [edit_formData, edit_setFormData] = React.useState({});
  const [openEdit, setOpenEdit] = React.useState(false);
  const [sotemplate, setSoTemplate] = React.useState({});
  const [openView, setOpenView] = React.useState(false);
  const [dataView, setDataView] = React.useState({});
  const [soKey, setSoKey] = React.useState(1);
  const [editContext, setEditContext] = React.useState(null);
  const [filtLabel, setFiltLabel] = useState(null);

  function initialFilterActive() {
    return JSON.stringify(filterCfg) ==
      JSON.stringify(initFilter)
  }
  const handleClickEdit = (e) => {
    setEditContext(e);
    setSoTemplate(cfg.edit.template)
    setOpenEdit(true);
  };

  const handleClickCustomSo = (e, template) => {
    setEditContext(e);
    setSoTemplate(template)
    setOpenEdit(true);
  };

  const resetFilter = () => {
    setFilterCfg(initFilter);
    applyFilter(initFilter);
  };
  const applyFilter = (fObj, mode = 'fetch') => {
    let fdef = removeUnusedCriteria(fObj);
    //console.log(fdef);
    if (context != null) fdef = { ...fdef, [context.field]: context.ref };
    fetchData(fdef, mode);
  };

  const handleDownloadResults = () => {
    let fdef = removeUnusedCriteria(filterCfg);
    if (context != null) fdef = { ...fdef, [context.field]: context.ref };
    fetchData(fdef, 'download');
  };

  React.useEffect(() => {
    refreshHandler();
  }, [filterCfg]);

  const handleEditOkData = () => {
    setSoKey((k) => k + 1);
    setOpenEdit(false);
    refreshHandler();
  };

  const handleEditErrorData = () => {
    console.error('Error Updating');
    setSoKey((k) => k + 1);
    setOpenEdit(false);
  };

  const refreshHandler = () => {
    applyFilter(filterCfg);
  };

  React.useEffect(() => {
    if (
      'view' in cfg &&
      'refreshOnClose' in cfg.view &&
      cfg.view.refreshOnClose &&
      !openView
    )
      refreshHandler();
  }, [openView]);


  const TableHeader = () => {

    return (
      <thead className="bg-gray-50">
        <tr>
          {cfg.columns.map((_c, k) => (
            <th
              scope="col"
              className={`py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6`}
              key={`${k}`}
            >
              {'filter' in _c ? (
                <HeaderSortFilter
                  filtLabel={filtLabel}
                  setFiltLabel={setFiltLabel}
                  tfilter={filterCfg}
                  setFilter={setFilterCfg}
                  colCfg={_c}
                  buttonContent={<>{_c.label}</>}
                />
              ) : (
                _c.label
              )}
            </th>
          ))}

          {'actionButton' in cfg && (
            <th scope="col" className="px-2 py-3" />
          )}
          {'edit' in cfg && cfg.edit.showEditButton && (
            <th
              scope="col"
              className="relative py-3.5 pl-3 pr-4 sm:pr-0"
            >
              <span className="sr-only">Editar</span>
            </th>
          )}
        </tr>
      </thead>
    )
  }

  const TableBody = () => {

    return (
      <tbody>
        {items.data &&
          items.data.map((_r, k) => (
            <tr
              key={k}
              className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600 font-light"
            >
              {cfg.columns.map((_c, k) => {
                let val = _r[_c.key];
                if ('valueformat' in _c) {
                  val = _c['valueformat'](val, _r, refreshHandler);
                }
                return (
                  <td
                    style={tdStyle}
                    className={`whitespace-normal py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 ${_c.class}`}
                    key={k}
                  >
                    {'slideoverView' in _c ? (
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          setDataView(_r);
                          setOpenView(true);
                        }}
                      >
                        {val}
                      </div>
                    ) : (
                      <div>{val}</div>
                    )}
                  </td>
                );
              })}
              {'actionButton' in cfg && (
                <td
                  className="py-4 pl-3 pr-6 text-right text-sm font-medium"
                  style={tdStyle}
                >
                  <TableActionMenu
                    row={_r}
                    items={cfg.actionButton.items}
                    optionsText={'Opções'}
                    editButton={() => handleClickEdit(_r.id)}
                    slideOver={handleClickCustomSo}
                  />
                </td>
              )}

              {'edit' in cfg && cfg.edit.showEditButton && (
                <td
                  style={tdStyle}
                  className="py-4 pl-3 pr-6 text-right text-sm font-medium"
                >
                  <a
                    href="#"
                    onClick={() => handleClickEdit(_r.id)}
                    className="text-gray-400 hover:text-gray-900"
                  >
                    Editar
                  </a>
                </td>
              )}
            </tr>
          ))}
      </tbody>
    )
  }

  const TableLoading = () => {

    return (
      <div
        role="status"
        className="w-full p-4 space-y-4 border border-gray-200 divide-y divide-gray-200 rounded shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
      >
        <div className="flex items-center justify-between">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>

        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <div className="flex items-center justify-between pt-4">
          <div>
            <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5" />
            <div className="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700" />
          </div>
          <div className="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12" />
        </div>
        <span className="sr-only">Loading...</span>
      </div>
    )
  }

  return (
    <>
      {'edit' in cfg && cfg.edit.type == 'slideover' && (
        <FormSlideover
          key={soKey}
          fin={sotemplate}
          open={openEdit}
          setOpen={setOpenEdit}
          formData={edit_formData}
          setFormData={edit_setFormData}
          okHandler={handleEditOkData}
          errHandler={handleEditErrorData}
          context={editContext}
        />
      )}

      {'view' in cfg && cfg.view.type == 'slideover' && (
        <ViewSlideover
          open={openView}
          setOpen={setOpenView}
          data={dataView}
          tmpl={cfg.view}
        />
      )}



      {'filter' in cfg && (
        <TableFilter
          cfg={cfg}
          tfilter={filterCfg}
          setFilter={setFilterCfg}
          resetFilter={resetFilter}
          downloadResults={handleDownloadResults}
          filtLabel={filtLabel}
          setFiltLabel={setFiltLabel}
        />
      )}

      <div className="mt-8 flow-root">
        
        {!items.data && <TableLoading />}

        {items.data &&
         ( items.totalResults == 0
          ? <NoRecordsFound />
          : <div className=" shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <TableHeader />
              <TableBody />
            </table>
          </div>
        )
        }


      </div>
      {items.data && (
        <TablePagination items={items} filterCfg={filterCfg} setFilterCfg={setFilterCfg} />
      )}


    </>
  );
};

export default TableEngineTw;
