import { useState } from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function ModalContainer({
  title,
  open,
  setOpen,
  content,
  contentText,
  primaryButtonText,
  primaryButtonColor,
  IconComponent,
  handleSubmit,
  handleSecondarySubmit,
  secondaryButtonText,
}) {
  return (
    <Dialog className="relative z-10" open={open} onClose={setOpen}>
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className={
              `relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 ` +
              `sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95`
            }
          >
            <div className="sm:flex sm:items-start">
              {IconComponent != null && (
                <div
                  className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${primaryButtonColor}-100 sm:mx-0 sm:h-10 sm:w-10`}
                >
                  {icon}

                  <IconComponent
                    className={`h-6 w-6 text-${primaryButtonColor}-600`}
                    aria-hidden="true"
                  />
                </div>
              )}
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  {title}
                </DialogTitle>
                <div className="mt-2 w-full">
                  {contentText != null && (
                    <p className="text-sm text-gray-500 mb-6">{contentText}</p>
                  )}
                  {content}
                </div>
              </div>
            </div>

            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
              <button
                type="button"
                className={`inline-flex justify-center rounded-md bg-${primaryButtonColor}-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-${primaryButtonColor}-500 sm:ml-3 sm:w-auto`}
                onClick={handleSubmit}
              >
                {primaryButtonText}
              </button>
              <button
                type="button"
                className="mt-3 inline-flex justify-center px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                onClick={() => setOpen(false)}
                data-autofocus
              >
                Cancelar
              </button>
              {handleSecondarySubmit != null && secondaryButtonText != null && (
                <button
                  type="button"
                  className="mt-3 inline-flex justify-center px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                  onClick={handleSecondarySubmit}
                  data-autofocus
                >
                  {secondaryButtonText}
                </button>
              )}
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
