import { useState, useRef, useEffect } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import { Fragment } from "react";
import MessagingSenderTextBox from "./MessagingSenderTextBox";
import MessagingSenderEditorButtons from "./MessagingSenderEditorButtons";
import {
  submitCommunicationMessage,
  submitInternalMessage,
} from "./senderHelpers";
import usePostRequest from "../../hooks/usePostRequest";
import usePutRequest from "../../hooks/usePutRequest";

export default function MessagingReplyContainer({ selectedTopic, itemsInboxCategories }) {
  const [selectedDestination, setSelectedDestination] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const txtboxRef = useRef();

  const { doPost: doPostCommMessage, postR: postRCommMessage } =
    usePostRequest("communication");

  const { doPut: doPutMsgThreadAction, putR: putRMsgThreadAction } =
    usePutRequest("messagingthread");

  const handleClearTxt = () => {
    if (txtboxRef.current) {
      txtboxRef.current.clearContent();
    }
  };

  useEffect(() => {
    if (putRMsgThreadAction.state == 2) {
      handleClearTxt();
    }
  }, [putRMsgThreadAction]);

  useEffect(() => {
    if (postRCommMessage.state == 2) {
      handleClearTxt();
    }
  }, [postRCommMessage]);

  function handleSubmit() {
    const resValue = txtboxRef.current.getContent();
    const mentions = resValue.mentions

    console.log(resValue)

    if (selectedIndex == 0)
      submitCommunicationMessage(
        selectedTopic,
        selectedDestination,
        resValue.plainText,
        doPostCommMessage
      );

    if (selectedIndex == 1)
      submitInternalMessage(
        selectedTopic,
        resValue.plainText,
        doPutMsgThreadAction,
        mentions
      );
  }
  return (
    <>
      {typeof selectedTopic != "undefined" &&
        Object.keys(selectedTopic).length > 0 && (
          <div className="w-full border shadow h-full">
            <TabGroup
              as={Fragment}
              selectedIndex={selectedIndex}
              onChange={setSelectedIndex}
            >
              <TabList className="isolate inline-flex shadow-sm text-xs min-h-[30px]">
                <Tab className="relative inline-flex items-center px-3 py-2 font-semibold text-gray-900  hover:bg-gray-50 focus:z-10 data-[selected]:bg-white bg-gray-100">
                  Responder
                </Tab>
                <Tab className="relative inline-flex items-center px-3 py-2 font-semibold text-gray-900  hover:bg-gray-50 focus:z-10 data-[selected]:bg-yellow-100/50 bg-gray-100">
                  Nota Interna
                </Tab>
              </TabList>
              <TabPanels style={{ height: "calc(100% - 32px)" }}>
                <TabPanel className="h-full bg-white">
                  <div className="flex flex-col" style={{ height: "100%" }}>
                    <div className="flex-1 min-h-[10px] overflow-auto h-full p-1">
                      <MessagingSenderTextBox ref={txtboxRef} />
                    </div>
                    <div className="flex-none min-h-[20px]">
                      <MessagingSenderEditorButtons
                        mode={selectedIndex}
                        selectedTopic={selectedTopic}
                        selectedDestination={selectedDestination}
                        setSelectedDestination={setSelectedDestination}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  </div>
                </TabPanel>
                <TabPanel className="h-full bg-yellow-100/50">
                  <div className="flex flex-col" style={{ height: "100%" }}>
                    <div className="flex-1 min-h-[10px] overflow-auto h-full p-1">
                      <MessagingSenderTextBox ref={txtboxRef} itemsInboxCategories={itemsInboxCategories} />
                    </div>
                    <div className="flex-none min-h-[20px]">
                      <MessagingSenderEditorButtons
                        mode={selectedIndex}
                        selectedTopic={selectedTopic}
                        selectedDestination={selectedDestination}
                        setSelectedDestination={setSelectedDestination}
                        handleSubmit={handleSubmit}
                      />
                    </div>
                  </div>
                </TabPanel>
              </TabPanels>
            </TabGroup>
          </div>
        )}
    </>
  );
}
