import React, { useContext } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { Fragment } from "react";
import { GlobalStateContext } from "../../state/globalState";

export default function TableActionMenu(props) {
  const globalcontext = useContext(GlobalStateContext);

  function handleOpenCustomSlideover(item) {
    props.slideOver(props.row.id, item.slideover.template);
  }

  return (
    <div>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="inline-flex w-full justify-center rounded-md bg-black/20 px-4 py-2 text-sm font-medium text-white hover:bg-black/30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
            {props.optionsText}
            <ChevronDownIcon
              className="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
              aria-hidden="true"
            />
          </Menu.Button>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none font-light">
            <div className="px-1 py-1">
              {props.items.map((i, k) => (
                <Menu.Item key={k}>
                  {({ active }) => (
                    <div className="cursor-pointer">
                      {i.onclick == null && i.href == null && "action" in i && (
                        <div
                          onClick={(e) =>
                            i.action == "@edit"
                              ? props.editButton()
                              : i.action == "@slideover"
                              ? handleOpenCustomSlideover(i)
                              : console.log("2")
                          }
                          className={`${
                            active
                              ? "bg-violet-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {"icon" in i && (
                            <i.icon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}

                          {i.label}
                        </div>
                      )}

                      {i.onclick == null && i.href != null && (
                        <Link
                          to={i.href(props.row)}
                          className={`${
                            active
                              ? "bg-violet-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          <i.icon className="mr-2 h-5 w-5" aria-hidden="true" />
                          {i.label}
                        </Link>
                      )}

                      {i.onclick != null && i.href == null && (
                        <div
                          onClick={(e) =>
                            i.onclick(e.target, props.row, globalcontext)
                          }
                          className={`${
                            active
                              ? "bg-violet-500 text-white"
                              : "text-gray-900"
                          } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                        >
                          {"icon" in i && (
                            <i.icon
                              className="mr-2 h-5 w-5"
                              aria-hidden="true"
                            />
                          )}

                          {i.label}
                        </div>
                      )}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

function DeleteInactiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#A78BFA" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#A78BFA" strokeWidth="2" />
    </svg>
  );
}

function DeleteActiveIcon(props) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5"
        y="6"
        width="10"
        height="10"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
      <path d="M3 6H17" stroke="#C4B5FD" strokeWidth="2" />
      <path d="M8 6V4H12V6" stroke="#C4B5FD" strokeWidth="2" />
    </svg>
  );
}
