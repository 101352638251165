

export const tableconfig = {
  edit: {
    showEditButton: false,
    type: 'slideover',
  },
  view: {
    title: 'Informações',
    description: '',
    type: 'slideover',
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: 'id',
          label: 'ID',
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: 'name',
          label: 'Nome',
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: 'booking_date',
      slideoverView: true,
      label: 'Data',
    },
    {
      key: 'booking_type',
      slideoverView: true,
      label: 'Tipo',
    },
    {
      key: 'center_name',
      slideoverView: true,
      label: 'Centro',
    },
    {
      key: 'provider_name',
      slideoverView: true,
      label: 'Médico',
    },
    {
      key: 'servt_name',
      slideoverView: true,
      label: 'Especialidade',
    },
    {
      key: 'subservt_name',
      slideoverView: true,
      label: 'Subespecialidade',
    },
    {
      key: 'patient_file',
      slideoverView: true,
      label: 'Paciente',
      valueformat: (e) => {
        return `${e.firstname} ${e.lastname}`;
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [
      
    ],
  },
};
