
  
  export const workflowComponents = [
    {
      id: 'send-sms',
      name: 'Send SMS',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'message', type: 'textarea', label: 'Message', placeholder: 'Enter your SMS message' },
      ],
    },
/*     {
      id: 'send-email',
      name: 'Send Email',
      inputs: [
        { name: 'to', type: 'email', label: 'To', placeholder: 'recipient@example.com' },
        { name: 'subject', type: 'text', label: 'Subject', placeholder: 'Email Subject' },
        { name: 'body', type: 'textarea', label: 'Body', placeholder: 'Enter your email content' },
      ],
    }, */
   /*  {
      id: 'simple-phone-call',
      name: 'Make Simple Phone Call',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'message', type: 'textarea', label: 'Message to Read', placeholder: 'Enter the message to be read during the call' },
      ],
    }, */
    {
      id: 'ivr-phone-call',
      name: 'Make Phone Call with IVR',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' },
        { name: 'welcomeMessage', type: 'textarea', label: 'Welcome Message', placeholder: 'Welcome to our IVR system' },
        { name: 'options', type: 'textarea', label: 'IVR Options', placeholder: 'Enter options as JSON: [{"digit": "1", "message": "Press 1 for sales"}, ...]' },
      ],
    },
    {
      id: 'ivr-phone-call-recording',
      name: 'Make Phone Call with IVR and Play Recording',
      inputs: [
        { name: 'phoneNumber', type: 'tel', label: 'Phone Number', placeholder: '+1234567890' }
      ],
    },
    {
      id: 'obtain-events',
      name: 'Obtain the events in real-time',
      inputs: [
        { name: 'date', type: 'date', label: 'Date', placeholder: '23-12-2024' }
      ],
    },
    {
      id: 'change-appointment-status',
      name: 'Change Appointment Status',
      inputs: [
        { name: 'appointment', type: 'number', label: 'Appointment', placeholder: '322' },
        { name: 'status', type: 'text', label: 'Status', placeholder: 'A' },
      ],
    },
    {
      id: 'execute-workflow',
      name: 'Execute Workflow',
      inputs: [
        { name: 'trigger_id', type: 'text', label: 'Trigger ID', placeholder: '' }
      ],
    },
  ];
  
  