import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Checkbox } from "@/components/ui/checkbox";
import useGetRequest from "../../hooks/useGetRequest";
import { useEffect } from "react";

const triggerSchema = z.object({
  name: z.string().min(1, "Name is required"),
  trigger_type: z.enum(["time_based", "event_date_based"]),
  trigger_time: z
    .string()
    .regex(/^([01]\d|2[0-3]):([0-5]\d)$/, "Invalid time format"),
  relative_days: z.number().int().optional(),
  workflow_id: z.string().min(1, "Workflow is required"),
  active: z.boolean(),
  weekday: z.array(z.enum(["mon", "tue", "wed", "thu", "fri", "sat", "sun"])),
});

export default function TriggersForm({ onSubmit, initialData = null }) {
  const {
    doGet: doGetWfs,
    items: wfsItems,
    isComplete: isCompleteWfs,
  } = useGetRequest("automationWorkflows");

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue, // Add this
    formState: { errors },
  } = useForm({
    resolver: zodResolver(triggerSchema),
    defaultValues: initialData || {
      name: "",
      trigger_type: "time_based",
      trigger_time: "",
      relative_days: 0,
      workflow_id: "",
      active: true,
      weekday: [],
    },
  });

  const triggerType = watch("trigger_type");

  useEffect(() => {
    doGetWfs();
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
        <Input {...register("name")} placeholder="Trigger Name" />
        {errors.name && (
          <p className="text-red-500 text-sm">{errors.name.message}</p>
        )}
      </div>
      <div>
        {/*  Registering value */}
        <Select onValueChange={(value) => setValue("trigger_type", value)}>
          <SelectTrigger>
            <SelectValue placeholder="Select trigger type" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="time_based">Time Based</SelectItem>
            <SelectItem value="event_date_based">Event Date Based</SelectItem>
          </SelectContent>
        </Select>
        {errors.trigger_type && (
          <p className="text-red-500 text-sm">{errors.trigger_type.message}</p>
        )}
      </div>
      <div>
        <Input {...register("trigger_time")} type="time" />
        {errors.trigger_time && (
          <p className="text-red-500 text-sm">{errors.trigger_time.message}</p>
        )}
      </div>
      {triggerType === "event_date_based" && (
        <div>
          <Input
            {...register("relative_days", { valueAsNumber: true })}
            type="number"
            placeholder="Relative Days"
          />
          {errors.relative_days && (
            <p className="text-red-500 text-sm">
              {errors.relative_days.message}
            </p>
          )}
        </div>
      )}
      <div>
        <Select onValueChange={(value) => setValue("workflow_id", value)}>
          <SelectTrigger>
            <SelectValue placeholder="Select workflow" />
          </SelectTrigger>
          <SelectContent>
            {isCompleteWfs &&
              wfsItems.data.map((workflow) => (
                <SelectItem key={workflow.id} value={workflow.id}>
                  {workflow.name}
                </SelectItem>
              ))}
          </SelectContent>
        </Select>
        {errors.workflow_id && (
          <p className="text-red-500 text-sm">{errors.workflow_id.message}</p>
        )}
      </div>
      <div>
        <label className="flex items-center space-x-2">
          <Controller
            name="active"
            control={control}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            )}
          />
          <span>Active</span>
        </label>
      </div>
      <div>
        <p className="mb-2">Weekdays</p>
        <div className="flex flex-wrap gap-2">
          {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map((day) => (
            <label key={day} className="flex items-center space-x-2">
              <Controller
                name="weekday"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    checked={field.value.includes(day)}
                    onCheckedChange={(checked) => {
                      const updatedWeekdays = checked
                        ? [...field.value, day]
                        : field.value.filter((d) => d !== day);
                      field.onChange(updatedWeekdays);
                    }}
                  />
                )}
              />
              <span className="capitalize">{day}</span>
            </label>
          ))}
        </div>
      </div>
      <Button type="submit">Save Trigger</Button>
    </form>
  );
}
