import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button";
import { Play, Pause, FileAudio, User } from "lucide-react";
import { formatDistanceToNow } from "date-fns";
import useGetRequest from "../../hooks/useGetRequest";

export default function IvrFileList() {
  const [playingId, setPlayingId] = useState(null);
  const audioRef = useRef(null); // Ref to manage the audio element

  const { doGet, items, isComplete } = useGetRequest("file");

  useEffect(() => {
    doGet({
      file_list: "IVR_TRACK",
    });
  }, []);

  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const togglePlay = (id) => {
    const s3PresignedAudioUrl = items.data.find(
      (i) => i.name === id
    ).presigned_url_download;

    if (playingId === id) {
      // If the same file is playing, stop it
      audioRef.current.pause();
      setPlayingId(null);
    } else {
      // If a different file is clicked, play it
      if (audioRef.current) {
        audioRef.current.pause(); // Pause any currently playing audio
      }

      // Create a new audio element and play
      const audio = new Audio(s3PresignedAudioUrl);
      audioRef.current = audio;
      audio.play();
      setPlayingId(id);

      // When the audio ends, reset playingId
      audio.addEventListener("ended", () => {
        setPlayingId(null);
      });
    }
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[50px]"></TableHead>
          <TableHead>Name</TableHead>
          <TableHead>Duration</TableHead>
          <TableHead>Changed</TableHead>
          <TableHead>User</TableHead>
          <TableHead>Size</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isComplete &&
          items.data.map((file) => (
            <TableRow key={file.name}>
              <TableCell>
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={() => togglePlay(file.name)}
                  aria-label={playingId === file.name ? "Pause" : "Play"}
                >
                  {playingId === file.name ? (
                    <Pause className="h-4 w-4" />
                  ) : (
                    <Play className="h-4 w-4" />
                  )}
                </Button>
              </TableCell>
              <TableCell className="font-medium">
                <div className="flex items-center">
                  <FileAudio className="h-4 w-4 mr-2" />
                  {file.filename}
                </div>
              </TableCell>
              <TableCell>{formatDuration(file.media_length_secs)}</TableCell>
              <TableCell>
                {formatDistanceToNow(new Date(file.last_modification), {
                  addSuffix: true,
                })}
              </TableCell>
              <TableCell>
                <div className="flex items-center truncate">
                  <User className="h-4 w-4 mr-2" />
                  {file.created_by_user?.fullname}
                </div>
              </TableCell>
              <TableCell>{file.human_readable_size}</TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}
