import * as React from "react"

function LookingForIllustration(props) {
  return (
    <svg
      width={'100%'}
      height={315.25333}
      viewBox="0 0 1000 315.25333"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillOpacity={1} fillRule="nonzero" stroke="none">
        <path
          d="M0 0h7500v2364.4H0z"
          fill="#ffffff"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1952.24 1.16l.27 3.75-169.43-3.129.01-.62h169.15M2290.65 1.16l-11.82 389.68-222.78-40.789 50.2-348.89h184.4"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2008.25 1.16c10.8 97.488 21.14 210.84 21.14 210.84s7.81-112.91 16.89-210.84h289.46c-7.97 55.41-17.13 123.09-22.86 189.969-12.62 147.062-26.32 290.191-26.32 290.191l-552.5-1.609s-10.24-295.781-10.71-478.55h284.9"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2029.39 209.891h-.2l.2 2.109s.05-.73.14-2.109h-.14"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2029.53 209.891c-.09 1.379-.14 2.109-.14 2.109l-.2-2.109a2.112 2.112 0 00-1.91 2.308l8.89 94.129a2.114 2.114 0 002.11 1.91c.06 0 .13 0 .19-.008a2.11 2.11 0 001.91-2.3l-8.89-94.129a2.109 2.109 0 00-1.96-1.91"
          fill="#bf9d47"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2066.76 1616.03c6.11 13.78 11.46 27.94 16.38 42.18 4.41 12.75 8.43 25.68 11.56 38.81 3.1 12.98 9.74 24.85 7.65 38.62-1.03 6.79-3.6 13.22-6.15 19.58-6.35 15.82-12.7 31.64-19.04 47.45-3.24 8.08-7.02 16.77-14.65 20.95-5.58 3.06-12.26 3.11-18.63 3.24-13.37.27-26.89.5-39.32 4.9-39.27 13.89-86.21 8.7-115.87-23.36-1.41-1.52-2.8-3.11-4.61-4.12-2.05-1.13-4.43-1.4-6.72-1.87-16.59-3.47-28.79-18.53-33.78-34.73-7.99-25.9-3.31-57.27-1.64-83.93.9-14.2 1.75-28.91 4.48-42.9 2.1-10.75 1.82-10.57 11.64-12.37 38.14-6.97 80.33-4.79 119.01-7.1 29.9-1.78 59.79-3.57 89.69-5.35"
          fill="#9b7645"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2029.23 1573.56s-6.12-105.98-.89-115.92c5.23-9.93 41.31-11.5 41.31-11.5l-66.46-158.58-137.75 154.39s12.82 7.85 19.09 24.06c6.28 16.21 2.33 99.45 2.33 99.45l142.37 8.1"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1860.92 1604.88c0-52.65 105.6-120.73 146.91-120.4 41.31.33 65.35 26.52 77.77 122.21 16.86 129.79-24.9 197.12-113.6 197.12-88.69 0-111.08-70.5-111.08-198.93"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2007.53 1482.36c-18.97 0-50.84 13.55-81.35 34.61-.96.67-1.2 1.98-.53 2.94.66.96 1.97 1.2 2.94.54 29.54-20.4 61.43-33.99 79.23-33.86 5.98.05 11.28.64 16.2 1.8a2.11 2.11 0 002.54-1.57c.27-1.14-.43-2.27-1.57-2.54-5.23-1.24-10.84-1.87-17.14-1.91l-.32-.01"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1971.99 1804.31c83.29 0 121.49-13.33 117.23-127.02 0 0-2.04 58.54-32.77 65.15-26.08 5.61-32.67-2.33-51.73-1.94-19.06.39-41.37 9.25-59.51 2.72-19.45-7-28.78-45.9-34.23-68.07-5.45-22.17-24.89-45.31-24.89-61.46l-26.78 30.12c-2 111.02 29.91 160.5 112.68 160.5"
          fill="#9b7645"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1844.69 1598.55c6.79-20.64 22.24-34.09 34.53-30.06 12.28 4.04 16.74 24.04 9.96 44.67-6.79 20.64-16.06 35.68-28.35 31.64-12.28-4.03-22.92-25.62-16.14-46.25"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1996.13 1638.36c-.06 7.32-3.79 13.23-8.34 13.19-4.55-.04-8.19-6-8.13-13.33.06-7.32 3.8-13.23 8.35-13.19 4.54.04 8.18 6 8.12 13.33M2079.85 1638.36c-.06 7.32-3.79 13.23-8.34 13.19-4.55-.04-8.19-6-8.13-13.33.06-7.32 3.8-13.23 8.34-13.19 4.55.04 8.19 6 8.13 13.33"
          fill="#3b2413"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2029.78 1549.27c-5.97 0-12.11 1.66-17.68 4.99-1 .6-1.33 1.89-.73 2.9.6 1 1.9 1.32 2.9.73 9.76-5.83 21.46-5.86 29.8-.08.96.66 2.28.42 2.94-.53.67-.96.43-2.28-.53-2.94-4.87-3.38-10.69-5.07-16.7-5.07M2035.84 1584.45c-.92 0-1.77.6-2.03 1.53-.32 1.13.33 2.29 1.45 2.61.06.02 7.1 2.12 8.63 6.92 4.56 14.36-10.58 51.23-10.73 51.6-.45 1.08.06 2.32 1.14 2.77 1.08.44 2.32-.07 2.76-1.15.65-1.57 15.88-38.68 10.86-54.5-2.22-6.97-11.12-9.59-11.5-9.7l-.58-.08"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1963.23 1662.8l2.11.29 2.29.35c1.53.24 3.06.46 4.59.66 3.06.36 6.11.71 9.17.91 3.06.17 6.12.37 9.23.4 3.11.11 6.23.03 9.52.26l.08.01c2.83.2 5.28 2.37 5.45 5.2.15 2.48-1.32 4.6-3.39 5.47a39.979 39.979 0 01-10.87 2.84c-3.73.54-7.54.47-11.3.12a47.41 47.41 0 01-11.08-2.49c-1.8-.6-3.55-1.4-5.27-2.25l-2.52-1.46-2.3-1.62c-1.93-1.48-2.7-4.2-1.48-6.3a4.74 4.74 0 014.1-2.39h1.67M2076.54 1663.55c-3.07.18-6.13.41-9.23.47-3.11.13-6.23.07-9.52.33l-.08.01c-2.82.22-5.26 2.41-5.41 5.24a5.553 5.553 0 003.44 5.45c3.47 1.42 7.14 2.35 10.88 2.75 3.74.52 7.54.41 11.31.04 2.17-.25 8.39-.65 10.52-1.18.34-4.57.61-9.22.77-14.03-2.88.36-9.8.71-12.68.92"
          fill="#914837"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2169.01 1678.32l-12.03-4.18-4.7-65.89 35.03-4.18s25.63 31.37 25.1 34.51c-.52 3.14-30.33 49.68-30.33 49.68l-13.07-9.94"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2272.84 1600.64c-7.41 8.6-23.42 28.75-30.53 39.43-7.12 10.67-24.61 29.34-32.61 34.98-8 5.63-31.72 26.38-37.94 26.97-6.23.59-59.95.16-72.03-5.34-6.53-2.96-5.04-6.81-.89-9.48 4.15-2.67 14.82-4.75 21.93-1.49 0 0 10.67-2.07 24.61 0 0 0 26.75-15.85 41.87-20.75 15.11-4.89 5.78-53.35-10.23-53.35s-22.9 2-30.01 5.11c-7.12 3.12-20.24 4.45-31.35.45-11.12-4.01-12.45-12.01-10.67-14.9 1.78-2.89 2.44-5.78 13.34-2.67 10.89 3.11 31.57-9.33 39.35-14 7.78-4.67 28.45-12.23 45.13-9.34 16.67 2.89 28.68-19.56 28.68-19.56l41.35 43.94"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2212.62 1668.59l-.6.15c-6.71 3.8-18.38 10.43-21.04 12.1-2.42 1.53-13.33 5.03-29.93 9.61l-.14.03c-2.07.58-4.21 2.2-6.28 3.77-2.42 1.83-4.94 3.71-6.71 3.47-1.31-.2-2.19-.74-2.69-1.67-1.46-2.7.4-8.01 1.24-9.83l-.59-1.6-1.6.59c-.14.31-3.49 7.68-1.17 11.99.88 1.61 2.38 2.59 4.46 2.9 2.78.41 5.69-1.8 8.51-3.93 1.89-1.44 3.85-2.92 5.47-3.37l.14-.03c12.07-3.33 27.17-7.75 30.57-9.89 2.62-1.65 14.25-8.25 20.95-12.04l.45-1.64-1.04-.61"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2120.77 1684.51l-.48.1c-.2.09-4.86 2.17-6.21 6.07-.64 1.84-.44 3.76.59 5.68 1.61 3.02 7.23 2.95 14.99 2.86 5.55-.07 11.85-.14 17.89.87l1.38-.98-.98-1.39c-6.25-1.05-12.66-.98-18.32-.91-5.85.07-11.91.14-12.84-1.58-.71-1.33-.85-2.55-.44-3.76 1-2.9 4.87-4.63 4.91-4.65l.61-1.59-1.1-.72"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1718.23 1151.2c-.01 0 1.98-113.3 1.51-121.13-3.99-65.41 13.67-130.39 6.21-197.211-1.76-15.738-4.26-31.437-4.79-47.269-1.19-36.121 7.93-71.731 10.94-107.75 3.21-38.309-.58-76.731-6.86-114.531-3.66-22.11-20.82-61.61-10.17-83.387 16.88-34.551 73.11-27.473 119.02-20.391 19.64 3.028 37.39 6.059 49.37 5.828 64.4-1.23 118.13-28.711 207.96-29.937 87.98-1.211 199.75 29.117 204.26 36.906 3.09 5.352-1.12 20.871-1.65 26.524-1.74 18.558-1.93 35.91-6.59 53.898-11.18 43.09-20.7 86.762-21.81 131.441-.81 32.418-1.54 59.61-7.33 91.399-5.94 32.648-14.61 64.781-19.16 97.68-3.23 23.359-4.35 46.941-5.47 70.492-2.54 53.508 2.85 111.878-8.38 163.888-1.35 6.21-4.24 82.66-7.12 83.29.41-.09 130.48-28.73 211.26-25.1 64.08 2.88 81.34 30.65 89.43 76.87 9.76 55.79-56.48 10.98-144.33 72.17-72 50.14-112.14 97.02-200.81 112.96-56.74 10.19-104.07 18.3-104.07 18.3l-54.39-119.23-149.82 115.04s-88.76-8.65-148.25-42.36c-70.01-39.67-261.99-100.4-251.01-174.13 16.7-112.13 251.15-74.41 252.05-74.26"
          fill="#415c55"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2272.84 1600.64l-41.35-43.94s38.24-148.19 80.49-233.58c42.24-85.4 66.54-145.3 117.45-157.28 53.34-12.55 93.34 28.37 91 89.42-2.35 61.06-247.59 345.38-247.59 345.38M1882.94 1554.13l-41.79 35.97s-147.97-113.1-217.73-177.99c-69.76-64.89-172.35-143.7-159.56-194.42 12.79-50.72 75.34-84.71 127.82-53.42 81.58 48.64 291.26 389.86 291.26 389.86"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1997.2 1690.81s-17.56-3.59-32.34-8.36c-14.29-4.6-37.33-14.75-37.33-30.65 0-18.43 10.6-26.27 25.46-33.18 14.87-6.92 34.69-13.72 56.81-14.12l-12.6 86.31"
          fill="#18282d"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2013.78 1682.74s57.9 8.67 104.23-4.39l-6.92-58.62s-51.05 15.22-78.36 0l-18.95 63.01"
          fill="#2e3e44"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2135.64 1693.58l-26.18-7.59c-18.39-5.33-30.79-22.86-29.03-41.92.65-6.98 3.15-13.89 8.88-19.62l32.75-16.73 13.58 85.86"
          fill="#18282d"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2046.08 1649.04c0-24.6-16.24-44.54-36.28-44.54-20.03 0-36.27 19.94-36.27 44.54 0 24.6 16.24 44.54 36.27 44.54 20.04 0 36.28-19.94 36.28-44.54"
          fill="#3e4e56"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1977.5 1649.04c0 23.78 15.18 43.2 34.29 44.47l-1.99.07c-20.03 0-36.27-19.94-36.27-44.54 0-24.6 16.24-44.54 36.27-44.54l1.99.07c-19.11 1.27-34.29 20.69-34.29 44.47"
          fill="#2e3e44"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2171.91 1649.04c0-24.6-16.24-44.54-36.27-44.54-20.04 0-36.28 19.94-36.28 44.54 0 24.6 16.24 44.54 36.28 44.54 20.03 0 36.27-19.94 36.27-44.54"
          fill="#3e4e56"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2103.51 1649.04c0 23.74 15.13 43.14 34.2 44.46l-2.07.08c-20.04 0-36.28-19.94-36.28-44.54 0-24.6 16.24-44.54 36.28-44.54l2.07.07c-19.07 1.33-34.2 20.73-34.2 44.47"
          fill="#2e3e44"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2039.1 1649.04c0-18.52-12.23-33.53-27.31-33.53-15.08 0-27.31 15.01-27.31 33.53 0 18.52 12.23 33.53 27.31 33.53 15.08 0 27.31-15.01 27.31-33.53M2165.02 1649.04c0-18.52-12.23-33.53-27.31-33.53-15.08 0-27.31 15.01-27.31 33.53 0 18.52 12.23 33.53 27.31 33.53 15.08 0 27.31-15.01 27.31-33.53"
          fill="#1d2c35"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1841.15 1590.1c5.91 8.52 18.52 28.38 23.96 38.76 5.44 10.37 19.55 29.03 26.28 34.91 6.73 5.89 26.29 27.02 31.89 28.16 5.6 1.13 61.59 12.94 73.43 10.34 6.4-1.4 5.81-5.13 2.61-8.3-3.2-3.18-12.33-7.08-19.32-5.52 0 0-9.13-3.9-21.98-4.7 0 0-31.99-21.09-45.26-26.98-13.27-5.9-.13-49.05 14.42-47.51 14.54 1.53 21.95 1.05 28.12 4.56 6.17 3.51 17.96 5.98 28.44 3.41 10.49-2.57 12.47-9.71 11.13-12.51-1.34-2.8-1.67-5.49-11.87-3.7-10.19 1.78-29.13-8.56-35.75-13.55-6.62-4.99-24.69-13.84-40.12-12.81-15.43 1.02-24.19-20.53-24.19-20.53l-41.79 35.97"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1885.05 1556.32l-.95.47.22 1.68 22.07 17.14.82.25c3.15-.21 6.6 0 10.26.62 12.47 2.11 24.21 8.31 29.06 11.96 5.57 4.2 25.42 15.75 36.68 13.76 8.52-1.49 9.25.14 10.28 2.39l.3.65c.34.72.44 2.22-.42 4.05-.87 1.83-3.31 5.16-9.91 6.77-10 2.45-21.44.2-27.57-3.29-4.78-2.72-10.26-3.16-18.55-3.81-2.93-.24-6.24-.5-10.04-.9-8.76-.94-16.22 11.65-19.46 23.72-2.66 9.94-3.1 22.74 4.44 26.09 13.02 5.79 44.76 26.67 45.08 26.88l.58.2c12.47.78 21.5 4.57 21.59 4.61l.74.07c6.37-1.42 15.18 2.18 18.21 5.19 1.5 1.5 2.2 2.94 1.91 3.95-.3 1.01-1.69 1.84-3.93 2.33-7.06 1.55-32.48-2.03-71.57-10.07l-1.37-.28c-3.6-.73-15.1-12.09-22.71-19.6-3.55-3.5-6.62-6.53-8.63-8.28-6.66-5.83-20.76-24.57-26-34.57-5.44-10.39-18.07-30.28-24.04-38.89l-1.68-.3-.3 1.67c5.93 8.56 18.49 28.33 23.89 38.63 5.45 10.4 19.59 29.19 26.55 35.27 1.95 1.7 4.99 4.71 8.52 8.19 11.12 10.97 19.94 19.43 23.92 20.24l1.36.27c27.89 5.74 63.11 12.14 72.57 10.07 3.23-.71 5.16-2.06 5.73-4 .55-1.91-.34-4.16-2.53-6.34-3.5-3.48-12.75-7.34-20.05-5.91-1.82-.73-10.23-3.86-21.71-4.63-3.31-2.19-32.44-21.25-45.16-26.91-5.75-2.55-5.69-13.52-3.08-23.25 2.99-11.19 9.9-22.68 16.88-21.96 3.82.41 7.16.67 10.09.91 8.26.65 13.24 1.05 17.56 3.51 6.55 3.72 18.72 6.13 29.33 3.53 7.57-1.85 10.46-5.86 11.51-8.08 1.04-2.2 1.2-4.49.42-6.12l-.28-.6c-1.53-3.37-3.25-5.46-12.88-3.77-9.9 1.72-28.67-8.68-34.83-13.32-5.03-3.79-17.21-10.23-30.1-12.41-3.67-.62-7.15-.84-10.36-.67l-21.72-16.86-.74-.25"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1882.62 1652.79l-.98.51.28 1.67c.21.16 21.17 15.05 24.5 17.68 2.17 1.71 12.61 7.42 31.02 16.98l1.94 1.02c4.1 2.15 10.3 5.4 13.92 5.63 1.92.12 3.53-.49 4.63-1.78 2.88-3.37 1.44-10.25 1.26-11.02l-1.43-.91-.92 1.44c.4 1.78 1.04 6.84-.75 8.93-.6.71-1.46 1.02-2.64.94-3.11-.2-9.27-3.43-12.95-5.36l-1.95-1.02c-22.76-11.83-29.22-15.63-30.64-16.74-3.38-2.67-23.73-17.13-24.6-17.75l-.69-.22M1980.01 1687.25l-1.08.66.54 1.62c.9.45 3.32 2.03 3.71 3.93.19.94-.14 1.9-.99 2.91l.14 1.7 1.69-.14c1.36-1.6 1.86-3.27 1.51-4.97-.71-3.43-4.82-5.5-4.99-5.59l-.53-.12"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2218.96 1188.98c-.27 1.21-.53 1.9-.79 1.96.03 0 .74-.16 2.06-.44-.14-.68-.62-1.26-1.27-1.52"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2218.16 1188.83c-1.04 0-1.94.77-2.09 1.83-.08.61-8.43 61.55-14.63 97.19a2.11 2.11 0 002.09 2.47c1.01 0 1.9-.72 2.08-1.75 6.21-35.71 14.57-96.73 14.65-97.34.04-.25.02-.5-.03-.73-1.32.28-2.03.44-2.06.44.26-.06.52-.75.79-1.96a2.12 2.12 0 00-.51-.13l-.29-.02"
          fill="#314540"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1718.23 1149.08c-.95 0-1.81.65-2.05 1.61-.01.07-.02.13-.03.19 1.32.2 2.03.31 2.08.32 0 0 .01-.74.04-2.11l-.04-.01"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1718.27 1149.09c-.03 1.37-.04 2.11-.04 2.11-.05-.01-.76-.12-2.08-.32-.17 1.07.51 2.11 1.58 2.37l72.17 17.78c.17.04.34.06.51.06.95 0 1.81-.64 2.04-1.61.28-1.13-.41-2.27-1.54-2.55l-72.17-17.78-.47-.06M2078.38 523.969c-25.26 0-50.46 2.371-75.07 7.16-6.26 1.211-12.64 2.609-18.8 3.961-18.69 4.082-37.86 8.269-56.84 8.269-6.23 0-12.44-.461-18.6-1.507a2.112 2.112 0 00-2.44 1.726 2.11 2.11 0 001.73 2.442c6.43 1.101 12.88 1.57 19.34 1.57 19.39 0 38.79-4.238 57.71-8.379 6.14-1.34 12.49-2.731 18.7-3.93 24.35-4.742 49.29-7.09 74.28-7.09 49.94 0 100.09 9.368 146.16 27.7a2.118 2.118 0 002.75-1.18 2.126 2.126 0 00-1.18-2.75c-46.57-18.52-97.26-27.992-147.74-27.992"
          fill="#314540"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2879.22 1414.16s11.89 77.8 21.38 83.45c9.49 5.66 46.44 4.12 53.15 14.6 6.71 10.47-5.87 67.89-4.84 72.79 1.02 4.91 14.4 8.58 14.94 18.39.53 9.8-27.54 39.78-35.66 57.87-2.85 6.35-2.89 17.04-6.13 27.45-5.66 18.25-8.72 39.58-22.53 55.21-19.96 22.6-71.86 37.87-130.31 23.42-45.96-11.37-78.11-49.06-81.47-79.8-3.37-30.75 11.66-117.22 33.82-139.1 22.17-21.89 37.15-59.94 41.65-79.18 4.49-19.24-.18-76.98-.18-76.98l116.18 21.88"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2921.01 1644.32c-1.08 7.15-5.57 12.4-10.01 11.73-4.44-.68-7.16-7.03-6.07-14.18 1.08-7.15 5.57-12.41 10.01-11.73 4.44.68 7.16 7.02 6.07 14.18"
          fill="#3b2413"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2880.74 1659.68l2.04 1.02 2.19 1.14c1.46.77 2.94 1.51 4.41 2.23 2.98 1.42 5.93 2.82 8.96 4.06 3.04 1.21 6.06 2.47 9.19 3.57 3.11 1.16 6.29 2.15 9.53 3.51l.08.04c2.79 1.17 4.52 4.2 3.73 7.11-.7 2.57-2.9 4.2-5.3 4.38-3.99.28-8.02 0-11.95-.85-3.96-.73-7.78-2.1-11.47-3.75-3.67-1.68-7.16-3.78-10.34-6.3-1.62-1.23-3.11-2.63-4.55-4.08l-2.05-2.34c-.62-.76-1.13-1.46-1.77-2.42-1.45-2.16-1.29-5.16.66-6.87a5.042 5.042 0 014.95-1.02l1.69.57"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2950.44 1571.54c-5.11-.78-18.07-.02-21.34-2.08-3.27-2.05 1.33-9.7 23.33-11.57-.69 5.04-1.32 9.2-1.99 13.65"
          fill="#fff"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2782.48 1585.45c10.49-16.38 26.1-16.72 33.96-11.27 7.47 5.18 9.85 21.73-.82 38.77-10.1 16.13-23.48 23.74-35.13 16.86-13.52-7.99-8.26-28.33 1.99-44.36"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3250.64 1810.95s4.03 10.56 13.64 7.3c0 0 6.55 11.69 13.79 11 7.25-.68 7.66-5.63 7.66-5.63s.86 7.18 6.85 8.65c5.98 1.46 8.3-3.39 8.3-3.39s1.2 5.07 6.94 6.43c5.73 1.35 7.08-7.4 7.08-7.4l-64.47-21.89.21 4.93M2793.34 1248.71s469.84 15.41 512.16 48.64c42.52 33.39 57.3 316.24 51.25 376.12l-58.89-11.58c-7.03-50.4-48.1-169.57-52.66-277.82l-415.8 16.73-36.06-152.09"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2799.04 914.02c34.73-131.309 7.24-197.36 16.5-261.79 8.92-62.109 8.75-46.312 8.75-46.312l352.07-34.43s-.28 60.723-26.94 198.473c-28.32 146.269-71.33 213.23-80.85 275.779-9.52 62.56-1.45 104.35-10.93 156.93-12.58 69.74-102.61 123.04-127.86 152.53-24.76 28.93-49.19 62.47-49.19 62.47-30.65 7.7-76.79 28.27-109.97 41.45 0 0-83.5-49.66-85.92-190.66-1.69-98.51 82.43-233.77 114.34-354.44"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2810.79 1326.28c-62.97-30.62-58.29-120.86-5.88-141.45 64.4-25.29 456.61 102.7 476.62 146.76 20.01 44.07-74.67 317.98-74.67 317.98l-53.1-4.11 21.43-253.14s-293.96-31.79-364.4-66.04"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2793.27 1548.41c-5.13 33.52-14.38 58.41-14.38 58.41 15.11 8.97 29.41 18.78 44.78 27.35 10.68 5.95 21.71 12.22 28.82 22.16 8.95 12.51 10.68 29.52 21.23 40.72 3.63 3.85 8.12 6.79 11.92 10.48 9.24 8.97 14.37 23.6 13.89 36.39-.18 5.12-10.55 26.71-29.35 36.99-20.92 11.43-67.36 22.02-121.37 6.33-54.01-15.69-79.5-56.42-85.98-118.09-2.24-21.36-1.36-39.79 1.51-56.52 3.29-17.11-.49-30.11-1.68-46.55-1.36-18.86 7.64-36.67 7-55.49-.64-18.77-11.02-35.79-14.71-54.2-2.67-13.38-1.76-27.18-.83-40.79 2.24-32.8 4.47-65.61 6.71-98.41.48-7.13 1.02-14.48 4.19-20.88 3.17-6.41 9.66-11.73 16.8-11.28-1.85 26.75 17.82 33.69 34.4 48.18 8.34 7.29 18.97 11.46 26.95 19.43 15.38 15.37 18.05 39.16 28.9 58.01 4.35 7.54 10 14.26 14.5 21.71 7.69 12.74 11.78 27.59 11.92 42.46.06 6.72-2.56 13.83-2.24 20.29.43 8.47 4.37 15.68 2.98 24.57-.21 1.36-3.61 13.71-4.6 18.51l-.22 1.92c-.35 2.81-.73 5.59-1.14 8.3"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3580.57 1840.49l-598.77-201.53-19.89 59.1 598.77 201.53c1.43.48 2.99-.29 3.47-1.72l18.14-53.91c.49-1.43-.28-2.99-1.72-3.47"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3167.96 1701.61l409.02 137.68-19.89 59.09-409.03-137.67 19.9-59.1"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3582.43 1834.96l-403.83-135.92c-1.44-.49-2.99.28-3.47 1.72l-21.87 64.97c-.48 1.43.29 2.99 1.72 3.47l403.84 135.92c1.43.49 2.98-.29 3.47-1.72l21.86-64.97c.49-1.43-.28-2.99-1.72-3.47"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3258.71 1726l320.03 107.72-23.61 70.16-320.03-107.72 23.61-70.16"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3584.03 1830.21l-314.84-105.96c-1.43-.48-2.98.29-3.47 1.72l-25.06 74.46c-.48 1.43.29 2.99 1.72 3.47l314.84 105.97c1.43.48 2.99-.29 3.47-1.72l25.06-74.47c.48-1.43-.29-2.98-1.72-3.47"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3349.5 1751.28l229.58 77.27-26.81 79.65-229.58-77.27 26.81-79.65"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3586.99 1821.42l-224.39-75.53c-1.43-.48-2.99.29-3.47 1.72l-30.98 92.06c-.49 1.44.29 2.99 1.72 3.47l224.39 75.52a2.73 2.73 0 003.47-1.71l30.98-92.06c.49-1.44-.29-2.99-1.72-3.47"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2985.26 1628.68c-3.4-1.14-7.08.68-8.22 4.08l-22.67 67.36a6.48 6.48 0 004.08 8.21c3.39 1.15 7.07-.68 8.22-4.08l22.67-67.35c1.14-3.4-.69-7.08-4.08-8.22M3592.03 1815.03c-3.55-1.2-7.39.71-8.59 4.26l-33.29 98.92c-1.2 3.56.71 7.4 4.26 8.59 3.55 1.2 7.4-.71 8.59-4.26l33.3-98.92c1.19-3.55-.72-7.4-4.27-8.59"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3269.11 1724.5c7.94-13.55 26.8-29.36 29.09-35.39 2.29-6.05-.34-27.22-.34-27.22l58.89 11.58c-.27 11.73 1.91 26.1 1.53 38.37-.44 13.83-6.6 21.2-14.86 37.44-27.76-9.26-74.31-24.78-74.31-24.78"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3329.55 1722.75c-7.06 13.8-24.11 17.07-24.11 17.07-4.71 29.31-13.63 41.17-20.97 39.57-12.65-2.76-5.76-17.51-4.01-25.77 1.75-8.25 4.89-15.68 1.26-28.59l47.83-2.28"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3328.35 1722.14c-6.67 13.02-23 16.32-23.17 16.35l-.93.18-.15.94c-4.44 27.63-11.79 35.3-14.75 37.32-1.58 1.07-3.12 1.46-4.59 1.13-2.55-.55-4.28-1.63-5.28-3.31-2.35-3.9-.45-10.65 1.07-16.08.48-1.71.93-3.32 1.24-4.76.28-1.34.61-2.66.93-3.98 1.69-6.95 3.44-14.13.31-25.27l-2.61.74c2.94 10.45 1.27 17.28-.34 23.88-.33 1.35-.66 2.7-.95 4.06-.29 1.36-.73 2.93-1.19 4.59-1.67 5.94-3.74 13.32-.79 18.22 1.39 2.31 3.76 3.85 7.03 4.56 2.2.48 4.52-.05 6.7-1.54 6.86-4.68 12.44-18.24 15.75-38.22 3.78-.92 17.73-5.08 24.13-17.58l-2.41-1.23"
          fill="#a06e5f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3153.76 1645.46c-2.04 14.52-.91 20.77-9.98 29.67-9.07 8.89-28.04 27.35-31.94 37.94-3.9 10.59-5.63 25.1-5.07 30.7.56 5.61 1.4 14.65 3.69 16.73 2.29 2.07 8.97 6.86 11.56 4.32 0 0 6.47 10.13 9.7 10.98 3.22.85 5.82-1.69 5.82-1.69s1.27 6.79 6.65 7.35c5.39.57 6.82-3.83 6.56-14.05 0 0 8.62 5.63 12.82 1.4 4.2-4.22 6.85-13.18 11.94-18.98 5.09-5.8 18.76-28.31 20.91-38.31 2.15-9.98 6.28-28.48 4.78-32.86-1.5-4.38 5.66-29.09 5.66-29.09l-53.1-4.11"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3123.46 1728.2l-1.32 1.06c-.48 2.15-4.71 21.09-3.78 25.18.88 3.9 2.33 10.66 2.33 10.66l2.66-.57s-1.46-6.77-2.34-10.69c-.6-2.63 2-16.11 3.78-23.98l-1.03-1.63-.3-.03M3141.68 1737.13l-1.3.99c-.2.68-4.74 16.72-4.63 21.79.11 4.88.43 14.15.43 14.25l1.4 1.3 1.31-1.4c0-.09-.32-9.35-.42-14.21-.1-4.67 4.47-20.83 4.52-20.99l-.94-1.68-.37-.05M3159.92 1741.39l-1.27.87c-.52 1.34-4.99 12.98-5.55 16.59l-3.59 8 .68 1.79 1.79-.68 3.68-8.18.11-.39c.3-2.53 4.01-12.52 5.41-16.16l-.77-1.75-.49-.09"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3183.34 1.16l-19.23 545.281-268.26 9.938L2988.91 1.16h194.43"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3004.35 1.16l104.86 570.91h-279.25L2804.09 1.16h200.26"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M2984.68 1.16h220.97c.29 10.899.63 21.809.98 32.711 1.37 41.707 4.67 83.629 4.56 125.359-.19 68.758-24.65 135.508-26.97 204.231-.9 26.949 1.61 53.891 1.72 80.848.19 46.062-7.25 91.113-15 136.339-5.32 31.012-.08 57.954-3.16 88.68-35.35-20.726-70.87-41.449-106.28-62.168-9.51-5.558-103.41-50.691-102.9-60.191l24.9-466.34c1.32-24.668.61-52.129 1.18-79.469"
          fill="#51422d"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3048.86 1.16c3.64 31.762 4.67 65.23 12.59 93.43 9.76 34.73 23.44 68.351 31.25 103.558 9.1 41.043 10.05 83.364 13.81 125.223 6.58 73.16 27.34 140.438 43.37 211.438 5.11 22.64 32.77 112.793 17.9 134.519-13.6 19.883-80.07 5.742-120.56-7.508-29.92-9.789-59.72-22.41-91.2-22.922-21.13-.347-41.92 4.821-62.93 7.043-29.56 3.118-64.69 22.887-74.12 12.411-9.43-10.481-38.17-104.993-38.17-225.383 0-34.93 6.77-164.957-8.37-301.219-4.16-37.43-2.92-87.84.54-130.59h275.89"
          fill="#6b5b40"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4078.75 1.16c21.76 121.82 40.34 227.629 40.34 227.629s19.52-107.129 40.09-227.629h306.59c-11.97 241.16-27.37 484.328-31.87 500.078-8.37 29.293-359.78 58.571-359.78 58.571l-207.09-87.848s-7.32-96.223-21.96-221.731c-6.57-56.3-19.03-149.019-33.43-249.07h267.11"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4119.09 226.68c-.13 0-.25.011-.37.031.24 1.367.37 2.078.37 2.078l.38-2.059-.38-.05"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4118.72 226.711c-.79.141-1.46.719-1.68 1.551l-29.28 112.957a2.103 2.103 0 001.51 2.57 2.11 2.11 0 002.58-1.508l29.29-112.961a2.11 2.11 0 00-1.52-2.57l-.15-.02-.38 2.059s-.13-.711-.37-2.078"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4492.34 1727.36s-29.55 16.52-47.9 26.26c-19.27 10.21-63.17 36.17-80.63 37.92-17.45 1.74-117.06-28.57-127.16-29.55-10.1-.99-24.82 4.47-25.59-4.87-.77-9.34 8.4-19.51 33.64-15.22 0 0 4.73-8.89 19.7-7.7 14.96 1.19 26.19 8.46 41.28 7.51 15.1-.94 53.04-33.81 67.06-36.79 14.02-2.98 20.52-5.04 20.52-5.04s9.83-13.25 28.67-12.99c18.84.27 23.53 5.65 23.53 5.65s11.84-5.53 29.1-20.43l17.78 55.25"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4393.26 1698.68l-.8.3c-6 5.33-11.57 21.25-13.16 26.07-3.56-1.71-14.13-5.85-30.76-4.47-13.01 1.07-17.9 5.74-19.72 9.47-1.7 3.5-1.35 7.67.95 11.16 2.89 4.4 15.14 9.82 20.27 11.95 8.17 3.39 18.79 6.92 24.09 7.16 9.55.47 51.46-14.42 53.24-15.05l.73-1.54-1.54-.73c-.43.15-43.21 15.38-52.32 14.91-9.43-.43-38.6-12.15-42.46-18.02-1.83-2.77-2.12-6.05-.79-8.78 2.18-4.48 8.48-7.37 17.75-8.13 19.52-1.61 30.54 4.76 30.65 4.83l1.05.09.72-.77c.06-.2 6.45-20.61 12.9-26.34l.1-1.71-.9-.4M4244.59 1740.7c-1.22 0-9.95.2-12.4 5.92-.84 1.95-1.55 4.23-.5 6.26 1.25 2.38 4.58 3.91 11.9 5.44 6.53 1.37 28.55 6.61 46.24 10.82 15.2 3.62 24.57 5.85 25.6 6.02l1.39-.99-.99-1.39c-1.01-.16-12.88-2.99-25.44-5.98-17.7-4.21-39.74-9.46-46.31-10.84-6.26-1.31-9.42-2.6-10.25-4.19-.47-.91-.3-2.16.57-4.2 1.94-4.54 10.22-4.48 10.28-4.47l1.22-1.18-1.18-1.22h-.13M4250.43 1736.58l-1.19 1.01.99 1.38c.46.08 46.37 7.76 65.87 11.55l1.41-.96-.96-1.41c-19.52-3.79-65.46-11.47-65.92-11.55l-.2-.02"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4213.22 1422.66s3.79 45.6 24.58 58.05c11.79 7.07 34.26 10.53 40.79 19.61 11.11 15.46 7.32 58.31 7.32 58.31s12.55 5.23 14.12 16.73c1.57 11.51-13.6 47.07-15.69 60.66-.74 4.78 1.25 21.57.13 36.09-2.07 26.82-7.34 61.87-28.63 87.07-26.06 30.84-54.39 35.56-112.96 31.37-58.57-4.18-72.95-31.11-88.64-82.36-15.68-51.25 4.45-114 14.91-141.19 10.46-27.2 3.92-103.55 1.83-135.97l142.24-8.37"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4275.91 1775.73c-6.82 12.37-8.47 23.99-22.53 31.25-10.46 5.41-22.59 6.61-34.36 6.4-11.16-.2-22.29-1.58-33.16-4.11-18.56-4.32-33.3-4.76-51.88.61-11.82 3.42-25.12 6.25-35.95.41-8.61-4.65-14.18-14.13-23.37-17.49-9.42-3.45-21.97-.34-28.19-8.19-6.57-8.29-1.1-20.31-5.18-29.52-1.57-3.56-4.37-5.34-5.5-9.25-1.67-5.81-.34-13.08-1.45-19.16-1.64-8.97-5.46-17.5-6.38-26.58-1.75-17.31 7.21-33.83 9.4-51.1 2.67-21.09-4.77-43.24 1.91-63.43 8.86-26.78 23.9-52.69 24.98-81.79.44-11.67 10.67-20.51 22.27-19.15 28.07 3.29 59.68-1.13 70.87 1.18 22.81 4.71 36.25 46.67 50.91 94.52 1.82 5.96-19.14 56.09-19.14 56.09s4.32-15.3 17.65-26.28c13.34-10.98 43.01-1.57 46.02 5.49 3.01 7.06-26.88 60.71-17.75 80.01 8.14 17.2 24.33 17.87 38.3 29.06 13.66 10.93 11.63 34.52 2.53 51.03"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4186 1592.66c6.79-20.64 22.25-34.09 34.53-30.06 12.29 4.04 16.74 24.04 9.96 44.67-6.79 20.63-16.06 35.68-28.35 31.64-12.28-4.03-22.92-25.62-16.14-46.25M3718.38 988.172s-45.45-143.613-48.53-182.063c-3.09-38.449 34.92-268.648 45.8-309.039l53.59 4.7s21.78 256.089 25.1 277.679c3.33 21.582 62.35 170.992 62.35 170.992l-138.31 37.731M4449.59 1409.07l199.76 115.05s-82.01 62.83-174.79 147.99l17.78 55.25s290.36-127.94 298.2-181.28c7.85-53.34-151.65-196.63-275.06-298.08l-65.89 161.07"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3849.25 474.051s-2.09-41.832 123.42-56.481c125.5-14.64 181.98 29.289 303.3 29.289 121.32 0 169.43 1.571 169.43 1.571s28.24 109.289 9.42 180.41c-18.83 71.121-50.2 241.601-16.74 388.02 20.82 91.06 46.02 172.57 46.02 172.57l72.17 44.98c-12.99 75.5-28.55 134.48-77.4 198.72 0 0-107.72-24.06-132.82-28.24-13.61-2.27-23.69 2.38-32.98 7.03-7.84 3.92-15.12 7.85-23.5 7.61-18.31-.53-46.02-27.19-46.02-27.19l-30.33 30.32c-63.44 14.92-115.96 21.69-144.33 10.47l-58.57-40.79s-127.34-29.07-167.34-62.76c-39.75-33.47-110.87-200.81-129.69-280.29l-31.38-58.571 186.17-64.848 27.19 110.869s33.47-104.592 14.64-276.119c0 0-14.64-41.832-25.1-77.391-10.46-35.558 18.83-48.109-35.56-209.179"
          fill="#bdcedf"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3769.24 501.77c-1.52-11.2-4.46-22.899 2.71-32.54 7.18-9.64 29-30.05 31.27-42.359 2.27-12.32.95-21.652 2.26-27.793 1.32-6.137 5.36-29.34 5.36-29.34s-4.16-6.66-9.59-3.847c-5.42 2.8-14.08 8.609-15.64 36.839 0 0-9.63 1.79-10.67-10.589-1.04-12.379-1-23.192.08-26.77 1.07-3.582 17.95-28.762 16.8-34.769-1.15-6-3.08-6.43-3.08-6.43s2-8.16-4.6-8.543c-6.59-.379-20.61 12.789-26.85 16.383-6.23 3.578-32.45 18.539-34.06 22.566-1.61 4.024-7.01 32.492-4.39 61.973 2.62 29.48-1.69 43.808-3.67 51.73-1.97 7.918.48 28.789.48 28.789l53.59 4.7"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3748.13 342.141c-4.37 0-8.63 1.398-12.73 4.179l-.32 1.668 1.67.321c8-5.43 14.47-3.618 16.6-3.028l.41.11c.75.187 2.59.359 5.54.621 2.8.25 6.29.558 7.59.867l.78.621c.28.82-.16 2.75-2.32 5.488-1.79 2.262-7.84 2.371-12.26 2.442-3.38.058-5.35.121-6.48.711-2.52 1.32-13.32 6.257-13.43 6.308l-.6 1.602 1.6.59c.45-.2 10.97-5.012 13.55-6.371.68-.348 3.19-.391 5.4-.43 5.17-.09 11.61-.199 14.11-3.36 2.42-3.05 3.38-5.808 2.7-7.769-.38-1.102-1.26-1.871-2.48-2.172-1.48-.348-4.77-.641-7.95-.918-2.24-.203-4.56-.41-5.17-.562l-.35-.098a21.65 21.65 0 00-5.86-.82"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3782 326.02l-2.44.289-.44.082c-2.29.41-21.75 6.019-22.57 6.257l-.83 1.493 1.49.828c6.97-2.008 20.6-5.899 22.33-6.199l.47-.09c2.1-.391 3.91-.739 5.76 2.32 1.89 3.109-1.84 6.91-5.3 9.559-2.71 2.07-15.25 3.832-19.85 4.332l-1.06 1.328 1.32 1.07c1.76-.199 17.32-1.969 21.06-4.82 7.68-5.867 7.44-10.16 5.89-12.719-1.83-3.039-3.96-3.73-5.83-3.73"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4008.26 1391.86c-.06.25-.07.51-.03.78a2.097 2.097 0 002.07 1.81c.11 0 .22-.01.33-.02.05-.01.82-.13 2.23-.32l-2.54-1.77s-.72-.17-2.06-.48m235.29.48l-1.7 1.7c.36.1.73.21 1.1.32.2.06.4.09.6.09.59 0 1.15-.25 1.55-.67-1-.91-1.55-1.44-1.55-1.44"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4148.23 1380.02c-17.79 0-35.12.67-50.44 1.58-48.17 2.85-87.39 8.59-87.78 8.65-.88.13-1.56.79-1.75 1.61 1.34.31 2.06.48 2.06.48l2.54 1.77c8.96-1.25 43.76-5.84 85.21-8.29 17.83-1.06 34.62-1.58 50.23-1.58 39.22 0 71.03 3.3 93.55 9.8l1.7-1.7s.55.53 1.55 1.44c.21-.23.38-.52.47-.84.34-1.12-.3-2.3-1.42-2.63-26.42-7.86-62-10.29-95.92-10.29"
          fill="#8e9aa7"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3895.27 1034.62c-.17 0-.34.02-.5.06l.5 2.06s.22-.68.61-2.03c-.06-.02-.11-.03-.17-.04l-.44-.05"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3894.77 1034.68c-.77.19-1.39.8-1.57 1.62-.05.25-5.29 25.26-9.57 55.84-5.75 41.15-7.11 72.23-4.05 92.38.16 1.05 1.07 1.8 2.09 1.8.11 0 .22-.01.32-.03a2.097 2.097 0 001.77-2.4c-7.18-47.18 13.37-145.73 13.58-146.72.23-1.08-.42-2.15-1.46-2.46-.39 1.35-.61 2.03-.61 2.03l-.5-2.06"
          fill="#8e9aa7"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4483.26 1186.67l.84 2.76 1.8 1.12c.04-.07.08-.14.12-.22.49-1.06.03-2.32-1.02-2.81-.58-.27-1.17-.56-1.74-.85"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4395.2 1061.81l-.76.15a2.109 2.109 0 00-1.21 2.73c.1.26 10.18 26.09 26.15 54.4 21.56 38.21 43.03 62.53 63.83 72.26.28.13.59.2.89.2.71 0 1.4-.37 1.8-1l-1.8-1.12-.84-2.76c-47.04-23.97-85.7-122.5-86.09-123.51l-1.97-1.35"
          fill="#8e9aa7"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4455.29 627.02c-.16.609-.31 1.218-.47 1.82-.24.898-.48 1.82-.72 2.75 1.01-.531 1.58-.82 1.67-.86 1.04-.531 1.46-1.8.93-2.839l-1.41-.871"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M4454.82 626.73l-.95.219c-.35.18-34.81 17.629-70.36 43.051-47.99 34.34-75.02 66.102-80.33 94.422a2.1 2.1 0 001.69 2.457c.13.031.27.043.4.043 1 0 1.87-.723 2.06-1.723 11.48-61.187 131.13-125.449 146.77-133.609.24-.93.48-1.852.72-2.75.16-.602.31-1.211.47-1.82l-.47-.29"
          fill="#8e9aa7"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3909.24 758.699l-.44.129c-.99.613-1.3 1.91-.68 2.91l2.31 3.731c-.17-1.61-.34-3.231-.52-4.848 0 0-.23-.672-.67-1.922"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M3909.91 758.512l-.67.187c.44 1.25.67 1.922.67 1.922.18 1.617.35 3.238.52 4.848l35.34 56.929c.4.641 1.09 1 1.8 1 .38 0 .76-.109 1.11-.32.99-.617 1.3-1.918.68-2.906l-37.65-60.66-1.8-1"
          fill="#8e9aa7"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5451.06 1373.52s-18.46 58.21-28.78 62.5c-10.33 4.3-47.13-2.56-55.36 6.97-8.24 9.53-3.9 68.88-5.64 73.64-1.73 4.77-15.67 6.52-17.62 16.27-1.95 9.75 21.87 43.83 27.4 63.13 1.94 6.76.44 17.49 2.18 28.38 3.05 19.1 3.04 40.91 14.63 58.57 16.74 25.52 66.55 48.3 127.19 42.23 47.7-4.77 85.34-37.9 93.14-68.23 7.8-30.32 5.2-119.12-13.86-144.25-19.06-25.12-28.59-65.41-30.33-85.33-1.73-19.93 6.61-59.08 6.61-59.08l-119.56 5.2"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5380.69 1580.08c.06 7.33 3.79 13.23 8.34 13.2 4.55-.04 8.19-6.01 8.13-13.33-.06-7.32-3.79-13.24-8.34-13.2-4.55.04-8.19 6.01-8.13 13.33"
          fill="#3b2413"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5418.83 1601.28l-2.19.72-2.36.83c-1.58.56-3.16 1.09-4.74 1.6-3.19.99-6.35 1.97-9.56 2.78-3.22.78-6.43 1.6-9.72 2.25-3.29.72-6.62 1.25-10.06 2.15l-.09.02c-2.96.77-5.13 3.56-4.76 6.6.33 2.67 2.31 4.62 4.69 5.15 3.95.85 8.03 1.15 12.09.86 4.07-.16 8.1-.99 12.03-2.1 3.92-1.15 7.72-2.76 11.27-4.83 1.8-.99 3.5-2.18 5.15-3.43l2.39-2.04 2.12-2.17c1.76-1.96 2.04-4.99.33-6.99a5.124 5.124 0 00-4.82-1.73l-1.77.33"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5363.55 1488.93a1.985 1.985 0 00-.18 3.96c.1.01 10.19 1.04 18.92 7.59l2.79-.4-.4-2.78c-9.66-7.25-20.48-8.32-20.94-8.37h-.19"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5432.87 1649.52c-21.31.51-41.05 9.62-62.36 10.12-.39.01.66 15.43 4.65 24.14 8.7 18.97 27.69 41.32 50.2 51.61 32.84 15.01 60.5 19.84 106.98 12.2 60.3-9.92 102.59-65.17 101.05-126.58-.33-13.28-3-26.43-2.98-39.71.02-18.77 5.27-37.03 6.75-55.5 1.38-17.32-8.66-33.7-5.72-51.11 2.87-16.96 18.17-32.6 12.73-48.92-25.98-10.57-54.93 7.77-82.89 5.52-14.42-1.17-28.3-7.84-42.74-6.77-7.56.55-14.69 3.2-21.84 5.88-5.13 24.78.97 61.5.97 61.5-12.33-19.96-17.63-42.13-19.86-55.81-17.67 3.13-36.52-.63-51.47-10.57-.73 15 .57 30.55 7.47 43.89 5.83 11.29 15.57 20.86 18.06 33.32 2.91 14.56-4.8 28.91-7.17 43.56-3.06 18.92 2.75 37.78 1.97 56.73-.71 17.19-10.2 30.05-13.8 46.5"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5597.07 1708.68s29.99 49.99 66.65 1.67c36.65-48.32-15-79.98-44.99-56.65-29.99 23.32-21.66 54.98-21.66 54.98"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5804.62 1.16c9.19 372.309-26.26 486.59-26.26 486.59l-523.06-34.211s-10.72-266.25-27.92-452.379h577.24"
          fill="#dfd1c1"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5563.36 7.2l-.53.07a2.111 2.111 0 00-1.52 2.57c3.49 13.66 8.68 46.691 14.17 81.648 5.76 36.66 11.71 74.563 15.72 89.321a2.114 2.114 0 002.04 1.562c.18 0 .37-.019.55-.07a2.12 2.12 0 001.49-2.59c-3.95-14.539-9.88-52.332-15.62-88.879-5.51-35.062-10.71-68.191-14.26-82.043a2.1 2.1 0 00-2.04-1.59"
          fill="#a79d91"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5773.35 759.211s-74.52-28.332-90.44-26.422l29.46-97.488s29.12-4.571 44.97 8.449c15.85 13.02 38.8 22.469 38.8 22.469l-22.79 92.992"
          fill="#e9b197"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5054.9 1668.93s32.01 15 51.85 23.77c20.83 9.19 68.48 32.93 86.79 33.44 18.3.52 119.76-38.5 130.2-40.28 10.44-1.78 26.17 2.81 26.28-6.98.1-9.78-10.21-19.68-36.17-13.33 0 0-5.59-8.9-21.08-6.54-15.5 2.36-26.64 10.76-42.43 10.91-15.79.15-57.76-31.24-72.58-33.3-14.82-2.05-21.75-3.71-21.75-3.71s-11.22-13.06-30.82-11.37c-19.59 1.68-24.08 7.63-24.08 7.63s-12.74-4.87-31.82-19.09l-14.39 58.85"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5156.02 1631.66l-1 .49.23 1.76c7.15 5.49 15.33 26.26 15.41 26.47l.8.75 1.09-.18c.1-.07 11.1-7.49 31.55-7.32 9.71.1 16.49 2.64 19.1 7.14 1.58 2.74 1.52 6.18-.17 9.2-3.58 6.41-33.08 20.79-42.87 21.95-9.45 1.11-55.13-11.49-55.59-11.62l-1.55.88.88 1.55c1.9.52 46.64 12.84 56.56 11.68 5.5-.64 16.29-5.11 24.55-9.25 5.19-2.61 17.53-9.17 20.21-13.96 2.13-3.81 2.19-8.18.15-11.69-2.17-3.75-7.62-8.24-21.24-8.39-17.4-.19-28.11 4.91-31.69 6.95-2.02-4.89-9.01-21.05-15.66-26.15l-.76-.26M5315.27 1664.24l-1.53.06-1.14 1.36 1.36 1.14c.09-.01 8.68-.7 11.04 3.88 1.06 2.05 1.33 3.35.91 4.33-.75 1.72-3.94 3.31-10.36 5.14-6.72 1.92-29.22 9.01-47.3 14.72-12.91 4.07-25.11 7.91-26.15 8.16l-.93 1.52 1.52.93c1.07-.26 10.7-3.29 26.31-8.22 18.07-5.69 40.55-12.78 47.24-14.69 7.5-2.15 10.86-3.99 11.98-6.56.95-2.2.03-4.51-.99-6.49-2.43-4.71-8.95-5.28-11.96-5.28M5307.58 1660.44l-.3.03c-.47.12-47.73 11.56-67.78 16.97l-.89 1.54 1.54.88c20.02-5.4 67.25-16.83 67.72-16.95l.93-1.51-1.22-.96"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5709.84 1358.49c-71.15 23.96-132.29 34.99-200.27 34.99-58.58 0-183.03-24.05-183.03-24.05l-279.25 32.94s59.09 161.4 57.1 207.76c-2 46.36-21.93 91.23-65.8 72.28-43.87-18.94-206.65-268.5-178.22-357.96 41.96-132.04 425.38-181.98 425.38-181.98s-18.3-50.21-16.74-125.51c2.88-138.089 45.06-263.858 13.11-402.671-6.9-29.98-19.34-58.34-29.88-87.238-12.75-35-19.83-86.371.56-122.449 35.85 4.457 62.53 3.636 92.89 2.82 13.42-.363 27.57-.723 43.54-.633 52.26.313 103.03 18.84 155.27 20.141 40.55 1.011 80.52-8.36 120.39-17.739 46.63-10.961 93.12-21.921 140.22-16.281 11.03 24.86 4.34 54.039-6.65 78.918-10.98 24.871-26.15 48.184-32.66 74.582-6.26 25.352-4.14 51.969-7.38 77.871-3.94 31.61-15.77 61.617-23.63 92.481-17.08 67.066-15.12 136.988-10.02 205.449 2.18 29.129-1.65 54.891-2.24 83.879-.12 5.78 15.38 108.4 10.86 111.28l154.23-150.632s-121.55-163.429-137.5-201.308c-15.95-37.891 10.13-96.211 46.02-99.211 35.89-2.989 250.32 221.761 252.15 313.66 1.92 95.611-275.99 357.581-338.45 378.611"
          fill="#415c55"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5734.05 1115.36c-.1.91-.27 1.53-.5 1.84l1.35-1.31a2.22 2.22 0 00-.85-.53"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5733.39 1115.25l-.28.02a2.125 2.125 0 00-1.82 2.38c.1.74 9.7 74.86-7.77 124.78a2.12 2.12 0 002 2.81c.87 0 1.69-.55 1.99-1.42 17.81-50.88 8.07-125.98 7.97-126.73-.06-.47-.27-.89-.58-1.2l-1.35 1.31c.23-.31.4-.93.5-1.84-.21-.07-.43-.11-.66-.11"
          fill="#314540"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5049.39 1402.12l-2.1.25.72 1.99a2.13 2.13 0 001.38-2.24"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M5047.29 1400.25l-.44.05c-.27.06-27.66 5.81-73.06 12a2.116 2.116 0 00-1.81 2.38c.15 1.06 1.05 1.84 2.08 1.84.1 0 .2-.01.3-.03 45.57-6.21 73.09-11.99 73.36-12.05.1-.03.2-.05.29-.08l-.72-1.99 2.1-.25c-.01-.06-.02-.12-.03-.19a2.124 2.124 0 00-2.07-1.68"
          fill="#314540"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6986.84 1547.01s-37.41-55.84 40.92-153.23l-176.29-9.88-30.17 139.5 165.54 23.61"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6824.94 1776.02c-22.48-28.66-37.13-59.86-39.43-81.07-2.18-20.06-8.79-48.36-16.05-57.04-14.5-17.37-30.84-41.9-24.46-51 6.37-9.11 20.12-8.5 23.49-16.23 3.37-7.73-13.16-62.7-1.99-76.58 19.47-24.2 123.53-24.96 220.34 52.91 55.37 44.54 69.99 131.28 54.83 185.52-15.16 54.25-61.18 76.07-115.25 81.93-54.07 5.86-78.29-8.87-101.48-38.44"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6825.59 1477.66c-1.18 0-2.16.93-2.22 2.11-.07 1.24.88 2.28 2.11 2.35 17.03.89 35.11 4.16 53.71 9.72 1.18.35 2.42-.32 2.78-1.5.35-1.18-.32-2.42-1.5-2.77-18.95-5.67-37.37-9-54.76-9.91h-.12"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6787.42 1651.28c.9 7.68 5.49 13.45 10.25 12.9 4.77-.56 7.91-7.23 7.01-14.91-.89-7.68-5.48-13.45-10.25-12.89-4.77.55-7.9 7.23-7.01 14.9"
          fill="#3b2413"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6826.92 1671.44l-2.14.72-2.3.82c-1.54.55-3.08 1.07-4.63 1.58-3.11.99-6.19 1.95-9.33 2.76-3.14.78-6.27 1.59-9.49 2.24-3.2.72-6.45 1.26-9.81 2.15l-.08.02c-2.9.77-5.01 3.5-4.63 6.47a5.87 5.87 0 004.6 5c3.87.82 7.86 1.1 11.82.8 3.98-.18 7.91-1 11.74-2.11 3.83-1.15 7.54-2.74 11-4.77 1.75-.98 3.41-2.15 5.01-3.37l2.33-2.02 2.06-2.12c1.72-1.92 1.97-4.89.29-6.83a4.976 4.976 0 00-4.71-1.67l-1.73.33M7067.09 1675.86c6.53 43 1.65 83.19-36.78 116.82-38.44 33.62-72.21 31.5-103.52 34.89-54.07 5.86-91.78-16.88-101.38-32.67-9.6-15.79-8.08-29.13-8.08-29.13.12-.07 48.27 6.89 63.82-19.52 5.01-8.5-39.44-43.59-14.67-86.78 11.6-20.21-3.38-55.39 10.4-56.88 17.16-1.86 64.19 31.7 64.19 31.7s14.06-7.09 17.34-19.5c2.42-9.18-10.47-17.32-12.7-28.74-2.65-13.51 20.55-48.01 34.06-54.41 6.4 15 29.2 25.6 48.27 50.88 19.32 25.62 34.89 66 39.05 93.34"
          fill="#914837"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6954.41 1598.64c-9.47-20.87-27.22-33.23-39.64-27.6-12.43 5.63-14.84 27.12-5.37 48 9.46 20.87 20.91 35.6 33.34 29.96 12.43-5.62 21.13-29.48 11.67-50.36"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6770.3 1552.16h-.38a2.232 2.232 0 00-2.22 2.24c0 1.23 1 2.22 2.23 2.22h.33c4.53 0 15.07.39 22.47 3.65 1.13.5 2.45-.01 2.94-1.14.5-1.12-.01-2.44-1.14-2.93-7.89-3.49-18.41-4.04-24.23-4.04"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6759.15 1.16l33.77 222.891s23.34-101.012 41.05-222.89h232.04c-.08 208.538 5.03 544.441 5.03 544.441l-485.21 23.589s-58.27-372.332-81.77-568.03h255.09"
          fill="#6b5b40"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6792.91 221.941c-.1 0-.21 0-.31.02l.32 2.09.47-2.063c-.08-.019-.16-.027-.23-.039l-.25-.008"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6792.6 221.961c-.92.141-1.67.879-1.78 1.848l-13.6 119.75c-.13 1.16.7 2.211 1.86 2.339.08.012.16.012.23.012 1.08 0 1.99-.789 2.11-1.871l13.6-119.75c.12-1.078-.6-2.07-1.63-2.301l-.47 2.063-.32-2.09"
          fill="#504430"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6333.78 1556.23l-85.43-1.45c12.02-38.76 52.41-165.82 94.86-291.5 44.99 27.13 54.69 28.16 100.7 57.56l-110.13 235.39"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6889.49 1407.13l-394.31-106.34s-3.68 14.8-20.98 21.24c-16.07 6-30.29-1.19-30.29-1.19-54.74-17.49-61.94-21.93-100.7-57.56 0 0-7.7-25.16-1.88-38.64 10.7-24.79 30.39-57.58 67.28-66.73 70.91-17.59 436.76 43.6 436.76 43.6l44.12 205.62"
          fill="#ddbe9b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6995.29 1458.78s99.47-95.05 103.34-225.81c3.88-130.77-9.06-341.31-6.32-422.232.31-52.949 11.06-107.258 5.59-160.16-4.94-47.758 2.99-87.93 12.04-135.957 3.52-18.641 7.05-37.5 5.91-56.43-1.14-18.929-7.46-38.242-21.06-51.461-4.44-4.3-101.17 32.481-113.56 35.372-34.67 8.07-68.84 23.25-103.96 28.207-15.36 2.171-30.9 1.89-46.45 1.613-15.29-.281-30.59-.563-45.71 1.496-29.41 4.004-57.03 16.672-86.29 21.684-23.75 4.058-47.63.929-71.17.019-18.79-.73-42.96-7.66-61.08-1.449-32.5 11.129 13.23 58.406 24.4 79.746 36.03 68.863 59.55 143.254 77.44 218.672 29.08 122.57 62.65 244.17 94.13 365.77 17.16 66.29 53.16 129.24 66.72 195.83 8.96 44.03 55.23 54.22 92.67 68.86 37.83 14.78 73.36 36.23 73.36 36.23"
          fill="#fff"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6567.93 1562.91l-75.4-7.93s-4.04-32.39-7.97-140.66c-3.72-102.14-3.57-170.82-1.94-189.91 49.72 22.52 58.97 10.81 109.93 29.64 1.31 61.2-17.15 213.74-24.62 308.86"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6830.03 455.488c4.6-.59 9.23-1.008 13.86-1.359-4.63.351-9.25.793-13.86 1.359M6658.86 495.27l1.45-.04-1.45.04"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M7132.88 404.441c1.14 18.93-2.4 37.797-5.91 56.438-9.06 48.019-34.01 141.941-29.07 189.699 5.47 52.902-5.28 107.211-5.59 160.16-2.74 80.922 10.2 291.462 6.32 422.232-3.87 130.76-103.34 225.81-103.34 225.81s-35.53-21.45-73.36-36.23c-37.44-14.64-83.71-24.83-92.67-68.86-13.55-66.59-49.56-129.54-66.72-195.83-31.48-121.6-65.05-243.2-94.13-365.77-17.89-75.418-61.23-191.27-97.27-260.141-11.16-21.34-56.9-68.621-24.39-79.75 16.71-5.719 35.76 1.813 52.74 2.051 8.45.109 17.21.129 26-.031 10.31 21.679 23.71 41.922 33.68 63.801 22.31 49.031 46.06 145.519 52.27 199.031 9.63 82.871 25.53 165 47.53 245.48 11.32 41.039 24.07 81.759 33.32 123.339 8.24 37.1 3.55 82.48 26.5 112.52 3.82-38.35 7.65-76.7 11.48-115.05 2.28-22.84 4.56-45.73 4.53-68.68-.04-26.769-3.24-53.449-3.45-80.211-.4-49.48-43.18-92.75-32.32-141.019 6.2-27.559 12.81-55.481 11.39-83.692-3.24-64.468-30.93-179.398-17.11-242.449 4.51-20.539 15.05-40.777 15.1-61.211 34.75-2.578 122.71-4.949 157.33-11.047 50.53-8.902 97.98-24.332 146.07-42.043 13.61 13.211 19.93 32.524 21.07 51.453"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6975.23 1367.92c-65.84 36-366.18-94.59-366.18-94.59s7.11 9.02.84 24.19c-3.82 9.23-18.78 12.73-18.78 12.73s-71.07.14-109.55-24.98c0 0-10.85-11.44-8.96-34.06 1.9-22.63-1.64-85.88 35.84-104.48 61.55-30.53 429.76 23.11 479.82 70.04 50.06 46.93 52.81 115.15-13.03 151.15"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6821.85 1162.13c-1 0-1.88.71-2.07 1.72h-.01c.81.15 1.61.3 2.42.46l.21-2.12c-.05-.01-.1-.01-.16-.02l-.39-.04"
          fill="#bfbfbf"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6822.4 1162.19l-.21 2.12c40.35 7.63 77.73 16.39 107.24 25.57.35-1.12-.28-2.3-1.4-2.65-28.2-8.65-64.72-17.3-105.63-25.04"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6819.77 1163.85c-.21 1.15.54 2.25 1.69 2.47 40.82 7.71 77.24 16.34 105.33 24.96.21.06.41.09.62.09.9 0 1.74-.58 2.02-1.49-29.51-9.18-66.89-17.94-107.24-25.57-.81-.16-1.61-.31-2.42-.46"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6827.35 1352.93l-.09.06c-.39 1.1.19 2.31 1.29 2.69.41.15.83.3 1.25.44-.2-.8-.38-1.6-.54-2.43-.02-.07-.04-.15-.05-.23-.62-.18-1.24-.35-1.86-.53"
          fill="#a68e74"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6829.21 1353.46c.01.08.03.16.05.23.16.83.34 1.63.54 2.43 24.25 8.43 47.69 11.39 68.41 14.01 7.47.94 14.51 1.83 21.31 2.92 10.31 1.65 19.53 2.48 27.63 2.48 12.34 0 22.08-1.92 29.1-5.76 1.02-.56 1.39-1.84.84-2.87-.01 0-.01-.01-.01-.01-.61.34-1.23.68-1.85 1.03-7.97 4.36-19.4 6.28-33.3 6.28-29.43 0-70-8.58-112.72-20.74"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6829.26 1351.58l-1.91 1.35c.62.18 1.24.35 1.86.53 42.72 12.16 83.29 20.74 112.72 20.74 13.9 0 25.33-1.92 33.3-6.28.62-.35 1.24-.69 1.85-1.03a2.135 2.135 0 00-1.85-1.09c-.34 0-.69.09-1.01.26-6.41 3.51-15.44 5.26-26.98 5.26-7.86 0-16.89-.81-27.05-2.44-6.87-1.11-13.95-2-21.45-2.95-20.86-2.63-44.51-5.61-68.78-14.23l-.7-.12"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6609.05 1273.33s.49.62 1.1 1.81c.36-.22.66-.56.84-.97l-1.94-.84"
          fill="#a68e74"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6510 1243.72c-.95 0-1.82.65-2.05 1.62-.27 1.14.43 2.28 1.56 2.55 46.44 11.05 98.39 27.3 98.91 27.46.21.07.42.1.63.1.39 0 .77-.11 1.1-.31-.61-1.19-1.1-1.81-1.1-1.81l1.94.84c.03-.07.06-.14.08-.21.35-1.11-.27-2.29-1.39-2.64-.52-.17-52.61-16.46-99.19-27.55l-.49-.05"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6352.53 1763.05s-7.32 14.33-20.42 8.27c0 0-11.04 15.54-21.2 13.48-10.15-2.07-9.97-9.14-9.97-9.14s-2.32 10.03-11.01 11.2-11.24-6.05-11.24-6.05-2.48 6.99-10.8 8.04c-8.32 1.05-8.9-11.55-8.9-11.55l94.59-21.19-1.05 6.94"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6043.12 1711.5l695.83-76.09 7.51 68.68-695.83 76.09a3.036 3.036 0 01-3.35-2.69l-6.85-62.65a3.032 3.032 0 012.69-3.34"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6522.62 1659.07l-475.33 51.97 7.51 68.68 475.33-51.97-7.51-68.68"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6042.41 1705.07l469.3-51.31a3.028 3.028 0 013.35 2.68l8.25 75.51a3.024 3.024 0 01-2.68 3.34l-469.3 51.32a3.036 3.036 0 01-3.35-2.69l-8.25-75.51a3.031 3.031 0 012.68-3.34"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6418.61 1663.94l-371.91 40.66 8.92 81.54 371.91-40.67-8.92-81.53"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6041.81 1699.55l365.88-40a3.031 3.031 0 013.34 2.68l9.46 86.54a3.024 3.024 0 01-2.68 3.34l-365.88 40.01a3.032 3.032 0 01-3.34-2.69l-9.47-86.53a3.036 3.036 0 012.69-3.35"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6314.36 1669.75l-266.8 29.18 10.12 92.56 266.8-29.17-10.12-92.57"
          fill="#d9a261"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6040.69 1689.33l260.77-28.51a3.031 3.031 0 013.34 2.68l11.7 106.98a3.035 3.035 0 01-2.68 3.35l-260.77 28.51a3.04 3.04 0 01-3.35-2.68l-11.69-106.98a3.028 3.028 0 012.68-3.35"
          fill="#ffd15f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6737.65 1623.47a7.187 7.187 0 017.92 6.37l8.56 78.27c.43 3.95-2.42 7.5-6.36 7.93-3.95.43-7.5-2.42-7.93-6.36l-8.56-78.28a7.191 7.191 0 016.37-7.93M6036.75 1681.22c4.13-.45 7.84 2.53 8.29 6.65l12.57 114.96c.45 4.12-2.53 7.83-6.65 8.29a7.517 7.517 0 01-8.29-6.66l-12.57-114.96a7.513 7.513 0 016.65-8.28"
          fill="#4c291b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6567.93 1562.91c-.87 20.85-4.07 29.31 6.33 44.1 10.41 14.79 32.22 45.52 34.96 61.35 2.73 15.84 1.42 36.6-.81 44.3-2.23 7.7-5.73 20.14-9.47 22.46-3.74 2.32-14.33 7.3-17.3 3.08 0 0-11.68 12.51-16.41 12.87-4.74.36-7.71-3.87-7.71-3.87s-3.53 9.18-11.22 8.59c-7.68-.6-8.55-7.13-5.55-21.37 0 0-13.52 5.66-18.32-1.33-4.79-6.99-6.2-20.22-11.83-29.66-5.63-9.43-18.97-44.47-19.4-59.02-.44-14.54-1.46-41.5 1.76-47.24 3.23-5.74-.43-42.19-.43-42.19l75.4 7.93"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6588.7 1686.52h-.08c-1.07.05-1.9.95-1.86 2.02.46 11.48.63 31.02-.88 34.55-2.25 5.25-6.03 14.37-6.03 14.37l3.57 1.48c0-.01 3.77-9.09 6.01-14.33 2.36-5.49 1.32-33.1 1.2-36.23-.05-1.04-.9-1.86-1.93-1.86M6560.89 1694.34h-.19a1.944 1.944 0 00-1.74 2.11c.03.24 2.27 24.04.92 30.55-1.39 6.79-4.22 19.66-4.25 19.79a1.931 1.931 0 003.77.83c.03-.13 2.87-13.03 4.27-19.83 1.46-7.08-.76-30.7-.86-31.7-.09-1-.93-1.75-1.92-1.75M6534.26 1695.6l-.36.04c-1.05.19-1.74 1.2-1.54 2.25 1.03 5.45 3.65 20.39 3.42 24.01l.05.58 3.04 12.4a1.94 1.94 0 002.34 1.42 1.94 1.94 0 001.42-2.34l-2.98-12.13c.15-5.2-3.11-22.64-3.49-24.65-.18-.93-.99-1.58-1.9-1.58M6357.06 1665.42c-7-21.22-30.48-62.31-31.87-71.4-1.39-9.09 8.59-37.79 8.59-37.79l-85.43-1.45c-3.11 16.42-10.45 35.77-13.57 52.97-3.52 19.37 4.8 44.93 11.45 69.99 41.4-4.61 110.83-12.32 110.83-12.32"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6271.51 1631.5c5.71 21.32 28.48 30.95 28.48 30.95-2.2 42.21 6.68 61.37 17.38 61.34 18.43-.07 13.25-22.66 13.27-34.67.02-12.02-2.14-23.29 6.77-40.18l-65.9-17.44"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M6273.37 1631l-3.73 1c5.18 19.31 23.36 29.26 28.35 31.66-1.36 28.81 2.37 49.35 10.52 57.91 2.59 2.72 5.64 4.15 8.83 4.15h.04c4.77-.02 8.51-1.45 11.14-4.25 5.57-5.95 4.89-16.84 4.34-25.59-.15-2.46-.29-4.78-.29-6.76 0-1.99-.05-3.96-.11-5.93-.27-9.67-.55-19.67 6.66-33.35l-3.42-1.8c-7.68 14.57-7.39 25.09-7.1 35.26.05 1.93.11 3.86.1 5.81 0 2.1.15 4.49.31 7 .5 8.02 1.12 17.99-3.31 22.71-1.9 2.03-4.62 3.02-8.33 3.04h-.03c-2.13 0-4.16-1-6.03-2.96-3.51-3.69-11.46-16.56-9.39-56.35l.07-1.35-1.25-.52c-.22-.1-21.97-9.55-27.37-29.68"
          fill="#a06e5f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M746.52 1760.76a228.46 228.46 0 01-29.122-6.56c-29.898-8.81-64.011-25.27-75.511-56.5-7.028-19.09-7.805-40.47-14.324-60.03-8.012-24.02-20.227-48.97-35.372-69.17-15.546-20.72-31.136-44.2-30.035-71.25.703-17.21 8.293-33.95 6.485-51.08-2.356-22.28-19.856-39.81-27.399-60.91-13.238-37 11.684-70.16 27.356-101.92 11.511-23.34 35.812-37.58 61.8-36.23l283.188 14.8c40.164 2.09 70.527 42.82 59.141 82-3.7 12.71-11.329 24.17-13.731 37.19-4.414 23.95 9.59 48.04 7.149 72.28-2.012 20-14.801 37.01-22.594 55.53-13.438 31.97-11.703 67.31-13.918 101.21-1.86 28.51-8.973 60.04-21.852 85.92-14.851 29.83-37.004 53.81-74.429 62.09-28.434 6.3-58.063 7.17-86.832 2.63"
          fill="#976652"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M861.992 1719.06c34.145-21.73 50.43-57.54 52.285-93.74 1.672-32.62-7.832-70.74-7.832-88.87 0-59.64-33.007-145.03-68.277-145.03s-76.824 21.06-101.168 43.63c-38.055 35.27-68.5 69.98-67.07 127.33 1.433 57.35-7.446 111.87 47.578 151.11 40.722 29.05 110.34 27.3 144.484 5.57"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M846.113 1485.58l-1.136.06a2.639 2.639 0 10.535 5.25 5.901 5.901 0 014.609 1.5 5.916 5.916 0 011.981 4.19l-12.504 66.68a2.633 2.633 0 002.105 3.08 2.626 2.626 0 003.082-2.11l12.559-66.95.043-.5a11.31 11.31 0 00-3.739-8.32 11.302 11.302 0 00-7.535-2.88"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M857.078 1465.73c2.676 0 4.367-2.87 3.074-5.21-3.726-6.75-13.566-15.27-25.386-15.75-13.614-.54-23.621 11.48-25.813 18.56-.359 1.16.531 2.33 1.75 2.33l46.375.07"
          fill="#fff"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M654.988 1511.51c11.309-23.85 31.989-37.74 46.188-31.01 14.203 6.73 16.547 31.52 5.238 55.37-11.309 23.85-24.773 40.58-38.976 33.86-14.2-6.73-23.758-34.37-12.45-58.22"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M802.871 1546.63c0 8.32-3.73 15.06-8.336 15.06-4.605 0-8.336-6.74-8.336-15.06 0-8.33 3.731-15.07 8.336-15.07 4.606 0 8.336 6.74 8.336 15.07M895.305 1546.63c0 8.32-3.735 15.06-8.34 15.06-4.606 0-8.336-6.74-8.336-15.06 0-8.33 3.734-15.07 8.336-15.07 4.605 0 8.34 6.74 8.34 15.07"
          fill="#00020f"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M684.297 1498.79l-1.434.42c-11.972 7.78-19.32 22.87-18.718 38.46.109 2.83.5 7.01 2.632 10.63 2.293 3.89 6.336 6.31 10.301 6.15 2.453-.09 4.906-1.31 6.555-3.25 1.5-1.77 2.18-3.91 1.914-6.04-.184-1.45-1.516-2.46-2.949-2.29a2.637 2.637 0 00-2.289 2.95l-.7 1.96c-.699.83-1.746 1.36-2.73 1.4-2.32.09-4.473-1.73-5.555-3.56-1.164-1.97-1.765-4.56-1.906-8.16-.527-13.76 5.879-27.04 16.32-33.82a2.639 2.639 0 00.778-3.65l-2.219-1.2"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M753.813 1571.48c.667.06 1.707.38 2.605.57l2.809.67c1.882.46 3.761.88 5.648 1.28 3.781.76 7.531 1.5 11.324 2.05 3.793.51 7.586 1.07 11.449 1.43 3.86.44 7.754.65 11.821 1.27l.097.02c3.5.53 6.336 3.47 6.262 7.01a6.927 6.927 0 01-4.769 6.48 49.38 49.38 0 01-13.801 2.44c-4.703.3-9.434-.17-14.086-.98a59.822 59.822 0 01-13.543-4.2c-2.184-.93-4.277-2.1-6.328-3.34-1.008-.68-2.02-1.3-2.992-2.07-.922-.68-1.7-1.33-2.7-2.23-2.257-2.04-2.937-5.5-1.207-8a5.901 5.901 0 015.332-2.56l2.079.16M915.012 1571.48c-.664.06-1.703.38-2.606.57l-2.808.67c-1.879.46-3.762.88-5.649 1.28-3.777.76-7.527 1.5-11.32 2.05-3.797.51-7.59 1.07-11.449 1.43-3.864.44-7.758.65-11.821 1.27l-.101.02c-3.5.53-6.332 3.47-6.262 7.01a6.928 6.928 0 004.77 6.48 49.37 49.37 0 0013.804 2.44c4.7.3 9.434-.17 14.082-.98a59.76 59.76 0 0013.543-4.2c2.184-.93 4.282-2.1 6.328-3.34 1.008-.68 2.02-1.3 2.997-2.07.918-.68 1.695-1.33 2.699-2.23 2.254-2.04 2.937-5.5 1.203-8a5.895 5.895 0 00-5.328-2.56l-2.082.16"
          fill="#976652"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M717.32 1713.86c40.828 29.12 110.618 27.37 144.848 5.58 19.445-12.38 34.484-30.63 43.207-51.95 4.402-10.76 6.996-22.29 10.41-33.38a204.438 204.438 0 008.184-41.55c.129-1.43.242-2.91-.211-4.27-1.16-3.52-5.836-4.89-9.274-3.51-3.437 1.39-5.714 4.72-7.132 8.14-1.422 3.43-2.192 7.1-3.766 10.46-5.652 12.07-19.777 17.12-32.41 21.38-3.649 1.23-7.36 2.53-10.414 4.87-4.172 3.21-6.727 8.07-8.715 12.94-3.043 7.46-7.856 18.36-8.774 26.37-.601 5.23-2.98 18.08-3.714 23.2.293-2.04-4.813-22.46-6.032-24.13-4.293-5.86-8.086-10.58-12.886-16.5-9.211-11.36-22.321-19.65-36.707-22.53-16.09-3.22-34.875-.8-46.821-12.04-6.304-5.94-9.211-14.53-13.707-21.93-9.164-15.07-34.972-25.99-53.234-24.92-10.09.59-.379 9.27-.371 17.52.008 8.96.008 17.93.371 26.88 1.465 36.3.207 69.54 29.234 93.43 2.301 1.9 15.489 4.21 17.914 5.94"
          fill="#976652"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M688.68 1353.58c11.164 23.82 6.465 215.48 6.465 215.48l129.222-145.9s1.524-73.85 31.031-81.48c28.282-7.32 76.129-146.19 76.129-146.19l-20.808-138.72-105.934-41.62-149.441 112.24-13.242 198.63s35.414 3.75 46.578 27.56"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M824.648 1384.06l-.679.08c-29.696 7.11-62.039 24.4-84.414 45.14a2.897 2.897 0 00-.153 4.1 2.903 2.903 0 004.106.16c21.68-20.1 53.031-36.86 81.812-43.75a2.904 2.904 0 002.153-3.5 2.914 2.914 0 00-2.825-2.23"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M587.547 1.16h305.094c3.504 58.23 14.297 106.031 14.297 106.031s11.285-48.16 24.503-106.03h286.819c-39.3 212.89-108.92 589.12-108.92 589.12l-495.613-37.832s-34.047-259.25-32.157-425.711c.594-52.386 3.082-92.976 5.977-125.578"
          fill="#f2d6b3"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M906.941 105.07l-.351.032c-.035.007-.07.019-.102.019.285 1.359.45 2.07.45 2.07s.164-.711.48-2.062a1.966 1.966 0 00-.477-.059"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M906.488 105.121a2.12 2.12 0 00-1.636 2.41l19.91 119.797a2.12 2.12 0 002.101 1.762c.11 0 .223-.012.332-.031a2.113 2.113 0 001.739-2.43L909.023 106.84a2.114 2.114 0 00-1.605-1.711c-.316 1.351-.48 2.062-.48 2.062s-.165-.711-.45-2.07"
          fill="#b5a086"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M642.102 1326.02s-94.582-7.57-149.442-66.21c-54.855-58.64-246.762-332.322-250.547-400.431-3.785-68.09 204.367-219.5 291.383-268.68l43.695 33.692S432.129 815.262 382.945 856.879c0 0 109.246 109.191 172.754 205.451 0 0 32.207-49.04 32.84-81.83l66.805 146.89-13.242 198.63M837.574 1343.04c11.981-9.28 52.555 5.09 84.063 17.24 46.394 17.9 113.933 23.11 174.463 33.2 60.54 10.09 161.2 73.26 161.2 73.26s-61.66 107.38-117.09 149.33l4.39 71.74s275.02-122.18 280.97-233.82c5.96-111.64-387.06-209.88-403.46-266.63-16.39-56.75 21.03-181.04 21.03-181.04l-111.613 189.17-93.953 147.55"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M931.527 1190.92a4.574 4.574 0 00-4.39 3.31l-44.871 156.61a4.572 4.572 0 003.132 5.65 4.576 4.576 0 005.653-3.14l44.871-156.6a4.57 4.57 0 00-3.137-5.65l-1.258-.18M655.34 1122.82a4.567 4.567 0 00-4.422 3.44l-49.031 191.96a4.56 4.56 0 003.297 5.55 4.561 4.561 0 005.554-3.29l49.035-191.96a4.578 4.578 0 00-3.296-5.56l-1.137-.14"
          fill="#627099"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1154.38 520.75c3.29-12.27 5.25-25.34-.13-33.91-10.08-16.078-53.14-14.629-70.64-13.481-25.16 1.653-49.9 8.661-75.11 8.262-37.07-.59-72.395-17.16-109.449-18.519-23.321-.864-46.84 4.476-67.504 15.316-13.508 7.094-31.477 16.012-42.078 5.043-5.625-5.82-6.016-14.981-10.524-21.711-6.144-9.16-18.804-11.852-29.652-9.84-10.848 2.012-20.57 7.738-30.586 12.371-38.027 17.547-82.078-1.722-120.711-5.609-3-.301-6.523-.32-8.414 2.027-1.422 1.762-1.367 4.262-1.242 6.531 2.637 47.45-3.617 83.77 25.297 122.629 11.144 14.981 15.359 75.051 7.226 80.532-6.761 4.558-10.453 12.519-12.336 20.449-5.742 24.199 2.559 49.301 3.45 74.148 3.109 86.524-17.907 156.551-23.442 215.512-5.445 58.02 61.442 173.62 61.442 173.62s101.535-82.03 188.55-65c87.016 17.02 83.856 134.96 83.856 134.96s95.647-94.17 117.977-174.55c12.03-43.33 19.82-221.26 18.82-247.882-1.33-35.339 22.58-130.73 35.17-163.429 18.77-48.75 39.93-44.328 53.3-95.02 1.57-5.949 4.47-14.008 6.73-22.449"
          fill="#627099"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M1144.6 1687.81c-14.83 5.23-50.77 10.54-76.23 18.86-25.45 8.33-48.77 20.58-60.13 19.22-11.353-1.36-15.138-5.89-34.697-12.98-19.563-7.1-93.984-32.08-101.426-34.74-7.437-2.67-.406-6.67-.406-6.67s-11.012-4.41-2.215-12.83c8.793-8.41 36.031 3.02 43.531 2.91 7.5-.11 13.133-3.6 27.555-2.72 14.422.87 39.797 8.66 44.508 9.03 4.711.39 38.45-5.81 48.07-13.93 9.62-8.11 19.91-21.21 49.9-21.11 29.99.11 19.38 2.65 29.99.11s27.16-16.89 27.16-16.89l4.39 71.74"
          fill="#f3aa8b"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M907.563 1661.65l-.801.23c-2.586 1.61-3.973 4.14-3.617 6.59.312 2.15 1.906 3.82 4.265 4.46 5.649 1.55 53.27 14.37 67.363 18.16 14.641 3.93 48.257 4.72 49.687 4.75l1.56-1.49-1.49-1.56c-.35 0-34.682-.81-48.967-4.65-15.028-4.04-61.946-16.67-67.348-18.15-1.192-.32-1.918-1.02-2.055-1.95-.18-1.23.688-2.63 2.207-3.57l.492-2.09-1.296-.73"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M886.168 1656.2l-.602.12c-3.886 1.68-7.851 5.36-9.027 8.38-.863 2.21-1.293 5.61.082 8.52 1.055 2.23 2.942 3.81 5.613 4.69 6.309 2.07 129.556 34.66 134.806 36.05l1.86-1.08-1.09-1.86c-1.28-.34-128.4-33.96-134.626-36.01-1.844-.6-3.125-1.64-3.809-3.09-.965-2.04-.602-4.57.004-6.12.769-1.99 3.996-5.21 7.391-6.67l.8-2.01-1.402-.92"
          fill="#c48974"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M555.699 1060.21l-1.133.33c-.011.01-.019.02-.031.02.391.59.778 1.18 1.164 1.77 0 0 .36-.55 1.004-1.57l-1.004-.55"
          fill="#bfbfb6"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M554.535 1060.56a2.118 2.118 0 00-.617 2.9c.242.38 24.465 38.46 39.16 63.45a2.114 2.114 0 001.824 1.04c.368 0 .735-.09 1.071-.29a2.118 2.118 0 00.75-2.9c-14.735-25.04-38.993-63.19-39.239-63.57l-.781-.43c-.644 1.02-1.004 1.57-1.004 1.57-.386-.59-.773-1.18-1.164-1.77"
          fill="#b67f68"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
        <path
          d="M618.738 622.512c15.293 1.879 58.914 23.238 74.875 15.547 15.961-7.68 44.84-22.387 44.258-32.797-.578-10.422-4.223-5.821-9.016-4.211-4.796 1.609-30.847 18.57-47.343 16.328 0 0 61.863-32.219 57.101-39.629-4.761-7.41-11.8-1.809-16.465 1.238-4.66 3.051-37.585 18.043-37.585 18.043s45.804-26.652 47.914-32.09c2.113-5.429-4.188-10.66-8.848-7.613-4.66 3.051-43.656 24.711-43.656 24.711s22.632-22.461 24.613-29.34c1.977-6.871-4.895-8.847-8.719-6.168-3.82 2.688-36.121 17.778-36.121 17.778s-63.297-20.95-85.844-15.309c-22.547 5.629-40.406 41.699-40.406 41.699l43.695 33.692s28.059-3.532 41.547-1.879"
          fill="#e9b197"
          transform="matrix(1.33333 0 0 -1.33333 0 315.253) scale(.1)"
        />
      </g>
    </svg>
  )
}

export default LookingForIllustration
