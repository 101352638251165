import React, { useState, useEffect } from "react";
import useGetRequest from "../hooks/useGetRequest";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { AutomationEventsHome } from "./events/AutomationEventsHome";
import TemplateHome from "./templates/TemplateHome";
import AutomationWorkflowList from "./workflow/AutomationWorkflowList";
import WorkflowTestForm from "./workflow/WorkflowTestForm";
import IvrVoiceRecorder from "./ivr/IvrVoiceRecorder";
import { TriggersDashboard } from "./event_trigger/TriggersDashboard";
import AutomationConfigHome from "./configuration/AutomationConfigHome";

import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
} from "@/components/ui/resizable";
import { AutomationNavigation } from "./navigation/AutomationNavigation";
import { AutomationLogManager } from "./logging/AutomationLogManager";
import { AutomationDataSourceManagement } from "./data_source/AutomationDataSourceManagement";
import { AutomationDataSourceFieldManager } from "./data_source/AutomationDataSourceFieldManager";
import { AutomationEventLogsHome } from "./event_logs/AutomationEventLogsHome";

export default function AutomationHome() {
  const [sel, setSel] = useState("events");

  const menuData = [
    {
      title: "Events",
      tabCode: "events",
      content: <></>,
      disabled: true,
      items: [
        {
          title: "Source",
          tabCode: "data_source",
          content: <AutomationDataSourceManagement />,
        },
        {
          title: "Fields",
          tabCode: "data_source_fields",
          content: <AutomationDataSourceFieldManager />,
        }
      ],
    },
    {
      title: "Workflows",
      tabCode: "workflows",
      content: <></>,
      disabled: true,
      items: [
        {
          title: "Manage",
          tabCode: "trigger",
          content: <AutomationWorkflowList />,
        },
        {
          title: "Triggers",
          tabCode: "event",
          content: <TriggersDashboard />,
        },
        {
          title: "Executions",
          tabCode: "list",
          content: (
            <AutomationEventsHome />
          ),
        },
        {
          title: "Event Log",
          tabCode: "event_log",
          hide: true,
          content: (
            <AutomationEventLogsHome />
          ),
        },
      ],
    },
    {
      title: "Templates",
      tabCode: "#",
      content: <></>,
      disabled: true,
      items: [
        {
          title: "Edit",
          tabCode: "templates",
          content: <TemplateHome />,
        },
        {
          title: "IVR",
          tabCode: "ivr",
          content: <IvrVoiceRecorder />,
        },
      ],
    },
    {
      title: "Inspect",
      tabCode: "#",
      disabled: true,
      content: <AutomationConfigHome content={<></>} />,
      items: [
        {
          title: "Test",
          tabCode: "test",
          content: <WorkflowTestForm />,
        },
        {
          title: "Logs",
          tabCode: "log",
          content: <AutomationLogManager />,
        },
      ],
    },
  ];

  const flattenMenuData = (data) => {
    const extractItems = (items) =>
      items.reduce((acc, item) => {
        const current =
          item.tabCode && item.content
            ? [{ tabCode: item.tabCode, content: item.content }]
            : [];
        const children = item.items ? extractItems(item.items) : [];
        return acc.concat(current, children);
      }, []);

    return extractItems(data);
  };

  return (
    <>
      <ResizablePanelGroup direction="horizontal" className="flex h-full">
        <ResizablePanel
          defaultSize={10}
          className="py-4 px-2 border-r border-gray-50 bg-gray-50 h-full items-center justify-center  hidden md:block overflow-auto"
        >
          <AutomationNavigation
            tabs={menuData}
            sel={sel}
            setSel={setSel}
            flatMenu={flattenMenuData(menuData)}
          />
        </ResizablePanel>
        <ResizableHandle />

        <ResizablePanel
          defaultSize={90}
          className="border-r h-full items-center justify-center bg-white hidden md:block "
        >
          <div className="flex-1 flex-col space-y-3 h-full overflow-auto p-4">
            {
              flattenMenuData(menuData).filter((m) => m.tabCode == sel)[0]
                .content
            }
          </div>
        </ResizablePanel>
      </ResizablePanelGroup>
    </>
  );
}
