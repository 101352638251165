import {
  ChatBubbleLeftIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
} from "@heroicons/react/24/outline";
import { senderDestinationOptions } from "./constants";

export function isGSMAlphabet(text) {
  var regexp = new RegExp(
    "^[A-Za-z0-9 \\r\\n@£$¥èéùìòÇØøÅå\u0394_\u03A6\u0393\u039B\u03A9\u03A0\u03A8\u03A3\u0398\u039EÆæßÉ!\"#$%&'()*+,\\-./:;<=>?¡ÄÖÑÜ§¿äöñüà^{}\\\\\\[~\\]|\u20AC]*$"
  );
  return regexp.test(text);
}

export function updateText(val, setSmsText) {
  if (val.length <= 160 && isGSMAlphabet(val)) setSmsText(val);
}

export function msgDestinationOptionsList(
  selectedTopic,
  selectedDestination,
  handleOnClick
) {
  let contextMenu = [];
  selectedTopic.contact_option_list.forEach((el) => {
    if (el.key == "phone") {
      contextMenu = [
        ...contextMenu,
        {
          name: senderDestinationOptions.filter((s) => s.key == el.key)[0]
            .actionLabel,
          icon: ChatBubbleLeftIcon,
          onClickAction: () => handleOnClick(el),
          href: null,
          textColor: "text-gray-800",
          children: [],
        },
      ];
    }
    if (el.key == "email") {
      contextMenu = [
        ...contextMenu,
        {
          name: senderDestinationOptions.filter((s) => s.key == el.key)[0]
            .actionLabel,
          icon: EnvelopeIcon,
          onClickAction: () => handleOnClick(el),
          href: null,
          textColor: "text-gray-800",
          children: [],
        },
      ];
    }
  });
  return contextMenu;
}

export function submitCommunicationMessage(
  selectedTopic,
  selectedDestination,
  txtValue,
  doPostCommMessage
) {

  doPostCommMessage({
    threadId: selectedTopic.id,
    method: selectedDestination.key,
    message: txtValue,
  })
  
}

export function submitInternalMessage(
  selectedTopic,
  txtValue,
  doPutMsgThreadAction,
  mentions = []
) {

  doPutMsgThreadAction({
    mode: 'addinternalcomment',
    mentions: mentions.toString(),
    threadId: selectedTopic.id,
    comment: txtValue,
  })
}
