import { useState, useContext, useEffect } from 'react';
import { apiUrl } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';
import { useAuth } from './useAuth';
import { blankResult, reqOk, loadingScreen } from '../common/fetchdata';

function usePostRequestWithFiles(action) {
  const auth = useAuth();
  const [postR, setPostR] = useState(blankResult);
  const _gc = useContext(GlobalStateContext);

  useEffect(() => {
    loadingScreen(postR, _gc);
  }, [postR]);

  async function handleRequest(payload, files) {
    const formData = new FormData();

    console.log(files)
    
    if (files && typeof files === 'object') {
      Object.keys(files).forEach((key) => {
        formData.append(key, files[key]);
      });
    }
    
    // Add other payload data to formData
    Object.keys(payload).forEach((key) => {
      formData.append(key, payload[key]);
    });

    // Add authentication token if available
    formData.append('token', auth.token);

    setPostR({ ...postR, loading: true });
    
    try {
      const response = await fetch(apiUrl[action], {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${auth.token}`, // Add Authorization header
        },
      });

      const data = await response.json();

      setPostR({
        loading: false,
        success: response.ok,
        items: data,
        error: !response.ok ? data : null,
      });
    } catch (error) {
      setPostR({
        loading: false,
        success: false,
        items: null,
        error: error.message || 'Unexpected error',
      });
    }
  }

  return {
    doPost: handleRequest,
    postR,
    setPostR,
    isComplete: reqOk(postR),
    items: reqOk(postR) ? postR.items : null,
  };
}

export default usePostRequestWithFiles;