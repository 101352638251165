import React from "react";
import { GlobalStateContext } from '../state/globalState';

const LoadingIndicator = ({ }) => {

  const globalcontext = React.useContext(GlobalStateContext);
  const [completed, setCompleted] = React.useState(50);
  const [showBar, setShowBar] = React.useState(true);
  let progress = 0;
  React.useEffect(() => {
    setInterval(() => {
      progress = progress + 10;
      if (progress <= 100) setCompleted(progress);
      else setShowBar(false)
    }, 0);
  }, []);

  return (
    <>
      {globalcontext.contextFcn.loading?.isLoading &&
        <React.Fragment>

          <div style={{ zIndex: 110 }} className="absolute top-0 left-0 h-2 w-full indeterminate-progress-bar">
            <div className="indeterminate-progress-bar__progress bg-gradient-to-r from-gray-800 to-gray-800" />
          </div>
        </React.Fragment>


      }


    </>
  );
};

export default LoadingIndicator;


