import { getJson } from "../../common/fetchdata";
import { getRequests as gt } from "../../common/apiEndpoints";
import { objectCompare } from "../../common/comjs";

import moment from "moment";
import "moment/dist/locale/pt";
import { threadFilters } from "../../common/filter/filterconst";
moment.locale("pt");

export const options = [
  {
    pivot: "contact-message",
    audience: "public_admin",
    name: "Pedidos de contacto",
    selection: null,
  },
  {
    pivot: "phone-call",
    audience: "public_admin",
    name: "Chamadas",
    selection: null,
  },
];

const defaultStartDate = moment().subtract(7, "days").format("YYYY-MM-DD");
const defaultEndDate = moment().format("YYYY-MM-DD");
const defaultDateSpan = `${defaultStartDate},${defaultEndDate}`;

export const dateSorterArgs = [
  "lastCallEvent",
  "lastBookingContact",
  "lastUpdate",
];

export function parseUrl(
  navigateAction,
  searchParams,
  topics,
  setTopics,
  topic,
  setTopic
) {
  // Arguments
  let _ownerId = searchParams.get("ownerId");
  let _status = searchParams.get("status");
  let _threadId = searchParams.get("threadId");
  let _lookup = searchParams.get("lookup");
  let _dateSpan =
    searchParams.get("startDate") && searchParams.get("endDate")
      ? `${searchParams.get("startDate")},${searchParams.get("endDate")}`
      : null;
  let _hasPendContact = searchParams.get("hasPendContact");

  _audience = _audience == null ? defaultAudience : _audience;
  _pivot = _pivot == null ? defaultPivot : _pivot;
  _dateSpan = _dateSpan == null ? defaultDateSpan : _dateSpan;
  _status = _status == null ? defaultStatus : _status;
  _hasPendContact = _hasPendContact == null ? "0" : _hasPendContact;

  // use case for lookup onlu
  if (_pivot == "search") {
    _dateSpan = null;
    _status = null;
  }

  console.log("get data");

  if (navigateAction.indexOf("topics") > -1)
    handleFetchTopics(
      topics,
      setTopics,
      searchParams,
      _audience,
      _pivot,
      _dateSpan,
      _status,
      _lookup,
      _ownerId,
      _hasPendContact
    );

  if (navigateAction.indexOf("topic") > -1 && _threadId)
    handleFetchTopic(topic, setTopic, _threadId);

  if (navigateAction.indexOf("all") > -1) {
    handleFetchTopics(
      topics,
      setTopics,
      searchParams,
      _audience,
      _pivot,
      _dateSpan,
      _status,
      _lookup,
      _ownerId,
      _hasPendContact,
      _threadId
    );
    handleFetchTopic(topic, setTopic, _threadId);
  }
}

export function dateSorterArg(pivot) {
  return pivot == "contact-message"
    ? "lastBookingContact"
    : pivot == "phone-call"
    ? "lastCallEvent"
    : "lastUpdate";
}

export function handleFetchTopics(
  topics,
  setTopics,
  searchParams,
  audience,
  pivot,
  dateSpan,
  status,
  lookup,
  ownerId,
  hasPendContact,
  threadId
) {
  // date sort depends on how the pivot is set
  // phone calls : lastCallEvent
  // message requests : lastBookingContact
  // other : lastUpdate

  const dateSorter = dateSorterArg(pivot);

  let args = {
    convType: audience,
    screen: pivot,
  };
  if (lookup != null) args.lookup = lookup;
  if (status != null) args.status = status;
  if (dateSpan != null) args[dateSorter] = dateSpan;
  if (ownerId != null) args.ownerId = ownerId;
  if (hasPendContact != null) args.hasPendContact = hasPendContact;
  if (threadId != null) args.threadId = threadId;

  const pageNum = searchParams.get("page");
  if (pageNum) {
    args = { ...args, page: pageNum };
  }
  getJson(gt.messaging, { ...topics, params: args }, setTopics);
}

export function handleFetchTopic(topic, setTopic, id) {
  getJson(gt.messagingthread, { ...topic, params: { threadId: id } }, setTopic);
}

export function getDateSpanFromUrl(searchParams) {
  const currConfig = dateSorterArg(getOptionFromUrl(searchParams).pivot);

  let _dateSpan = searchParams.get(currConfig)
    ? searchParams.get(currConfig)
    : null;

  if (_dateSpan != null) {
    _dateSpan = _dateSpan.split(",");
    if (_dateSpan.length == 2)
      _dateSpan = {
        startDate: moment(_dateSpan[0]).format("YYYY-MM-DD"),
        endDate: moment(_dateSpan[1]).format("YYYY-MM-DD"),
      };
    else _dateSpan = null;
  }

  _dateSpan =
    _dateSpan == null
      ? { startDate: defaultStartDate, endDate: defaultEndDate }
      : _dateSpan;
  return _dateSpan;
}

export function getOptionFromUrl(searchParams) {
  let _pivot = searchParams.get("screen");
  _pivot = _pivot == null ? defaultPivot : _pivot;

  if (options.filter((k) => k.pivot == _pivot).length > 0)
    return options.filter((k) => k.pivot == _pivot)[0];
  else return false;
}

export function isLookupSet(searchParams) {
  let _lookup = searchParams.get("lookup");
  if (_lookup == null) return false;
  else return true;
}

export function moduleSelected(searchParams) {
  let _pivot = searchParams.get("screen");
  if (_pivot == null) return "dashboard";
  else return _pivot;
}

export function onlyShowPendContacts(searchParams) {
  let _pivot = searchParams.get("hasPendContact");
  if (_pivot == null) return false;
  else {
    if (_pivot == 0) return false;
    if (_pivot == 1) return true;
    return false;
  }
}

export function setShowPendContacts(searchParams, navigate, show) {
  let showContacts = 0;
  if (show) showContacts = 1;

  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.delete("hasPendContact");
  newSearchParams.set("hasPendContact", showContacts);
  newSearchParams.delete("_k");
  const uniqueKey = new Date().getTime();
  newSearchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${newSearchParams.toString()}`);
}

export function getNfilters(locationSearch) {
  let newSearchParams = new URLSearchParams(locationSearch);
  newSearchParams.delete("_k");
  newSearchParams.delete("screen");
  newSearchParams.delete("threadId");
  newSearchParams = Object.fromEntries(newSearchParams);
  return Object.keys(newSearchParams).length;
}

export function getActiveFilters(locationSearch) {
  let searchParams = new URLSearchParams(locationSearch);
  let _status = searchParams.get("status");

  if (_status == null) return ["OPEN", "CLOSED", "PENDING"];
  return _status;
}

export function setActiveStatus(locationSearch, navigate, status) {
  let searchParams = new URLSearchParams(locationSearch);
  searchParams.delete("status");
  const uniqueKey = new Date().getTime();
  searchParams.set("_k", uniqueKey);
  searchParams.set("status", status);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function getLeadAssigneeId(locationSearch) {
  let searchParams = new URLSearchParams(locationSearch);
  let _assignee = searchParams.get("ownerId");
  return _assignee;
}

export function showLeadsByAsigneeId(locationSearch, navigate, assignee) {
  let searchParams = new URLSearchParams(locationSearch);
  const uniqueKey = new Date().getTime();
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  searchParams.set("ownerId", assignee);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function searchLeads(locationSearch, navigate, searchTerms) {
  let searchParams = new URLSearchParams("");
  const uniqueKey = new Date().getTime();
  searchParams.set("_k", uniqueKey);
  searchParams.set("screen", "results");
  searchParams.set("hasPendContact", 0);
  searchParams.set("status", "OPEN,PENDING,CLOSED");
  searchParams.set("lookup", searchTerms);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function removeLookup(locationSearch, navigate) {
  let searchParams = new URLSearchParams(locationSearch);
  const uniqueKey = new Date().getTime();
  searchParams.delete("_k");
  searchParams.delete("lookup");
  searchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function isFilterSetVanilla(locationSearch, navigate) {
  let searchParams = new URLSearchParams(locationSearch);
  const params = Object.fromEntries(searchParams);
  delete params._k;
  delete params.threadId;

  if (
    objectCompare(params, {
      screen: "results",
      hasPendContact: "0",
      status: "OPEN,PENDING",
      ownerId: "0",
    })
  )
    return true;
  else return false;
}

export function resetFiltersToVanilla(locationSearch, navigate) {
  let searchParams = new URLSearchParams("");
  const uniqueKey = new Date().getTime();
  searchParams.set("_k", uniqueKey);
  searchParams.set("screen", "results");
  searchParams.set("hasPendContact", 0);
  searchParams.set("status", "OPEN,PENDING");
  searchParams.set("ownerId", 0);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function updateLeadsList(locationSearch, navigate) {
  let searchParams = new URLSearchParams(locationSearch);
  const uniqueKey = new Date().getTime();
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function refreshContext(locationSearch, navigate) {
  let searchParams = new URLSearchParams(locationSearch);
  const uniqueKey = new Date().getTime();
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function setCustomFilter(locationSearch, navigate, filterList) {
  let searchParams = new URLSearchParams(locationSearch);

  filterList.forEach((element) => {
    const filterSel = `${encodeURIComponent(element.selectedFilter)}`;
    const filterVal = `${encodeURIComponent(
      element.selectedOperator + ":" + element.value
    )}`;
    searchParams.delete(filterSel);
    searchParams.set(filterSel, filterVal);
  });

  const uniqueKey = new Date().getTime();
  searchParams.delete("threadId"); // otherwise it will fetch a single topic
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

// CHange below when new filters are added

export function getActiveCustomFilter(locationSearch) {
  let searchParams = new URLSearchParams(locationSearch);
  const params = Object.fromEntries(searchParams.entries());

  let activeFilters = [];
  threadFilters.map((t) => {
    if (t.name in params) {
      activeFilters = [
        ...activeFilters,
        {
          selectedFilter: t.name,
          selectedOperator: params[t.name].split("%3A")[0],
          value: params[t.name].split("%3A")[1],
        },
      ];
    }
  });

  return activeFilters;
}

export function isCustomFilterApplied(locationSearch) {
  let searchParams = new URLSearchParams(locationSearch);
  const params = Object.fromEntries(searchParams.entries());
  const _threadFilters = threadFilters.map((t) => t.name);

  for (let i = 0; i < _threadFilters.length; i++) {
    if (params.hasOwnProperty(_threadFilters[i])) {
      return true;
    }
  }
  return false;
}

export function setResetCustomFilters(locationSearch, navigate) {
  let searchParams = new URLSearchParams(locationSearch);
  const _threadFilters = threadFilters.map((t) => t.name);
  for (let i = 0; i < _threadFilters.length; i++) {
    searchParams.delete(_threadFilters[i]);
  }

  const uniqueKey = new Date().getTime();
  searchParams.delete("_threadId");
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function resetFilters(navigate) {
  let searchParams = new URLSearchParams("");
  const uniqueKey = new Date().getTime();
  searchParams.delete("_k");
  searchParams.set("_k", uniqueKey);
  searchParams.set("view", "all");
  navigate(`${location.pathname}?${searchParams.toString()}`);
}
