"use client";

import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import toast from "react-hot-toast";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";

export function AutomationDataSourceManagement() {
  const [dataSources, setDataSources] = useState([
    { id: "1", name: "Users", type: "table", value: "users" },
    {
      id: "2",
      name: "Active Orders",
      type: "query",
      value: "SELECT * FROM orders WHERE status = 'active'",
    },
  ]);
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [newDataSource, setNewDataSource] = useState({
    name: "",
    type: "table",
    value: "",
  });
  const [editingDataSource, setEditingDataSource] = useState(null);

  const handleAddDataSource = () => {
    const id = Date.now().toString();
    setDataSources([...dataSources, { ...newDataSource, id }]);
    setNewDataSource({ name: "", type: "table", value: "" });
    setIsAddDialogOpen(false);

    toast.success(`${newDataSource.name} has been added successfully.`);
  };

  const handleEditDataSource = () => {
    if (editingDataSource) {
      setDataSources(
        dataSources.map((ds) =>
          ds.id === editingDataSource.id ? editingDataSource : ds
        )
      );
      setEditingDataSource(null);
      toast.success(`${editingDataSource.name} has been updated successfully.`);
    }
  };

  const handleRemoveDataSource = (id) => {
    setDataSources(dataSources.filter((ds) => ds.id !== id));
    toast.success("The data source has been removed successfully.");
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-2xl font-bold">Data Sources</h2>
        <Dialog open={isAddDialogOpen} onOpenChange={setIsAddDialogOpen}>
          <DialogTrigger asChild>
            <Button>Add Data Source</Button>
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add New Data Source</DialogTitle>
              <DialogDescription>
                Enter the details for the new data source.
              </DialogDescription>
            </DialogHeader>
            <div className="grid gap-4 py-4">
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="name" className="text-right">
                  Name
                </Label>
                <Input
                  id="name"
                  value={newDataSource.name}
                  onChange={(e) =>
                    setNewDataSource({ ...newDataSource, name: e.target.value })
                  }
                  className="col-span-3"
                />
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="type" className="text-right">
                  Type
                </Label>
                <select
                  id="type"
                  value={newDataSource.type}
                  onChange={(e) =>
                    setNewDataSource({ ...newDataSource, type: e.target.value })
                  }
                  className="col-span-3"
                >
                  <option value="table">Table</option>
                  <option value="query">Query</option>
                </select>
              </div>
              <div className="grid grid-cols-4 items-center gap-4">
                <Label htmlFor="value" className="text-right">
                  {newDataSource.type === "table" ? "Table Name" : "SQL Query"}
                </Label>
                <Textarea
                  id="value"
                  value={newDataSource.value}
                  onChange={(e) =>
                    setNewDataSource({
                      ...newDataSource,
                      value: e.target.value,
                    })
                  }
                  className="col-span-3"
                />
              </div>
            </div>
            <DialogFooter>
              <Button type="submit" onClick={handleAddDataSource}>
                Add Data Source
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>

      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Value</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {dataSources.map((dataSource) => (
            <TableRow key={dataSource.id}>
              <TableCell>{dataSource.name}</TableCell>
              <TableCell>{dataSource.type}</TableCell>
              <TableCell>{dataSource.value}</TableCell>
              <TableCell>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button
                      variant="outline"
                      className="mr-2"
                      onClick={() => setEditingDataSource(dataSource)}
                    >
                      Edit
                    </Button>
                  </DialogTrigger>
                  <DialogContent>
                    <DialogHeader>
                      <DialogTitle>Edit Data Source</DialogTitle>
                      <DialogDescription>
                        Make changes to the data source. This may affect
                        existing automations.
                      </DialogDescription>
                    </DialogHeader>
                    {editingDataSource && (
                      <div className="grid gap-4 py-4">
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Label htmlFor="edit-name" className="text-right">
                            Name
                          </Label>
                          <Input
                            id="edit-name"
                            value={editingDataSource.name}
                            onChange={(e) =>
                              setEditingDataSource({
                                ...editingDataSource,
                                name: e.target.value,
                              })
                            }
                            className="col-span-3"
                          />
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Label htmlFor="edit-type" className="text-right">
                            Type
                          </Label>
                          <select
                            id="edit-type"
                            value={editingDataSource.type}
                            onChange={(e) =>
                              setEditingDataSource({
                                ...editingDataSource,
                                type: e.target.value,
                              })
                            }
                            className="col-span-3"
                          >
                            <option value="table">Table</option>
                            <option value="query">Query</option>
                          </select>
                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                          <Label htmlFor="edit-value" className="text-right">
                            {editingDataSource.type === "table"
                              ? "Table Name"
                              : "SQL Query"}
                          </Label>
                          <Textarea
                            id="edit-value"
                            value={editingDataSource.value}
                            onChange={(e) =>
                              setEditingDataSource({
                                ...editingDataSource,
                                value: e.target.value,
                              })
                            }
                            className="col-span-3"
                          />
                        </div>
                      </div>
                    )}
                    <DialogFooter>
                      <Button type="submit" onClick={handleEditDataSource}>
                        Save Changes
                      </Button>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
                <AlertDialog>
                  <AlertDialogTrigger asChild>
                    <Button variant="destructive">Remove</Button>
                  </AlertDialogTrigger>
                  <AlertDialogContent>
                    <AlertDialogHeader>
                      <AlertDialogTitle>Are you sure?</AlertDialogTitle>
                      <AlertDialogDescription>
                        This action cannot be undone. This will permanently
                        delete the data source and may affect existing
                        automations.
                      </AlertDialogDescription>
                    </AlertDialogHeader>
                    <AlertDialogFooter>
                      <AlertDialogCancel>Cancel</AlertDialogCancel>
                      <AlertDialogAction
                        onClick={() => handleRemoveDataSource(dataSource.id)}
                      >
                        Continue
                      </AlertDialogAction>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
