import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import {
  handleStatusChange,
  messagingMainActionObj,
  messagingSecondaryActions,
} from "./leadTags";
import usePutRequest from "../../hooks/usePutRequest";
import { useEffect, useContext } from "react";
import { GlobalStateContext } from "../../state/globalState";
import { refreshContext } from "../data/messagingSelect";
import { useNavigate, useSearchParams } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MessagingActionsStatusBtn({ selectedTopic }) {
  const globalcontext = useContext(GlobalStateContext);
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();

  const { doPut: doPutMsgStatusChange } = usePutRequest("messagingthread");

  return (
    <>
      {typeof selectedTopic != "undefined" && (
        <div className="inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className={`relative inline-flex items-center rounded-l-md bg-${
              messagingMainActionObj(selectedTopic).color
            }-500 px-3 py-2 text-sm font-semibold text-white hover:bg-${
              messagingMainActionObj(selectedTopic).color
            }-600`}
            onClick={() =>
              handleStatusChange(
                globalcontext,
                messagingMainActionObj(selectedTopic).value,
                selectedTopic,
                doPutMsgStatusChange
              )
            }
          >
            {messagingMainActionObj(selectedTopic).actionLabel}
          </button>
          <Menu as="div" className="relative -ml-px block">
            <MenuButton
              className={`relative inline-flex items-center rounded-r-md bg-${
                messagingMainActionObj(selectedTopic).color
              }-500 px-2 py-2 text-white hover:bg-${
                messagingMainActionObj(selectedTopic).color
              }-600`}
            >
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </MenuButton>
            <MenuItems
              transition
              className="absolute right-0 z-10 -mr-1 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
              <div className="py-1">
                {messagingSecondaryActions(selectedTopic).map((item) => (
                  <MenuItem key={item.name}>
                    {({ focus }) => (
                      <a
                        href="#"
                        onClick={() =>
                          handleStatusChange(
                            globalcontext,
                            item.value,
                            selectedTopic,
                            doPutMsgStatusChange
                          )
                        }
                        className={classNames(
                          focus ? "bg-gray-100 text-gray-900" : "text-gray-700",
                          "block px-4 py-2 text-sm"
                        )}
                      >
                        {item.actionLabel}
                      </a>
                    )}
                  </MenuItem>
                ))}
              </div>
            </MenuItems>
          </Menu>
        </div>
      )}
    </>
  );
}
