import moment from "moment";
import "moment/dist/locale/pt";
moment.locale("pt");

function updateRequestParams(prevReqParams, newParams) {
  delete newParams._k;
  for (const field of Object.keys(newParams)) {
    if (!(field in prevReqParams)) return true;
    if (prevReqParams[field] != newParams[field]) return true;
  }
  return false;
}

function forceRefresh(prevReqParams, newParams) {
  if (prevReqParams._k != newParams._k) return true;
  else return false;
}

export function parseMessagingUrl(
  searchParams,
  navigate,
  doGetMessagingTopics,
  doGetMessagingThread,
  isCompleteMessagingTopics,
  isCompleteMessagingThread,
  getRMessagingTopics,
  getRMessagingThread
) {
  const newSearchParams = new URLSearchParams(searchParams);
  const newParams = Object.fromEntries(newSearchParams.entries());
  const oldParams = getRMessagingTopics.params;

  let _threadId = newSearchParams.get("threadId");

  if (newSearchParams.get("view") == null) {
    goToCategory(searchParams, navigate, "all");
    return;
  }

  // we need to capture when user is selecting a specific threadId
  // fetch single topic
  if (_threadId != null) {
    doGetMessagingThread({
      threadId: _threadId,
    });

    if (!isCompleteMessagingTopics)
      doGetMessagingTopics({
        page: 1,
        entries: 30,
        ...newParams,
      });
  } else if (
    forceRefresh(oldParams, newParams) || // _k changed
    updateRequestParams(oldParams, newParams) // parameter was changed changed
  )
    doGetMessagingTopics({
      page: 1,
      entries: 30,
      ...newParams,
    });
}

export function loadMoreTopics(
  searchParams,
  itemsMessagingTopics,
  doGetMessagingTopics
) {
  const newSearchParams = new URLSearchParams(searchParams);

  if (itemsMessagingTopics.nextPage > 0)
    doGetMessagingTopics({
      page: itemsMessagingTopics.nextPage,
      entries: 20,
      ...Object.fromEntries(newSearchParams.entries()),
    });
}

export function fetchTopic() {}


export function loadTopic(
  topicData,
  setSelectedTopic,
  topicsRead,
  setTopicRead
) {
  // Set topics as read
  if (topicsRead.indexOf(topicData.id) == -1)
    setTopicRead([...topicsRead, topicData.id]);
  setSelectedTopic(topicData);
}

export function categorySelected(searchParams) {
  const newSearchParams = new URLSearchParams(searchParams);
  let _view =
    newSearchParams.get("view") == null 
    ? "all" : 
    newSearchParams.get("view");

  return _view;
}

export function goToCategory(_searchParams, navigate, category) {
  let searchParams = new URLSearchParams("");
  const uniqueKey = new Date().getTime();
  searchParams.set("_k", uniqueKey);
  searchParams.set("view", category);
  navigate(`${location.pathname}?${searchParams.toString()}`);
}

export function handleSelectTopic(searchParams, navigate, threadId) {
  const newSearchParams = new URLSearchParams(searchParams);
  newSearchParams.set("threadId", threadId);
  newSearchParams.delete("_k");
  newSearchParams.set("_k", new Date().getTime());
  const newURL = `${location.pathname}?${newSearchParams.toString()}`;
  navigate(newURL, { replace: true });
}
