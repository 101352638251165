import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Badge } from "@/components/ui/badge";
import { Send, TagIcon } from "lucide-react";
import dayjs from "dayjs";

const IconTag = ({ status, className, icon: Icon = Send }) => {
  return (
    <Badge
      variant={"success"}
      className={`rounded-full bg-slate-100 h-8 w-8 p-2 ${className}`}
    >
      <Icon
        aria-hidden="true"
        className={`h-6 w-6 ${
          status === "success" ? "text-sky-600" : "text-red-500"
        }`}
      />
    </Badge>
  );
};

const ToolTipMessage = ({ content, status, icon: Icon = Send }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger>
          <IconTag icon={Icon} status={status} />
        </TooltipTrigger>
        <TooltipContent className="max-w-xl">
          <p className="text-sm ">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export function AutomationEventLogsStepsTable({ events }) {
  return (
    <div className="flow-root">
      <ul role="list" className="-mb-8">
        {events.map((sms, index) => (
          <li key={index}>
            <div className="relative pb-8">
              {index !== events.length - 1 ? (
                <span
                  aria-hidden="true"
                  className="absolute left-4 top-2 -ml-px h-full w-0.5 bg-gray-200"
                />
              ) : null}
              <div className="relative flex items-start space-x-2">
                {sms.action_type === "send_sms" ? (
                  <>
                    {sms.result.content != null && sms.result.content != "" ? (
                      <ToolTipMessage
                        content={sms.result.content}
                        status={sms.result.status}
                        icon={Send}
                      />
                    ) : (
                      <IconTag icon={Send} status={sms.result.status} />
                    )}

                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        SMS to {sms.result.destination}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        {sms.result.message}
                      </p>
                    </div>
                  </>
                ) : sms.action_type === "wait_for_sms_response" ? (
                  <>
                    <IconTag icon={TagIcon} status={sms.result.status} />
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        Waiting for response from {sms.result.destination}
                      </p>
                      <p className="mt-1 text-xs text-gray-500">
                        Status:{" "}
                          {sms.result.message}
                      </p>
                    </div>
                  </>
                ) : sms.action_type === "sms_received" ? (
                  <>
                    <IconTag icon={Send} status={sms.result.status} className={'rotate-180'}/>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-medium text-gray-900">
                        Received Response {sms.result.destination}
                      </p>
                      <p className="text-xs flex flex-col">
                        <span className="text-gray-500">{dayjs(sms.result.date).format("DD-MM-YY H:mm")}</span>
                        <span className="text-gray-5  00 mt-1">Mensagem:<span className="ml-1 text-gray-600">{sms.result.rec_msg}</span></span>
                      </p>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
