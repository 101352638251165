import { useState, useContext, useEffect } from 'react';
import { postJson, blankResult, reqOk, loadingScreen } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';
import { useAuth } from "./useAuth";


function usePostRequest( action ) {

  let auth = useAuth();
  const [postR, setPostR] = useState(blankResult);
  const _gc = useContext(GlobalStateContext);

   useEffect(() => {
      loadingScreen(postR, _gc)
  }, [postR]); 

  function handleRequest( payload ) {
    postJson(
      apiUrl[action],
      { ...postR,  payload: payload, token: auth.token },
      setPostR
    );
  };



  return {
    doPost: handleRequest,
    postR,
    setPostR,
    isComplete: reqOk(postR),
    items: reqOk(postR) ? postR.items : null
  }

}

export default usePostRequest;