import { useState, useContext, useEffect } from 'react';
import { deleteJson, blankResult, reqOk, loadingScreen } from '../common/fetchdata';
import { apiUrl } from '../common/apiEndpoints';
import { GlobalStateContext } from '../state/globalState';
import { useAuth } from "./useAuth";


function useDeleteRequest( action ) {


  let auth = useAuth();
  const [deleteR, setDeleteR] = useState(blankResult);
  const _gc = useContext(GlobalStateContext);

   useEffect(() => {
      loadingScreen(deleteR, _gc)
  }, [deleteR]); 

  function handleRequest( payload ) {
    deleteJson(
      apiUrl[action],
      { ...deleteR,  params: payload, token: auth.token },
      setDeleteR
    );
  };



  return {
    doDelete: handleRequest,
    deleteR,
    setDeleteR,
    isComplete: reqOk(deleteR),
    items: reqOk(deleteR) ? deleteR.items : null
  }

}

export default useDeleteRequest;