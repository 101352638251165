import { newMessageToastWithAction, successToast } from "../../common/toasts";

export function notifyMe() {
  if (!("Notification" in window)) {
    // Check if the browser supports notifications
    alert("This browser does not support desktop notification");
  } else if (Notification.permission === "granted") {
    // Check whether notification permissions have already been granted;
    // if so, create a notification
    const notification = new Notification("Hi there!");
    // …
  } else if (Notification.permission !== "denied") {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        const notification = new Notification("Hi there!");
        // …
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
}

export function supportNotification() {
  if (!("Notification" in window)) return false;
  else return true;
}

export function notificationState() {
  return "Notification" in window && Notification.permission;
}

export function enableNotifications(callback) {
  if ("Notification" in window)
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        callback();
      }
    });
}

export function notificationsEnabled() {
  if ("Notification" in window) {
    successToast("Notificações habilitadas");
    new Notification("Notificações habilitadas");
  }
}

export function broadcastNotification(data, navigate) {
  const _nSeverity = data.severity;
  const _nAudience = data.audience;
  const _nLabel = data.label;

  let _nType = "ACTIONS_BELOW";
  let actionFcn = "";
  let actionLabel = "Confirmar";
  let _data = {};
  let _nMessageTitle = "";
  let _nMessageContent = "";
  let _trigNotif = {
    severity: _nSeverity,
    audience: _nAudience,
    label: _nLabel,
  };


  let issueNotification = false;

  if (_nLabel == "NEW_CONTACT_SMS") {
    _data = JSON.parse(data.data);
    _nMessageTitle = `Nova mensagem de ${_data.contact_message_thread.customers_web.phone_contact.phone_loc}`;
    _nType = "ACTIONS_BELOW";
    _nMessageContent = `${_data.content}`;
    actionLabel = "Ver Mensagem";
    issueNotification = true;
    const newSearchParams = new URLSearchParams("");
    newSearchParams.set("view", "all");
    newSearchParams.set("threadId", _data.contact_message_thread.id);
    newSearchParams.set("_k", new Date().getTime());
    actionFcn = `${location.pathname}?${newSearchParams.toString()}`;
  }

  if (_nLabel == "NEW_CONTACT_EMAIL") {
    _data = JSON.parse(data.data);
    _nMessageTitle = `Nova mensagem de ${_data.contact_message_thread.customers_web.phone_contact.phone_loc}`;
    _nType = "ACTIONS_BELOW";
    _nMessageContent = `${_data.content}`;
    actionLabel = "Ver Mensagem";
    issueNotification = true;
    const newSearchParams = new URLSearchParams("");
    newSearchParams.set("view", "all");
    newSearchParams.set("threadId", _data.contact_message_thread.id);
    newSearchParams.set("_k", new Date().getTime());
    actionFcn = `${location.pathname}?${newSearchParams.toString()}`;
  }

  if (_nLabel == "NEW_BOOK_REQUEST") {
    _nMessageTitle = "Novo Pedido de Marcação de Consulta";
    _nType = "ACTIONS_BELOW";
    _data = JSON.parse(data.data);
    _nMessageContent = `${_data.first_name} ${_data.last_name}`;
    actionLabel = "Ver Pedido";
    issueNotification = true;
    const newSearchParams = new URLSearchParams("");
    newSearchParams.set("view", "all");
    newSearchParams.set("threadId", _data.contact_message_thread_id);
    newSearchParams.set("_k", new Date().getTime());
    actionFcn = `${location.pathname}?${newSearchParams.toString()}`;
  }

  if (_nLabel == "MSG_THREAD_ASSIGN") {
    _nMessageTitle = "Uma lead foi assignada a si";
    _nType = "ACTIONS_BELOW";
    _data = JSON.parse(data.data);
    _nMessageContent = `${_data.customers_web.phone_loc}`;
    actionLabel = "Ver Lead";
    issueNotification = true;
    const newSearchParams = new URLSearchParams("");
    newSearchParams.set("view", "all");
    newSearchParams.set("threadId", _data.id);
    newSearchParams.set("_k", new Date().getTime());
    actionFcn = `${location.pathname}?${newSearchParams.toString()}`;
  }

  if (_nLabel == "NEW_PHONE_IMPORT") {
    _nMessageTitle = "Importação Registo telefónico";
    _nType = "ACTIONS_BELOW";
    _data = JSON.parse(data.data);
    //console.log(_data)
    let nonAnsweredNums = [];
    _data.detail.map((d) => {
      if (d.pickup == "not-connected" && d.bound == "outbound") {
        nonAnsweredNums = [...nonAnsweredNums, d.relevant_phone_number];
      }
    });
    _nMessageContent = `${
      _data.inbound_not_conn
    } chamadas não atendidas - ${nonAnsweredNums.toString()}`;
    actionLabel = "Ver Chamadas";
    if (_data.inbound_not_conn > 0) issueNotification = true;
    const newSearchParams = new URLSearchParams("");
    newSearchParams.set("screen", "results");
    newSearchParams.set("_k", new Date().getTime());
    newSearchParams.set("hasPendContact", "0");
    actionFcn = `${location.pathname}?${newSearchParams.toString()}`;
  }



  if (issueNotification) {
    /*         setTriggerNotification({
            ..._trigNotif,
            title: _nMessageTitle,
            body: _nMessageContent,
            type: _nType,
            actionFcn: actionFcn,
            actionLabel: actionLabel
        }) */

    if (_nSeverity == "info") {
      newMessageToastWithAction(
        _nMessageContent,
        () => navigate(actionFcn, { replace: true }),
        "Ver"
      );
      if ("Notification" in window) {
        const notification = new Notification(_nMessageTitle, {
          body: _nMessageContent,
          icon: "../static/logo_square.png",
        });
        notification.onclick = (event) => {
          event.preventDefault(); // prevent the browser from focusing the Notification's tab
          navigate(actionFcn, { replace: true });
        };
      }
    }
  }

  //const notification = new Notification(text);
}

function handleResetNotificationCount() {}
