import React from "react";
import MessagingActions from "../../MessagingActions";
import InboxChatContent from "./InboxChatContent";
import MessagingReplyContainer from "../../sender/MessagingReplyContainer";

export default function InboxChatContentPanel({
  selectedTopic,
  selectBatch,
  topicLoadingState,
  setSelectBatch,
  showContactInfo,
  setShowContactInfo,
  itemsInboxCategories
}) {
  return (
    <div className="flex flex-col" style={{ height: "calc(100vh - 65px)" }}>
      
      <div className="flex-none min-h-[60px]">
        <MessagingActions
          selectedTopic={selectedTopic}
          selectBatch={selectBatch}
          showContactInfo={showContactInfo}
          setShowContactInfo={setShowContactInfo}
          itemsInboxCategories={itemsInboxCategories}
        />
      </div>
      <div className="flex-1 min-h-[100px] overflow-auto">
        <InboxChatContent
          selectedTopic={selectedTopic}
          topicLoadingState={topicLoadingState}
          selectBatch={selectBatch}
          setSelectBatch={setSelectBatch}
          showContactInfo={showContactInfo}
        />
      </div>
      <div className="flex-none min-h-[160px]">
        <MessagingReplyContainer
          selectedTopic={selectedTopic}
          itemsInboxCategories={itemsInboxCategories}
        />
      </div>
    </div>
  );
}
