import React from "react";

import { tzbe, rgxtz } from "../../state/globalui/timezoneConfig";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
moment.locale("pt");

export default function MessagingViewersBubble({ readReceipts }) {
  return (
    <>
      <ul
        role="list"
        className="mt-2 divide-y divide-gray-200 border-b border-t border-gray-200 w-full"
      >
        {readReceipts.map((item) => (
          <li key={item.id} className="flex items-center justify-between py-3">
            <div className="flex items-center">
              <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                <span className="text-sm font-medium leading-none text-white">
                  {item.users.initials}
                </span>
              </span>
              <p className="ml-4 text-sm font-medium text-gray-900">
                {item.users.fullname}
              </p>
            </div>
            <time
              dateTime={item.created_at}
              className="flex-none text-xs text-gray-500"
            >
              {moment(item.created_at).fromNow()}
            </time>
          </li>
        ))}
      </ul>
    
    </>
  );
}
