import React, { createContext, useContext, useState, useEffect } from "react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import { successToast } from "../common/toasts";
import { useAuth } from "../hooks/useAuth";
import { broadcastNotification } from "../state/notifications/notificationLib";

moment.locale("pt");

const SocketContext = createContext();

export const useSocket = () => {
  return useContext(SocketContext);
};

export const SocketProvider = ({ children }) => {
  let navigate = useNavigate();
  const auth = useAuth();
  
 
  const [socket, setSocket] = useState(null);
  const [socketState, setSocketState] = useState({});
  const [socketRefreshTopics, setSocketRefreshTopics] = useState(
    () => () => {}
  );
  const [socketRefreshNotifications, setSocketRefreshNotifications] = useState(
    () => () => {}
  );

   /* 
   
  useEffect(() => {
    let socketConn;

    if (auth.token) {
      // Establish the connection only if the token is valid
      socketConn = io("", {
        reconnectionDelayMax: 5000,
        auth: {
          token: auth.token, // Pass the token to the backend
        },
      });

      setSocket(socketConn);

      socketConn.on("connect", () => {
        successToast("Ligado ao servidor");
        setSocketState({
          id: socketConn.id,
          connected: socketConn.connected,
        });

        // Send a PING every 30 seconds
        const pingInterval = setInterval(() => {
          socketConn.emit("PING", { date: moment().format() });
        }, 2500);

        // Clear the interval when the socket disconnects
        socketConn.on("disconnect", () => {
          setSocketState({
            id: socketConn.id,
            connected: socketConn.connected,
          });
          clearInterval(pingInterval);
        });
      });

      // Handle authentication errors from the server
      socketConn.on("connect_error", (err) => {
        console.error("Connection error:", err.message);
        setSocketState({
          id: socketConn.id,
          connected: socketConn.connected,
        });
        // Optionally, you can handle token invalidation here
      });
    }

    return () => {
      if (socketConn) {
        socketConn.disconnect();
        setSocket(null);
        setSocketState({
          id: socketConn.id,
          connected: socketConn.connected,
        });
      }
    };
  }, [auth.token]); // Re-run the effect when the token changes

  const handleMessageThreadUpdate = (data) => {
    const parsedData = JSON.parse(data.results);
    socketRefreshTopics(parsedData);
  };

  const handleNotificationsUpdate = (data) => {
    socketRefreshNotifications(data);
  };

  useEffect(() => {
    if (socket) {
      socket.on("USER_NOTIFY", (data) => broadcastNotification(data, navigate));
      socket.on("NOTIFY_UPDATE", (data) =>
        broadcastNotification(data, navigate)
      );

      socket.on("MESSAGE_THREAD_UPDATE", handleMessageThreadUpdate);
      return () => {
        socket.off("MESSAGE_THREAD_UPDATE", handleMessageThreadUpdate);
      };
    }
  }, [socket, socketRefreshTopics]);

  useEffect(() => {
    if (socket) {
      socket.on("NOTIFICATION_UPDATE", handleNotificationsUpdate);
      return () => {
        socket.off("NOTIFICATION_UPDATE", handleNotificationsUpdate);
      };
    }
  }, [socket, socketRefreshNotifications]);

   */

  const value = {
    socket,
    socketState,
    setSocketRefreshTopics,
    setSocketRefreshNotifications,
  };

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
};
