import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import useGetRequest from "../../hooks/useGetRequest";
import { FlexibleTable } from "../../../components/table/FlexibleTable";
import { useEffect, useState } from "react";

export default function AutomationEventsSourceData({ srcData }) {
  const [cols, setCols] = useState([]);

  const {
    doGet: doGetEventSrcData,
    items: srcDataContent,
    isComplete: isCompleteEventSrcData,
  } = useGetRequest("automationEventSrcData");

  const frontendTableConfig = {
    columns: cols,
    initialSort: {
      key:
        cols.find((f) => f.event_field_type == "event_date") != null
          ? cols.find((f) => f.event_field_type == "event_date").field_name
          : "key",
      direction: "asc",
    },
    selectable: false,
    columnSelector: true,
    canDownload: true, 
    pagination: {
      itemsPerPage: 5,
      itemsPerPageOptions: [5],
    },
  };

  useEffect(() => {
    if (isCompleteEventSrcData) {
      const _cols = srcDataContent.data_fields.map((dc) => ({
        key: dc.field_name,
        header: dc.field_name,
        sortable: false,
        filterable: false,
        headerTextSize: 10,
        render: (e) => <span className="text-[10px]">{e}</span>,
        ...dc,
      }));
      setCols(_cols);
    }
  }, [isCompleteEventSrcData]);

  return (
    <div className="flex flex-col">
      {srcData.map((d, i) => (
        <Dialog key={i}>
          <DialogTrigger asChild>
            <Button
              variant="ghost"
              onClick={() => doGetEventSrcData({ id: d.id })}
            >
              {d.n_records}
            </Button>
          </DialogTrigger>
          <DialogContent className=" max-w-4xl">
            <DialogHeader>
              <DialogTitle>View Data</DialogTitle>
              {isCompleteEventSrcData && cols.length > 0 && (
                <FlexibleTable
                  isLoading={isCompleteEventSrcData}
                  data={!isCompleteEventSrcData ? [] : srcDataContent.data.data}
                  config={frontendTableConfig}
                />
              )}
            </DialogHeader>
          </DialogContent>
        </Dialog>
      ))}
    </div>
  );
}
