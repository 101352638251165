import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { errorToast, successToast } from "../toasts";
import { useSearchParams } from "react-router-dom";
import { filterDataTypes, filterDataTypeOperatorLabel } from "./filterconst";
import { getActiveCustomFilter, isCustomFilterApplied } from "../../messaging/data/messagingSelect";

const FilterComponent = forwardRef(({ filters }, ref) => {
  const [filterList, setFilterList] = useState([]);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if(isCustomFilterApplied(searchParams)){
      const _activeFilters = getActiveCustomFilter(searchParams)
      if(_activeFilters.length > 0) setFilterList(_activeFilters)
    }
  },[searchParams])


  const addFilter = () => {
    setFilterList([
      ...filterList,
      { selectedFilter: "", selectedOperator: "", value: "" },
    ]);
  };

  const updateFilter = (idx, key, value) => {
    const newFilterList = [...filterList];
    newFilterList[idx][key] = value;
    setFilterList(newFilterList);
  };

  const removeFilter = (idx) => {
    const newFilterList = [...filterList];
    newFilterList.splice(idx, 1);
    setFilterList(newFilterList);
  };

  const getOperators = (filterDataType) => {
    const dataType = filterDataTypes.find(
      (type) => type.type === filterDataType
    );
    return dataType ? dataType.operators : [];
  };

  const getInputType = (filterDataType) => {
    const dataType = filterDataTypes.find(
      (type) => type.type === filterDataType
    );
    return dataType ? dataType.input : "input";
  };

  const getFilterOptions = (filterName) => {
    const filter = filters.find((filter) => filter.name === filterName);
    return filter ? filter.options : [];
  };

  const handleSubmit = () => {
    if (filterList.length === 0) {
      errorToast("Nenhum filtro definido.");
      return false;
    }

    for (const filter of filterList) {
      if (!filter.selectedFilter || !filter.selectedOperator || !filter.value) {
        errorToast("Todos os filtros devem ser preenchidos corretamente.");
        return false;
      }
    }

    // Proceed with form submission logic
    successToast("Filtros guardados com sucesso!");
    return true;
  };

  useImperativeHandle(ref, () => ({
    handleSubmit,
    getFilterList: () => filterList,
  }));

  const usedFilters = filterList.map((filter) => filter.selectedFilter);

  return (
    <div className="w-full">
      <div className="w-full">
        <ul role="list" className="divide-y divide-gray-100 w-full">
          {filterList.map((_filt, idx) => (
            <li
              key={idx}
              className="shadow-sm border flex flex-col md:flex-row justify-between gap-4 py-5 bg-gray-50 px-4 rounded-md mb-4"
            >
              <div className="flex flex-col md:w-1/4">
                <select
                  value={_filt.selectedFilter}
                  onChange={(e) =>
                    updateFilter(idx, "selectedFilter", e.target.value)
                  }
                  className="p-2 text-sm mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Selecionar Filtro</option>
                  {filters.map((filter) => (
                    <option
                      key={filter.name}
                      value={filter.name}
                      disabled={usedFilters.includes(filter.name)}
                    >
                      {filter.key}
                    </option>
                  ))}
                </select>
              </div>
              <div className="flex flex-col md:w-3/12">
                <select
                  value={_filt.selectedOperator}
                  onChange={(e) =>
                    updateFilter(idx, "selectedOperator", e.target.value)
                  }
                  disabled={!_filt.selectedFilter}
                  className="p-2 text-sm mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                >
                  <option value="">Selecionar Operador</option>
                  {_filt.selectedFilter &&
                    getOperators(
                      filters.find(
                        (filter) => filter.name === _filt.selectedFilter
                      ).filterDataType
                    ).map((operator) => (
                      <option key={operator} value={operator}>
                        {
                          filterDataTypeOperatorLabel.find(
                            (op) => op.key === operator
                          ).label
                        }
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col md:w-1/4">
                {_filt.selectedFilter &&
                  (() => {
                    const filter = filters.find(
                      (filter) => filter.name === _filt.selectedFilter
                    );
                    const inputType = getInputType(filter.filterDataType);
                    if (inputType === "input") {
                      return (
                        <input
                          type="text"
                          value={_filt.value}
                          onChange={(e) =>
                            updateFilter(idx, "value", e.target.value)
                          }
                          className="mt-2 p-2 text-sm block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      );
                    } else if (inputType === "select") {
                      return (
                        <select
                          value={_filt.value}
                          onChange={(e) =>
                            updateFilter(idx, "value", e.target.value)
                          }
                          className="p-2 text-sm mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          <option value="">Selecionar Valor</option>
                          {getFilterOptions(_filt.selectedFilter).map(
                            (option) => (
                              <option key={option.name} value={option.name}>
                                {option.value}
                              </option>
                            )
                          )}
                        </select>
                      );
                    } else if (inputType === "dateinput") {
                      return (
                        <input
                          type="date"
                          value={_filt.value}
                          onChange={(e) =>
                            updateFilter(idx, "value", e.target.value)
                          }
                          className="mt-2 p-2 text-sm block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      );
                    }
                  })()}
              </div>
              <div className="flex flex-col md:w-1/12 items-end">
                <button
                  type="button"
                  onClick={() => removeFilter(idx)}
                  className="mt-3 relative flex h-6 w-6 items-center justify-center rounded-full bg-white text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                >
                  <span className="absolute -inset-1.5" />
                  <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </div>
            </li>
          ))}
        </ul>
        <button
          onClick={addFilter}
          type="button"
          className="flex gap-x-1 rounded-md bg-indigo-50 px-2.5 py-1.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
        >
          <PlusIcon className="h-5 w-5" aria-hidden="true" />
          Novo filtro
        </button>
      </div>
    </div>
  );
});

export default FilterComponent;
