import React  from 'react'
import moment from 'moment-timezone'
import 'moment/dist/locale/pt';
moment.locale('pt');
import { CheckIcon  } from '@heroicons/react/20/solid';
import { smsStatus } from '../../../common/messaging/messagingVars';
import { msgChannel, msgDeliveryState } from '../../data/messagingStatus';

function getState(status) {
    const smsState = smsStatus.filter(s => s.status == status)
    if (smsState.length == 0) return 'PENDING'
    else return smsState[0].state
}

export default function ChatCustomerCommContent  ({ _item }) {
    return (
      <>
        <div
          style={{ minWidth: "20rem" }}
          className={
            `relative flex-col leading-1.5 rounded-xl ` +
            `${
              _item.outgoing_direction
                ? "rounded-br-none bg-gray-100 "
                : "rounded-bl-none bg-sky-100 "
            }` +
            `${
                getState(_item.status) == "FAILED"
                ? "border-red-200 bg-red-100"
                : "border-gray-200"
            }`
          }
        >
          <div className="p-4 mb-4">
            {_item.channel == "EMAIL" ? (
              <div
                className="text-sm text-gray-600"
                dangerouslySetInnerHTML={{ __html: _item.content }}
              />
            ) : (
              <p className="text-sm font-normal text-gray-900 ">
                {`${_item.content}`}
              </p>
            )}
          </div>

          <span
            className={`absolute bottom-1 ${
              _item.outgoing_direction ? "left-3" : "right-3"
            }`}
          >
            <time
              dateTime={moment(_item.created_at).format()}
              className="text-[10px] font-normal text-gray-500"
            >
              {moment(_item.created_at).isSame(moment(), "day")
                ? moment(_item.created_at).format("HH:mm")
                : moment(_item.created_at).format("D MMM YYYY, HH:mm")}
            </time>
          </span>

          <span
            className={`absolute bottom-0 ${
              _item.outgoing_direction ? "right-2" : "left-2"
            }`}
          >
            {_item.outgoing_direction && (
              <>
                {msgDeliveryState.filter((i) => i.key == getState(_item.status))
                  .length > 0 &&
                  msgDeliveryState.map(
                    (m, i) =>
                      getState(_item.status) == m.key && (
                        <div
                          key={i}
                          className="flex tooltip"
                          data-tip={m.label}
                        >
                          <CheckIcon
                            className={`h-4 w-4 text-${m.color}-800`}
                          />
                        </div>
                      )
                  )}
              </>
            )}
          </span>
        </div>
        <div
          className={`align-bottom h-full flex ${
            _item.outgoing_direction ? "ml-2" : "mr-2"
          }`}
        >
          <div className="rounded-full bg-gray-200 h-5 w-5">
            {msgChannel.map(
              (m, i) =>
                m.key == _item.channel && (
                  <div key={i} className="tooltip" data-tip={m.label}>
                    <m.icon className="m-1 h-3 w-3 text-gray-400 " />
                  </div>
                )
            )}
          </div>
        </div>
      </>
    );
  };