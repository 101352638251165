import React from "react";
import ProviderAppointmentManagement from "./ProviderAppointmentManagement";

export default function AppointmentManagement({ token }) {
  
  return (
    <>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Configurações de consulta
          </h2>
          <p className="mt-1 text-sm leading-6 text-gray-500">
            É possivel configurar as definições por médico e tipo de consulta.
          </p>
        </div>
      </div>
      <div className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6" />
      <ProviderAppointmentManagement />
    </>
  );
}
