//import { formfield_editspace as fin } from '../../forms/formfield_space';
import React from "react";
import { CheckCircleIcon } from "@heroicons/react/20/solid";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import AvatarXSStack from "../card/AvatarXSStack";
import {
  formfield_editogrp as f_edit,
  formfield_editogrp_operators as f_edit_ops,
  formfield_editogrp_resources as f_edit_res,
} from "../../forms/formfield_operatorgroup";

moment.locale("pt");

export const tableconfig = {
  edit: {
    showEditButton: false,
    type: "slideover",
    template: f_edit,
  },
  actionButton: {
    actionsLabel: "Actions",
    items: [
      {
        label: "Modificar",
        action: "@edit",
      },
      {
        label: "Editar Operadores",
        action: "@slideover",
        slideover: {
          template: f_edit_ops,
        },
      },
      {
        label: "Editar Recursos",
        action: "@slideover",
        slideover: {
          template: f_edit_res,
        },
      },
    ],
  },
  view: {
    title: "Grupos de Operador",
    description: "",
    type: "slideover",
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: "id",
          label: "ID",
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: "name",
          label: "Nome",
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: "id",
      slideoverView: true,
      label: "ID",
    },
    {
      key: "name",
      slideoverView: true,
      label: "Nome",
    },
    {
      key: "description",
      slideoverView: true,
      label: "Descrição",
    },
    {
      key: "users",
      slideoverView: true,
      label: "Operadores",
      valueformat: (e) => {
        return (
          <>
            <AvatarXSStack persons={e} />
          </>
        );
      },
    },
    {
      key: "providers",
      slideoverView: true,
      label: "Recursos",
      valueformat: (e) => {
        return (
          <>
            <AvatarXSStack persons={e} />
          </>
        );
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [],
  },
};
