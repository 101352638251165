import LookingForIllustration from "../../../common/illustration/LookingForIllustration";


export default function MessagingNoTopicSelected() {
    return (
        <div className="text-center p-16">

           
           <LookingForIllustration/>

            <h3 className="mt-2 text-2xl font-semibold text-gray-900">Nenhum Tópico</h3>
            <p className="mt-1 pt-6 text-sm text-gray-500">Escolha um tópico na lista à esquerda.</p>

        </div>
    )
}
