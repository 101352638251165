import React, {
  Fragment,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import moment from "moment-timezone";
import "moment/dist/locale/pt";
import AvatarXs from "../../../common/card/AvatarXs";
moment.locale("pt");

export default function ChatInternalCommentContent({ _item, showContactInfo }) {
  return (
    <>
      <div className="flex gap-x-3">
      <span className="text-xs font-medium mt-1.5">Nota Interna</span>
        <div className="tooltip" data-tip={_item.user_fullname}>
         
          <AvatarXs
            size={5}
            fontSize={"[8px]"}
            person={{
              avatar_url_sm: null,
              initials: _item.user_initials,
            }}
          />
        </div>
        <div className="bg-gray-100 rounded-lg p-3 text-sm border border-gray-200 w-48">
          <div className="flex relative">
            <span className={`text-gray-400/50 ${showContactInfo ? '' : 'pb-6'}`}>{_item.content}</span>
            {!showContactInfo && (
              <div className="hidden xl:flex font-normal text-xs absolute bottom-0 right-0">
                <time
                  dateTime={moment(_item.created_at).format()}
                  className="text-[10px] font-normal text-gray-500"
                >
                  {moment(_item.created_at).isSame(moment(), "day")
                    ? moment(_item.created_at).format("HH:mm")
                    : moment(_item.created_at).format("D MMM YYYY, HH:mm")}
                </time>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
