import React from "react";
import useGetRequest from "../../hooks/useGetRequest";
import usePostRequest from "../../hooks/usePostRequest";
import { GlobalStateContext } from "../../state/globalState";
import { useContext, useEffect } from "react";
import { tableconfig as tbcfg } from "../../common/configs/tableconfig_customerportal_bookings";
import TableEngineTw from "../../common/table/TableEngineTw";

export default function CustomerPortalBookings() {
  const globalcontext = useContext(GlobalStateContext);
  const {
    doGet: doGetBookings,
    isComplete: isCompleteBookings,
    items: itemsBookings,
  } = useGetRequest("customerPortalBookings");

  useEffect(() => {
    doGetBookings();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Marcações
          </h1>
          <p className="mt-2 text-sm text-gray-700">Listagem de marcações</p>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <TableEngineTw
          cfg={tbcfg}
          items={isCompleteBookings && itemsBookings}
          fetchData={doGetBookings}
        />
      </div>
    </div>
  );
}
