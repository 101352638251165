export const tableconfig = {
  edit: {
    showEditButton: false,
    type: "slideover",
  },
  view: {
    title: "Informações",
    description: "",
    type: "slideover",
    template: {
      nCols: 2,
      data: [
        {
          colSpan: 1,
          key: "id",
          label: "ID",
          valueformat: (e) => {
            return e;
          },
        },
        {
          colSpan: 1,
          key: "name",
          label: "Nome",
          valueformat: (e) => {
            return e;
          },
        },
      ],
    },
  },
  columns: [
    {
      key: "id",
      slideoverView: true,
      label: "id",
    },
    {
      key: "email",
      slideoverView: true,
      label: "Tipo",
    },
    {
      key: "email_confirmed_at",
      slideoverView: true,
      label: "Centro",
    },
    {
      key: "last_sign_in_at",
      slideoverView: true,
      label: "Médico",
    },
    {
      key: "profiles",
      slideoverView: true,
      label: "Nome",
      valueformat: (e) => {
        return `${e.firstname}`;
      },
    },
    {
      key: "profiles",
      slideoverView: true,
      label: "Apelido",
      valueformat: (e) => {
        return `${e.lastname}`;
      },
    },
    {
      key: "profiles",
      slideoverView: true,
      label: "date_of_birth",
      valueformat: (e) => {
        return `${e.date_of_birth}`;
      },
    },
    {
      key: "profiles",
      slideoverView: true,
      label: "gender",
      valueformat: (e) => {
        return `${e.gender}`;
      },
    },
    {
      key: "profiles",
      slideoverView: true,
      label: "nationality",
      valueformat: (e) => {
        return `${e.nationality}`;
      },
    },
  ],
  filter: {
    initFilter: {
      entries: 10,
      page: 1,
    },
    fields: [],
  },
};
