import { useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import TriggersModal from "./TriggersModal";
import { useWorkflows } from "./hooks/useWorkflows";
import useGetRequest from "../../hooks/useGetRequest";
import useDeleteRequest from "../../hooks/useDeleteRequest";
import usePutRequest from "../../hooks/usePutRequest";

export default function TriggersTable() {
  const {
    doGet: doGetTriggers,
    items: triggerItems,
    isComplete: isCompleteTriggers,
  } = useGetRequest("automationTriggers");
  const { doDelete, isComplete: isCompleteDelete } =
    useDeleteRequest("automationTriggers");

  const { doPut, isComplete: isCompletePut } =
    usePutRequest("automationTriggers");

  const { workflows } = useWorkflows();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTrigger, setEditingTrigger] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmAction, setConfirmAction] = useState(null);

  const handleEdit = (trigger) => {
    setEditingTrigger(trigger);
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    setConfirmAction({ type: "delete", id });
  };

  const handleToggleActive = (id, active) => {
    setConfirmAction({ type: "toggle", id, active });
  };

  const confirmDelete = () => {
    if (confirmAction && confirmAction.type === "delete") {
      doDelete({ id: confirmAction.id });
    }
    setConfirmAction(null);
  };

  const confirmToggle = () => {
    if (confirmAction && confirmAction.type === "toggle") {
      doPut({ id: confirmAction.id, data: { active: !confirmAction.active } });
    }
    setConfirmAction(null);
  };

  const filteredTriggers = !isCompleteTriggers
    ? []
    : triggerItems.data.filter((trigger) =>
        trigger.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

  const getWorkflowName = (workflowId) => {
    const workflow = workflows.find((w) => w.id === workflowId);
    return workflow ? workflow.name : "Unknown";
  };

  useEffect(() => {
    doGetTriggers();
  }, [isCompletePut, isCompleteDelete]);

  const onCompleteRequest = () => {
    doGetTriggers();
  };

  return (
    <>
      <div className="flex justify-between mb-4">
        <h2 className="text-xl font-semibold">Trigger List</h2>
        <Button onClick={() => setIsModalOpen(true)}>Add New Trigger</Button>
      </div>
      <Input
        type="text"
        placeholder="Search triggers..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4"
      />
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Name</TableHead>
            <TableHead>Type</TableHead>
            <TableHead>Time</TableHead>
            <TableHead>Workflow</TableHead>
            <TableHead>Active</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {filteredTriggers.map((trigger) => (
            <TableRow key={trigger.id}>
              <TableCell>{trigger.name}</TableCell>
              <TableCell>{trigger.trigger_type}</TableCell>
              <TableCell>{trigger.trigger_time}</TableCell>
              <TableCell>{getWorkflowName(trigger.workflow)}</TableCell>
              <TableCell>
                <Switch
                  checked={trigger.active}
                  onCheckedChange={() =>
                    handleToggleActive(trigger.id, trigger.active)
                  }
                />
              </TableCell>
              <TableCell>
                <Button
                  variant="outline"
                  className="mr-2"
                  onClick={() => handleEdit(trigger)}
                >
                  Edit
                </Button>
                <Button
                  variant="destructive"
                  onClick={() => handleDelete(trigger.id)}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TriggersModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingTrigger(null);
        }}
        trigger={editingTrigger}
        onCompleteRequest={onCompleteRequest}
      />
      <AlertDialog
        open={confirmAction !== null}
        onOpenChange={() => setConfirmAction(null)}
      >
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {confirmAction?.type === "delete"
                ? "Confirm Deletion"
                : "Confirm Status Change"}
            </AlertDialogTitle>
            <AlertDialogDescription>
              {confirmAction?.type === "delete"
                ? "Are you sure you want to delete this trigger? This action cannot be undone."
                : `Are you sure you want to ${
                    confirmAction?.active ? "deactivate" : "activate"
                  } this trigger?`}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction
              onClick={
                confirmAction?.type === "delete" ? confirmDelete : confirmToggle
              }
            >
              Confirm
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
}
