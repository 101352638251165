import { useState, useRef } from "react";
import ContextMenuClick from "./ContextMenuClick";

export default function ContextMenuLeftClickButton({
  content,
  contextMenuData
}) {
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const [contextMenuVisible, setContextMenuVisible] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenuPosition({ top: event.clientY, left: event.clientX });
    console.log({ top: event.clientY, left: event.clientX });
    setContextMenuVisible(true);
  };

  const handleCloseContextMenu = () => {
    setContextMenuVisible(false);
  };

  return (
    <>
      <ContextMenuClick
        position={contextMenuPosition}
        visible={contextMenuVisible}
        onClose={handleCloseContextMenu}
        menuData={contextMenuData}
        openToDirection={'up'}
      />
      <div onClick={handleContextMenu}>{content}</div>
    </>
  );
}
