export const senderDestinationOptions = [
  {
    key: "phone",
    label: 'SMS',
    actionLabel: "Enviar SMS",
  },
  {
    key: "email",
    label: 'EMAIL',
    actionLabel: "Enviar Email",
  },
];
