import React from "react";
import {
  Popover,
  Transition,
  PopoverButton,
  PopoverPanel,
} from "@headlessui/react";
import { useRef, Fragment } from "react";
import { usePopper } from "react-popper";

import moment from "moment-timezone";
import "moment/dist/locale/pt";
moment.locale("pt");

export default function ChatCallEventContentInfo({ buttonContent, data }) {
  let [popperElement, setPopperElement] = React.useState();
  const popRef = useRef(null);
  let { styles, attributes } = usePopper(popRef, popperElement);

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <PopoverButton
            ref={popRef}
            className={`outline-none ${open ? "" : "text-opacity-90"}`}
            onMouseOver={() => popRef.current.click()}
          >
            {buttonContent}
          </PopoverButton>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <PopoverPanel
              ref={setPopperElement}
              onMouseLeave={() => popRef.current.click()}
              style={styles.popper}
              {...attributes.popper}
              className={`absolute left-1/2 z-10 w-48 mt-1 -translate-x-1/2 transform px-4`}
            >
              <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                <div className="relative flex flex-col bg-white py-3 px-3 text-xs">
                  <div className="flex justify-between gap-x-4 py-1">
                    <dt className="text-gray-500">Data</dt>
                    <dd className="text-gray-700">
                      {moment(data.phone_start_date).format("HH:mm, DD/MM/YY")}
                    </dd>
                  </div>
                  {data.phone_duration_sec != null && (
                    <div className="flex justify-between gap-x-4 py-1">
                      <dt className="text-gray-500">Duração</dt>
                      <dd className="text-gray-700">
                        {moment
                          .utc(data.phone_duration_sec * 1000)
                          .format("HH:mm:ss")}
                      </dd>
                    </div>
                  )}
                  {data.phone_duration_waiting_sec != null && (
                    <div className="flex justify-between gap-x-4 py-1">
                      <dt className="text-gray-500">Espera</dt>
                      <dd className="text-gray-700">
                        {moment
                          .utc(data.phone_duration_waiting_sec * 1000)
                          .format("HH:mm:ss")}
                      </dd>
                    </div>
                  )}
                  {data.phone_queued_sec != null && (
                    <div className="flex justify-between gap-x-4 py-1">
                      <dt className="text-gray-500">Em fila</dt>
                      <dd className="text-gray-700">
                        {moment
                          .utc(data.phone_queued_sec * 1000)
                          .format("HH:mm:ss")}
                      </dd>
                    </div>
                  )}
                  <div className="flex justify-between gap-x-4 py-1">
                    <dt className="text-gray-500">Atendido</dt>
                    <dd className="text-gray-700">{data.phone_pickup == 'not-connected' ? 'Não' : 'Sim'}</dd>
                  </div>
                  <div className="flex justify-between gap-x-4 py-1">
                    <dt className="text-gray-500">Operador</dt>
                    <dd className="text-gray-700"></dd>
                  </div>
                </div>
              </div>
            </PopoverPanel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
