import * as React from "react";

import LoadingIndicator from "../loading/LoadingIndicator";
import HomeLayout from "../nav/HomeLayout";
import { useAuth } from "react-oidc-context";

import { GlobalStateProvider } from "../state/globalState";
import { useErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";

const logError = (error, info) => {
  console.error(info);
  console.error(error);
};

export default function LoadAuthContext(props) {
  const auth = useAuth();

  if (auth.isLoading) {
    return <div>Loading...</div>;
  }

  if (auth.error) {
    return <div>Oops... {auth.error.message}</div>;
  }

  if (auth.isAuthenticated) {
    return (
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <GlobalStateProvider>
          <LoadingIndicator />

          <HomeLayout
            noPadding={props.noPadding}
            showbreadCrumbs={props.showbreadCrumbs}
            content={props.content}
          />
        </GlobalStateProvider>
      </ErrorBoundary>
    );
  }

  // Automatically redirect to login if not authenticated
  auth.signinRedirect();
  return (
    <div>
      <p className="text-lg p-4 text-gray-700 font-light">
        A redireccionar para a página de início de sessão
      </p>
    </div>
  );
}

function ErrorFallback({ error }) {
  const { resetBoundary } = useErrorBoundary();

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
      <button onClick={resetBoundary}>Try again</button>
    </div>
  );
}
