import React, { useState } from "react";

import FormSlideover from "../common/slideover/FormSlideover";
import TableEngineTw from "../common/table/TableEngineTw";

import SpeedDialAdd from "../nav/speeddial/SpeedDialAdd";
import { tableconfig as tbcfg } from "../common/configs/tableconfig_insurance";
import { formfield_newinsurance as fin } from "../forms/formfield_insurance";
import useGetRequest from "../hooks/useGetRequest";

const InsurancePage = () => {
  const {
    doGet: doGetinsurances,
    items: insuranceItems,
    isComplete: isLoadingCompleteInsurance,
  } = useGetRequest("insurances");

  const [edit_formData, edit_setFormData] = React.useState({});
  const [openAdd, setOpenAdd] = useState(false);
  const [formKey, setFormKey] = useState(0);


  const handleAddNewItem = (usr) => {
    setOpenAdd(true);
  };

  const edit_okHandler = () => {
    setFormKey((k) => k + 1);
    setOpenAdd(false);
    doGetinsurances();
  };

  const edit_errHandler = () => {
    console.error("Error processing request");
    setFormKey((k) => k + 1);
    setOpenAdd(false);
  };
  return (
    <>
      <FormSlideover
        fin={fin}
        key={formKey}
        open={openAdd}
        setOpen={setOpenAdd}
        formData={edit_formData}
        setFormData={edit_setFormData}
        okHandler={edit_okHandler}
        errHandler={edit_errHandler}
      />
      <SpeedDialAdd onClickAction={handleAddNewItem} />

      <TableEngineTw
        cfg={tbcfg}
        items={isLoadingCompleteInsurance && insuranceItems}
        fetchData={doGetinsurances}
        refreshHandler={doGetinsurances}
      />
    </>
  );
};

export default InsurancePage;
