import { useState } from 'react'
import { PlusCircle, MoreVertical, Pencil, Trash2, Check, X, AlertCircle, Bell, Calendar, FileText, Settings } from 'lucide-react'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"



const iconOptions = {
  PlusCircle,
  Pencil,
  Trash2,
  Check,
  AlertCircle,
  Bell,
  Calendar,
  FileText,
  Settings,
}

const defaultWorkflows  = [
  { id: '1', name: 'Approval', icon: 'Check' },
  { id: '2', name: 'Review', icon: 'Pencil' },
  { id: '3', name: 'Delete', icon: 'Trash2' },
]

export default function AutomationWorkflowList() {

  const [workflows, setWorkflows] = useState(defaultWorkflows)
  const [editingId, setEditingId] = useState(null)
  const [editName, setEditName] = useState('')
  const [editIcon, setEditIcon] = useState ('PlusCircle')

  const addWorkflow = () => {
    const newWorkflow = {
      id: Date.now().toString(),
      name: 'New Workflow',
      icon: 'PlusCircle',
    }
    setWorkflows([...workflows, newWorkflow])
    setEditingId(newWorkflow.id)
    setEditName(newWorkflow.name)
    setEditIcon(newWorkflow.icon)
  }

  const removeWorkflow = (id) => {
    setWorkflows(workflows.filter((workflow) => workflow.id !== id))
  }

  const startEditing = (id, name, icon) => {
    setEditingId(id)
    setEditName(name)
    setEditIcon(icon)
  }

  const saveEdit = (id) => {
    setWorkflows(
      workflows.map((workflow) =>
        workflow.id === id ? { ...workflow, name: editName, icon: editIcon } : workflow
      )
    )
    setEditingId(null)
  }

  const cancelEdit = () => {
    setEditingId(null)
  }

  return (
    <div className="p-4 max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Workflow Icons</h2>
      <ul className="space-y-2">
        {workflows.map((workflow) => {
          const IconComponent = iconOptions[workflow.icon]
          return (
            <li
              key={workflow.id}
              className="flex items-center justify-between bg-white p-3 rounded-lg shadow"
            >
              <div className="flex items-center space-x-3">
                <IconComponent className="h-6 w-6 text-gray-500" />
                {editingId === workflow.id ? (
                  <Input
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    className="w-40"
                  />
                ) : (
                  <span>{workflow.name}</span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                {editingId === workflow.id ? (
                  <>
                    <Select
                      value={editIcon}
                      onValueChange={(value) => setEditIcon(value)}
                    >
                      <SelectTrigger className="w-[100px]">
                        <SelectValue placeholder="Select icon" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.entries(iconOptions).map(([key, Icon]) => (
                          <SelectItem key={key} value={key}>
                            <div className="flex items-center">
                              <Icon className="mr-2 h-4 w-4" />
                              {key}
                            </div>
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <Button size="icon" onClick={() => saveEdit(workflow.id)}>
                      <Check className="h-4 w-4" />
                    </Button>
                    <Button size="icon" variant="outline" onClick={cancelEdit}>
                      <X className="h-4 w-4" />
                    </Button>
                  </>
                ) : (
                  <DropdownMenu>
                    <DropdownMenuTrigger asChild>
                      <Button variant="ghost" size="icon">
                        <MoreVertical className="h-4 w-4" />
                        <span className="sr-only">Open menu</span>
                      </Button>
                    </DropdownMenuTrigger>
                    <DropdownMenuContent align="end">
                      <DropdownMenuItem onClick={() => startEditing(workflow.id, workflow.name, workflow.icon)}>
                        Edit
                      </DropdownMenuItem>
                      <DropdownMenuItem onClick={() => removeWorkflow(workflow.id)}>
                        Remove
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                )}
              </div>
            </li>
          )
        })}
      </ul>
      <Button onClick={addWorkflow} className="mt-4">
        <PlusCircle className="h-4 w-4 mr-2" />
        Add Workflow
      </Button>
    </div>
  )
}

