import {
  AtSymbolIcon,
  DocumentIcon,
  FaceFrownIcon,
  FaceSmileIcon,
  MicrophoneIcon,
  PaperAirplaneIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import ContextMenuLeftClickButton from "../../common/menu/ContextMenuLeftClickButton";
import { msgDestinationOptionsList } from "./senderHelpers";
import { useEffect } from "react";
import { senderDestinationOptions } from "./constants";

export default function MessagingSenderEditorButtons({
  selectedTopic,
  selectedDestination,
  setSelectedDestination,
  handleSubmit,
  mode,
}) {
  useEffect(() => {
    if (selectedTopic.contact_option_list != null) {
      let defaultContact = {};
      const phoneContact = selectedTopic.contact_option_list.filter(
        (s) => s.value == "phone"
      );
      if (phoneContact.length > 0) defaultContact = phoneContact[0];
      else defaultContact = selectedTopic.contact_option_list[0];
      setSelectedDestination(defaultContact);
    }
  }, [selectedTopic]);

  function handleChangeDestination(dest) {
    setSelectedDestination(dest);
  }

  return (
    <div className="flex w-full">
      <div className="flex gap-x-2 p-1 w-full">
        {mode == 0 && (
          <>
            <ContextMenuLeftClickButton
              contextMenuData={msgDestinationOptionsList(
                selectedTopic,
                selectedDestination,
                handleChangeDestination
              )}
              content={
                <button
                  type="button"
                  className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
                >
                  {senderDestinationOptions.filter(
                    (s) => s.key == selectedDestination.key
                  ).length > 0 &&
                    senderDestinationOptions.filter(
                      (s) => s.key == selectedDestination.key
                    )[0].label}
                </button>
              }
            />

           {/*  <button
              type="button"
              className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            >
              <DocumentIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            >
              <AtSymbolIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            >
              <FaceSmileIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            >
              <PaperClipIcon className="h-4 w-4" />
            </button>
            <button
              type="button"
              className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm hover:bg-gray-100"
            >
              <MicrophoneIcon className="h-4 w-4" />
            </button> */}
          </>
        )}
      </div>
      <div className="flex gap-x-2 p-1">
        {mode == 0 && selectedDestination.key == "phone" && (
          <div className="rounded bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600 shadow-sm">
            SMS
          </div>
        )}

        <button
          type="button"
          onClick={handleSubmit}
          className="rounded flex gap-x-2 px-2 py-1 text-sm font-semibold shadow-sm bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          {mode == 0 
          ? "Enviar"
          : mode == 1
          ? "Comentar"
          : ""}
          <PaperAirplaneIcon className="h-4 w-4 pt-1" />
        </button>
      </div>
    </div>
  );
}
